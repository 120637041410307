// eslint-disable-next-line no-use-before-define
import React from 'react';
import { Spinner } from './spinner';
import '../assets/styles/centered_spinner.scss';

export function CenteredSpinner() {
  return (
    <div className='text-center align-middle spinner-page'>
        <div className='spinner-box'>
          <Spinner />
        </div>
    </div>
  );
}
