// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import Auth from '@aws-amplify/auth';
import StackedLogo from '../assets/images/stacked-logo.svg';
import CompactLogo from '../assets/images/compact-logo-negative.png';
import { Spinner } from '../components/spinner';
import '../assets/styles/login.scss';
import { ZohoHelper } from '../utils/ZohoHelper';

export class Login extends Component {
  state = { 'username': '', 'password': '', 'error': '' };

  constructor(props) {
    super(props);
    this.state = {
      'username': '',
      'password': '',
      'error': ''
    };
  }

  handleAnequimViaSignIn = () => {
    Auth.federatedSignIn().catch((err) => {
      console.error('Sign in failed:', err);
    });
  };

  handleClientLogin = (e) => {
    e.preventDefault();
    // { formData.name }, Email: ${ formData.password });
    ZohoHelper.loginToZoho(this.state.username, this.state.password)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (Object.prototype.hasOwnProperty.call(result, 'attributes')) {
          sessionStorage.setItem('user', JSON.stringify(result));
          window.location.reload();
        } else {
          this.setState((prevFormData) => ({ ...prevFormData, 'error': result.error }));
        }
      });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevFormData) => ({ ...prevFormData, [name]: value }));
    console.log({ name, value });
  };

  handleHubstaffSignIn = () => {
    Auth.federatedSignIn({ 'customProvider': 'Hubstaff' }).catch((err) => {
      console.error('Sign in failed:', err);
    });
  };

  HandleError = () => {
    const errorMsg = this.state.error;
    if (errorMsg === '') {
      return '';
    }
    return (
      <div className='text-danger'>
        <h6>{errorMsg}</h6>
      </div>
    );
  }

  ButtonOrSpinner = () => {
    if (window.location.search.startsWith('?code=')) {
      return <Spinner />;
    }

    return (
      <div className='button-container'>
        {/* <div className='title'>Vía Portal</div> */}
        <form onSubmit={this.handleClientLogin}>
          <div className="form-group-left-aligned form-group">
            <label>
              <p>Username</p>
              <input type='text' className='form-control' name='username' onChange={this.handleChange} />
            </label>
          </div>
          <div className="form-group-left-aligned form-group">
            <label>
              <p>Password</p>
              <input type='password' className='form-control' name='password' onChange={this.handleChange} />
            </label>
          </div>
          <div>
            <button className='btn primary-login-btn'>Sign in <img className='anequim-compact-logo compact-logo' src={CompactLogo} alt='Anequim Vía' /></button>
          </div>
        </form>
      </div>
    );
  }

  render() {
    return (
      <div className='text-center align-middle login'>
        <div className='login-box'>
          <div>
            <img className='logo' src={StackedLogo} alt='Anequim' />
          </div>
          <div className='vl'></div>
          {this.ButtonOrSpinner()}
          {this.HandleError() }
        </div>
      </div>
    );
  }
}
