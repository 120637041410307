// eslint-disable-next-line no-shadow,no-unused-vars
 enum ReduxUserActions {
    // eslint-disable-next-line no-unused-vars
    SET_USER = 'setUser'
}

/**
 * ReduxUserActions is an enum that specify the 'action' names the reducer will handle
 * e.g. SET_USER = 'setUser'
 * that way we reduce the posibility of commiting a spelling mistake
 * */
export default ReduxUserActions;
