import * as _ from 'lodash';
import { ReduxEmployeesActionTypes } from './ReduxEmployeesActionTypes';
import ReduxEmployeesActions from './ReduxEmployeesActions';

// eslint-disable-next-line default-param-last
const reducer = (state = [], action: ReduxEmployeesActionTypes) => {
  if (_.get(action, 'type') === ReduxEmployeesActions.LOAD_EMPLOYEES && !_.isEmpty(action.payload)) {
    return action.payload;
  }
  return state;
};

export default reducer;
