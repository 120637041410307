// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import '../assets/styles/employee-references-form.scss';
import Button from 'react-bootstrap/Button';
import _ from 'lodash';
import { Col, Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { v4 as uuidv4 } from 'uuid';
import { IEmployeeExternalReferenceRequest } from './interfaces/IEmployeeExternalReferenceRequest';
import { IExternalSystemModel } from './interfaces/IExternalSystemModel';
import { IEntityTypeModel } from './interfaces/IEntityTypeModel';
import { Environment } from '../environment';
import { CenteredSpinner } from './centered_spinner';
import EmployeeTypeAhead from './EmployeeTypeAhead';
import { IEmployeeTypeAheadData } from './IEmployeeTypeAheadData';
import TrashCanIcon from '../assets/images/trash-can-regular.svg';
import { IExternalRefRequestError } from './interfaces/IExternalRefRequestError';

export class EmployeeReferencesForm extends Component<{}> {
  state: {
    isLoading: boolean;
    employeeReferencesRequest: Array<IEmployeeExternalReferenceRequest>;
    externalSystems: Array<IExternalSystemModel>;
    externalEntityTypes: Array<IEntityTypeModel>;
    typeaheadEmployees: Array<IEmployeeTypeAheadData>;
    requestErrors: Array<IExternalRefRequestError>;
    isRequestStored: boolean;
    storingError: boolean;
  } = {
    'isLoading': true,
    'employeeReferencesRequest': [],
    'externalSystems': [],
    'externalEntityTypes': [],
    'typeaheadEmployees': [],
    'requestErrors': [],
    'isRequestStored': false,
    'storingError': false
  };

  componentDidMount() {
    Promise.all([
      this.loadExternalSystems(),
      this.loadEntityTypes(),
      this.loadTypeaheadEmployees()
    ]).then(() => {
      this.setState({ 'isLoading': false });
    });
  }

  render() {
    if (this.state.isLoading) {
      return (
        <CenteredSpinner />
      );
    }

    return (
        <Container>
          <Row>
            <table className={'table table-striped'}>
              <thead className="references-thead">
              <tr>
                <th className={'name-column'}>
                  <span>Empleado</span>
                </th>
                <th className={'system-column'}>
                  <span>Sistema</span>
                </th>
                <th className={'entity-column'}>
                  <span>Entidad</span>
                </th>
                <th className={'ref-column'}>
                  <span>Referencia</span>
                </th>
                <th className={'delete-column'}>
                  <span>Borrar</span>
                </th>
              </tr>
              </thead>
              <tbody>
                {this.externalReferencesTable()}
              </tbody>
            </table>
          </Row>
          <Row className={'justify-content-center mt-2'}>
            {this.storingFeedbackMessage()}
          </Row>
          <Row className={'justify-content-center mt-2'}>
            <Col sm={3} className={'pt-2'}>
              <Button variant="primary" onClick={ () => this.addNewReferenceButtonHandler()}>
                Nueva referencia
              </Button>
            </Col>
            <Col sm={3} className={'pt-2'}>
              <Button variant="primary" onClick={ () => this.sendRequestButtonHandler()}>
                Guardar referencias
              </Button>
            </Col>
          </Row>
        </Container>
    );
  }

  private externalReferencesTable = () => {
    const {
      externalSystems,
      externalEntityTypes,
      typeaheadEmployees
    } = this.state;
    const extSysOptions = _.map(externalSystems, (eSys) => <option key={_.get(eSys, 'id')} value={_.get(eSys, 'code')}>{eSys.text}</option>);
    const exteTypesOptions = _.map(externalEntityTypes, (eType) => <option key={_.get(eType, 'id')} value={_.get(eType, 'code')}>{eType.text}</option>);
    const rows: Array<any> = [];
    _.forEach(this.state.employeeReferencesRequest, (req) => {
      const currentEmployeeId = _.get(req, 'employeeId');
      const selectedOption = currentEmployeeId ? [currentEmployeeId] : [];
      const currentId = _.get(req, 'localId');
      const currentRowErrors = this.getRequestErrorsByLocalId(currentId);
      rows.push(
        <tr key={req.localId}>
          <td>
            <EmployeeTypeAhead
              handler={this.typeaheadHandler}
              selectedOption={selectedOption}
              options={typeaheadEmployees}
              rowIdentifier={currentId}
              isDisabled={false}
            />
            <Form.Control.Feedback type="invalid">
              Elige un empleado por favor
            </Form.Control.Feedback>
          </td>
          <td>
            <Form.Control as="select"
                          onChange={ (event) => this.eSysSelectHandler(event, currentId)}
                          isInvalid={ !_.isNil(currentRowErrors) && _.get(currentRowErrors, 'externalSystemCode') }>
              <option>Sistema externo</option>
              {extSysOptions}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Elige un sistema externo por favor
            </Form.Control.Feedback>
          </td>
          <td>
            <Form.Control as="select"
                          onChange={ (event) => this.eTypesSelectHandler(event, currentId)}
                          isInvalid={ !_.isNil(currentRowErrors) && _.get(currentRowErrors, 'entityTypeCode') }>
              <option>Tipo de entidad</option>
              {exteTypesOptions}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Elige un tipo de entidad por favor
            </Form.Control.Feedback>
          </td>
          <td>
             <Form.Control type="text"
                          placeholder="Referencia"
                          value={req.externalReferenceValue}
                          onChange={ (event) => this.refValueHandler(event, currentId) }
                          isInvalid={ !_.isNil(currentRowErrors) && _.get(currentRowErrors, 'externalReferenceValue') }
            />
            <Form.Control.Feedback type="invalid">
              Escribe un valor por favor
            </Form.Control.Feedback>
          </td>
          <td className='delete-icon-container'>
            <a href='#' onClick={ (e) => this.deleteRowHandler(e, currentId) }>
              <img className='trash-can-icon' src={TrashCanIcon}/>
            </a>
          </td>
        </tr>
      );
    });
    return rows;
  }

  private addNewReferenceButtonHandler() {
    const { employeeReferencesRequest } = this.state;
    const newUuid = uuidv4();
    const newRequest: IEmployeeExternalReferenceRequest = {
      'localId': newUuid,
      'entityTypeCode': '',
      'employeeId': '',
      'externalSystemCode': '',
      'externalReferenceValue': ''
    };
    const newState = [...employeeReferencesRequest];
    newState.push(newRequest);
    this.setState({ 'employeeReferencesRequest': newState, 'isRequestStored': false, 'storingError': false });
  }

  private async loadExternalSystems() {
    await fetch(`${Environment.get('INTERNAL_REST_SERVICE_API_ROOT')}anequim/external-systems/all`, {})
      .then((response) => response.json())
      .then((data) => {
        this.setState({ 'externalSystems': data });
      });
  }

  private async loadEntityTypes() {
    await fetch(`${Environment.get('INTERNAL_REST_SERVICE_API_ROOT')}anequim/entity-types/all`, {})
      .then((response) => response.json())
      .then((data) => {
        this.setState({ 'externalEntityTypes': data });
      });
  }

  private async loadTypeaheadEmployees() {
    await fetch(`${Environment.get('INTERNAL_REST_SERVICE_API_ROOT')}anequim/employees/`, {})
      .then((response) => response.json())
      .then((data) => {
        const filteredResponse = _.filter(data, (emp) => !_.isNil(_.get(emp, 'name')));
        this.setState({ 'typeaheadEmployees': filteredResponse });
      });
  }

  private typeaheadHandler = (selectedOption: any, rowIdentifier: string) => {
    if (!_.isNil(rowIdentifier)) {
      const request = this.getSingleRef(rowIdentifier);
      if (request) {
        request.employeeId = selectedOption;
        this.updateEmpId(request);
      }
    }
  };

  private eSysSelectHandler(event: any, localId: string) {
    const selectedValue = event.target.value;
    const request = this.getSingleRef(localId);
    if (request) {
      request.externalSystemCode = selectedValue;
    }
  }

  private eTypesSelectHandler(event: any, localId: string) {
    const selectedValue = event.target.value;
    const request = this.getSingleRef(localId);
    if (request) {
      request.entityTypeCode = selectedValue;
    }
  }

  private refValueHandler(event: any, localId: string) {
    const { value } = event.target;
    const request = this.getSingleRef(localId);
    if (request) {
      request.externalReferenceValue = value;
      this.updateRefValue(request);
    }
  }

  private updateRefValue(updatedRequest: IEmployeeExternalReferenceRequest) {
    const propToUpdate = _.find(this.state.employeeReferencesRequest, { 'localId': updatedRequest.localId });
    if (propToUpdate) {
      propToUpdate.externalReferenceValue = updatedRequest.externalReferenceValue;
      this.setState({ propToUpdate });
    }
  }

  private updateEmpId(updatedRequest: IEmployeeExternalReferenceRequest) {
    const propToUpdate = _.find(this.state.employeeReferencesRequest, { 'localId': updatedRequest.localId });
    if (propToUpdate) {
      propToUpdate.employeeId = updatedRequest.employeeId;
      this.setState({ propToUpdate });
    }
  }

  private getSingleRef = (localId: string) : IEmployeeExternalReferenceRequest | undefined => _.find(this.state.employeeReferencesRequest, { 'localId': localId });

  private async sendRequestButtonHandler() {
    const { employeeReferencesRequest } = this.state;
    const reqErrors = this.validateRequest();
    if (!_.isEmpty(reqErrors)) {
      this.setState({ 'requestErrors': reqErrors });
    }
    if (!_.isNil(employeeReferencesRequest) && _.isEmpty(reqErrors)) {
      const parsedRequest = _.map(employeeReferencesRequest, (row) => {
        const r: IEmployeeExternalReferenceRequest = {
          'localId': _.get(row, 'localId'),
          'entityTypeCode': _.get(row, 'entityTypeCode'),
          'employeeId': _.get(_.get(row, 'employeeId'), 'id'),
          'externalSystemCode': _.get(row, 'externalSystemCode'),
          'externalReferenceValue': _.get(row, 'externalReferenceValue')
        };
        return r;
      });
      try {
        await fetch(`${Environment.get('INTERNAL_REST_SERVICE_API_ROOT')}anequim/external-references`, {
          'method': 'POST',
          'headers': {
            'Content-Type': 'application/json'
          },
          'body': JSON.stringify(parsedRequest)
        }).then((response) => response.json())
          .then(() => {
            this.setState({ 'employeeReferencesRequest': [] });
          })
          .finally(() => {
            this.setState({ 'isRequestStored': true, 'storingError': false });
          });
      } catch (e) {
        console.log(e);
        this.setState({ 'employeeReferencesRequest': [], 'isRequestStored': true, 'storingError': true });
      }
    }
  }

  private validateRequest = () : Array<IExternalRefRequestError> => {
    const { employeeReferencesRequest } = this.state;
    const reqErrors: Array<IExternalRefRequestError> = [];
    _.forEach(employeeReferencesRequest, (req) => {
      const rowErrors = this.getRowErrors(req);
      if (rowErrors) {
        reqErrors.push(rowErrors);
      }
    });
    return reqErrors;
  }

  // eslint-disable-next-line max-len
  private getRowErrors = (row: IEmployeeExternalReferenceRequest) : IExternalRefRequestError | undefined => {
    const errors: IExternalRefRequestError = {
      'localId': _.get(row, 'localId'),
      'entityTypeCode': false,
      'employeeId': false,
      'externalSystemCode': false,
      'externalReferenceValue': false
    };

    const etype = _.get(row, 'entityTypeCode');
    const emp = _.get(row, 'employeeId');
    const extSys = _.get(row, 'externalSystemCode');
    const refValue = _.get(row, 'externalReferenceValue');
    let isError = false;
    const { externalEntityTypes, externalSystems } = this.state;
    const eTypeError = this.getValueErrors(etype, externalEntityTypes);
    if (eTypeError) {
      errors.entityTypeCode = true;
      isError = true;
    }
    if (!emp) {
      errors.employeeId = true;
      isError = true;
    }
    const extSysError = this.getValueErrors(extSys, externalSystems);
    if (extSysError) {
      errors.externalSystemCode = true;
      isError = true;
    }
    if (!refValue) {
      errors.externalReferenceValue = true;
      isError = true;
    }
    if (!isError) {
      return undefined;
    }
    return errors;
  }

  // eslint-disable-next-line max-len
  private getValueErrors = (valueToValidate: string | undefined, objects: Array<any>): boolean => {
    let isError = false;
    if (!valueToValidate || _.isNil(valueToValidate) || _.isEmpty(valueToValidate)) {
      isError = true;
    }
    if (valueToValidate) {
      const isValid = this.isEntityTypeValid(valueToValidate, objects);
      if (!isValid) {
        isError = true;
      }
    }
    return isError;
  }

  private isEntityTypeValid(etype: string, objects: Array<any>) {
    const et = _.find(objects, (obj) => _.get(obj, 'code') === etype);
    return !!et;
  }

  private getRequestErrorsByLocalId = (localId: string) : IExternalRefRequestError | undefined => _.find(this.state.requestErrors, { 'localId': localId });

  private deleteRowHandler(event: any, rowIdentifier: string) {
    event.preventDefault();
    const { employeeReferencesRequest } = this.state;
    const newRequest = [...employeeReferencesRequest];
    _.remove(newRequest, { 'localId': rowIdentifier });
    this.setState({ 'employeeReferencesRequest': newRequest });
  }

  private storingFeedbackMessage = () => {
    if (this.state.isRequestStored && !this.state.storingError) {
      return (<div> Las referencias se guardaron correctamente </div>);
    }
    if (this.state.isRequestStored && this.state.storingError) {
      return (<div> Hubo un error guardando las referencias </div>);
    }
    return (<div></div>);
  }
}
