// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import './assets/styles/app.scss';
import Amplify, { Hub } from '@aws-amplify/core';
import { Analytics } from '@aws-amplify/analytics';
// import Auth from '@aws-amplify/auth';
import amplifyConfig from './amplify';
import { Login } from './pages/Login';
import { AuthenticatedApp } from './AuthenticatedApp';
import { Footer } from './components/Footer';

// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';
// import ToastConfig from './toast';

Amplify.configure(amplifyConfig);

Analytics.autoTrack('session', {
  'enable': true,
  'type': 'SPA',
  'provider': 'AWSPinpoint'
});

// toast.configure(ToastConfig);

class App extends Component {
  state: { user: any } = { 'user': null };

  componentDidMount() {
    Hub.listen('auth', ({ 'payload': { event, data } }) => {
      // console.log(event);
      console.log(data);
      switch (event) {
        case 'signIn':
          this.setState({ 'user': data });
          break;
        case 'signOut':
          this.setState({ 'user': null });
          break;
        case 'customOAuthState':
          this.setState({ 'customState': data });
          break;
        default:
          this.setState({ 'user': null });
          break;
      }
    });
    if (sessionStorage.getItem('user') != null) {
      this.setState({ 'user': JSON.parse(sessionStorage.getItem('user') ?? '') });
    }

    // Auth.currentAuthenticatedUser()
    //   .then((user) => {
    //     this.setState({ user });
    //   })
    //   .catch(() => console.log('Not signed in'));
  }

  isLoggedIn(): boolean {
    return !!this.state.user;
  }

  render() {
    if (!this.isLoggedIn()) {
      return (
        <Login></Login>
      );
    }
    return (
      <div>
        <AuthenticatedApp user={this.state.user}></AuthenticatedApp>
        <div>
          <Footer user={this.state.user} />

          {/* <nav id="nav-nav_footer_legal" className="c-legal-banner__nav" */}
          {/*     data-navclass="nav-no-children"> */}
          {/*  <ul className=""> */}
          {/*    <li><a href="/cookie-preferences">Cookie */}
          {/*      Preferences</a></li> */}
          {/*    <li><a href="/privacy">Privacy */}
          {/*      Statement</a></li> */}
          {/*    <li><a href="/legal.aspx">Legal</a> */}
          {/*    </li> */}
          {/*    <li><a href="https://www.gallup.com/315998/ccpa-do-not-sell-my-information.aspx">Do */}
          {/*      Not Sell My Personal Information</a></li> */}
          {/*  </ul> */}
          {/* </nav> */}
        </div>
      </div>
    );
  }
}

export default App;
