// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import '../assets/styles/changelog.scss';

export class Changelog extends Component<{}> {
  onPageSizeChange = (e: any) => {
    const size = e.target.value;
    this.setState({ 'pageSize': size });
  };

  render() {
    return (
      <div>
      </div>
    );
  }
}
