// eslint-disable-next-line no-use-before-define
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import * as _ from 'lodash';
import { useAppDispatch } from '../store';
import {
  fetchData,
  selectAllData,
  selectProfileDataError,
  selectProfileDataStatus
} from '../features/employeeProfile/employeeProfileSlice';

type Props = {
  user: any;
}

export const EmployeeProfile: React.FC<Props> = ({ user }) => {
  const userEmail = _.get(user, 'attributes.email');
  const dispatch = useAppDispatch();

  // profile base data selectors
  const dataFetchedRef = useRef(false);
  const data = useSelector(selectAllData);
  const dataStatus = useSelector(selectProfileDataStatus);
  const error = useSelector(selectProfileDataError);

  useEffect(() => {
    if (dataFetchedRef.current || !userEmail) return;
    dataFetchedRef.current = true;
    if (dataStatus === 'idle' || dataStatus === undefined) {
      dispatch(fetchData(userEmail));
    }
  }, [userEmail, dataStatus, dispatch]);

  const renderedData = Object.keys(data).length > 0 ? <div className='profile-content'>
    <div><span className='profile-h'>Puesto:</span> {data.officialTitle}</div>
    {data.badges !== 'None' && <><span><span className='profile-h'>Insignia(s):</span> {data.badges}</span></>}
    <div><span className='profile-h'>Empleador:</span> {data.usClient === 'Undefined' ? 'Anequim' : data.usClient}</div>
    <div><span className='profile-h'>Proxima review:</span> {new Date(data.nrd).toLocaleDateString('es-MX')}</div>
    <div><span className='profile-h'>Advocate:</span> {data.advocate}</div>
    <div><span className='profile-h'>Advocate email:</span> <a
      href={`mailto:${data.advocateEmail}`}>{data.advocateEmail}</a></div>
  </div> : <div className='profile-content'>Ha ocurrido un error, por favor intenta de nuevo más tarde.</div>;

  let content;

  if (dataStatus === 'loading') {
    content = <div>Cargando...</div>;
  } else if (dataStatus === 'succeeded') {
    content = <div>
      {renderedData}
    </div>;
  } else if (dataStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <div>
      <h3 className="header">Hola, {_.split(_.get(user, 'attributes.name'), ' ', 1)}!</h3>
      <div className="landing-body">
        {content}
      </div>
    </div>
  );
};
