import _ from 'lodash';
import ReduxUserActions from './ReduxUserActions';
import { ReduxUserActionTypes } from './ReduxUserActionTypes';
import HubstaffUser from './HubstaffUser';

const initialState: HubstaffUser = {
  'username': '',
  'pool': {
    'userPoolId': '',
    'clientId': '',
    'client': {
      'endpoint': '',
      'fetchOptions': {}
    },
    'advancedSecurityDataCollectionFlag': true,
    'storage': {
      'domain': '',
      'path': '',
      'expires': 0,
      'secure': true,
      'sameSite': ''
    }
  },
  'Session': null,
  'client': {
    'endpoint': '',
    'fetchOptions': {}
  },
  'signInUserSession': {
    'idToken': {
      'jwtToken': '',
      'payload': {
        'at_hash': '',
        'sub': '',
        'cognito:groups': null,
        'email_verified': false,
        'iss': '',
        'cognito:username': '',
        'aud': '',
        'identities': [
          {
            'userId': '',
            'providerName': '',
            'providerType': '',
            'issuer': null,
            'primary': '',
            'dateCreated': ''
          }
        ],
        'token_use': '',
        'auth_time': 0,
        'name': '',
        'exp': 0,
        'iat': 0,
        'email': ''
      }
    },
    'refreshToken': {
      'token': ''
    },
    'accessToken': {
      'jwtToken': '',
      'payload': {
        'sub': '',
        'cognito:groups': null,
        'token_use': '',
        'scope': '',
        'auth_time': 0,
        'iss': '',
        'exp': 0,
        'iat': 0,
        'version': 0,
        'jti': '',
        'client_id': '',
        'username': ''
      }
    },
    'clockDrift': 0
  },
  'authenticationFlowType': '',
  'storage': {
    'domain': '',
    'path': '',
    'expires': 0,
    'secure': true,
    'sameSite': ''
  },
  'keyPrefix': '',
  'userDataKey': '',
  'attributes': {
    'sub': '',
    'identities': '',
    'email_verified': false,
    'name': '',
    'email': ''
  },
  'preferredMFA': ''
};

/**
 * There are several ways of writing reducers, this is the old base-redux approach.
 *
 * We declare a function that takes a state and an action object as parameters,
 * this pure function will contain the logic of the actions we may pass to it
 * and return a new status.
 *
 * e.g. A counterReducer will take a state with the values it'll use, and an action
 * object that has a 'type' and (optionally) a 'payload' values, the reducer will
 * look at the 'type' and based on that value will apply some logic to the state
 * (it never mutates the passed state, instead creates a copy of it) and return
 * a new version of it.
 * */
// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action: ReduxUserActionTypes): Object => {
  if (_.get(action, 'type') === ReduxUserActions.SET_USER) {
    return action.payload;
  }
  return state;
};

export default reducer;
