// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { API } from '@aws-amplify/api';
import * as _ from 'lodash';
import { Col, Row } from 'react-bootstrap';
import '../assets/styles/my-balance.scss';
import { CenteredSpinner } from '../components/centered_spinner';
import AnequimBucksIconBlue from '../assets/images/benefits/a-rewards-blue.svg';

export class MyBalance extends Component<{}> {
  state: { user: any, data: any, isLoading: boolean } = {
    'user': null,
    'data': {},
    'isLoading': true
  };

  props: {} = {};

  constructor(props: any) {
    super(props);
    this.props = props;
    this.loadJournals();
  }

  // eslint-disable-next-line class-methods-use-this
  render = () => {
    if (this.state.isLoading) {
      return (<CenteredSpinner></CenteredSpinner>);
    }

    const journalList: Array<any> = [];
    const journalData = _.get(this.state.data, 'relevantJournals');
    journalList.push(
      <Row>
        <Col className={'column-hdr'} xs={3}>ID de transacción</Col>
        <Col className={'column-hdr'} xs={3}>Fecha de Transacción</Col>
        <Col className={'column-hdr'} xs={3}>El valor</Col>
        <Col className={'column-hdr'} xs={3}>Notas</Col>
      </Row>
    );
    let totalAmt = 0;
    _.each(journalData, (j) => {
      const amt = -(_.get(j, 'Amount'))!.toFixed(2);
      totalAmt += amt;
      journalList.push(<Row>
        <Col xs={3}>{_.get(j, 'JournalID')}</Col>
        <Col xs={3}>{new Date(_.get(j, 'TransactionDate')).toLocaleDateString()}</Col>
        <Col xs={3}><img className='anequim-bucks-icon-balance' src={AnequimBucksIconBlue}/>{amt}</Col>
        <Col xs={3}>{_.get(j, 'Comment')}</Col>
      </Row>);
    });

    journalList.push(<Row>
      <Col xs={3} className={'total'}>Total</Col>
      <Col xs={3}></Col>
      <Col xs={3} className={'total'}><img className='anequim-bucks-icon-balance' src={AnequimBucksIconBlue}/>{totalAmt!.toFixed(2)}</Col>
      <Col xs={3}></Col>
    </Row>);

    const moveInDataArray = _.get(this.state.data, 'employeeRecord.moveInDate');
    // eslint-disable-next-line max-len,no-unused-vars
    const moveInDate = _.isEmpty(moveInDataArray) ? undefined
      : new Date(_.get(moveInDataArray, '0'), Math.max(_.get(moveInDataArray, '1'), 1) - 1, _.get(moveInDataArray, '2'));
    let tenured;
    let fullAmountDate;
    if (!_.isNil(moveInDate)) {
      // eslint-disable-next-line max-len
      tenured = new Date(moveInDate.getFullYear(), moveInDate.getMonth(), moveInDate.getDate() + 90);// tenured.setDate(moveInDate .getDate() + 90);
      // eslint-disable-next-line max-len
      fullAmountDate = new Date(moveInDate.getFullYear(), moveInDate.getMonth(), moveInDate.getDate() + 420);
    }
    return (<div className="my-balance">
        <h2>Mi Balance</h2>
        <div className="employee-info">
          <span><label>Mi ID de unidad :</label>{_.get(this.state.data, 'employeeRecord.unitId')}</span>
            <span><label>Fecha de inicio en Anequim:</label>{(_.isNil(moveInDate) ? 'UNKNOWN' : `${moveInDate.getMonth() + 1}/${moveInDate.getDate()}/${moveInDate.getFullYear()}`)}</span>
          {/* eslint-disable-next-line max-len */}
           <span><label>Fecha de antigüedad:</label>{(_.isNil(tenured) ? 'UNKNOWN' : `${tenured.getMonth() + 1}/${tenured.getDate()}/${tenured.getFullYear()}`)}</span>
          {/* eslint-disable-next-line max-len */}
           <span><label>Fecha de pago después de 30 nóminas :</label>{(_.isNil(fullAmountDate) ? 'UNKNOWN' : `${fullAmountDate.getMonth() + 1}/${fullAmountDate.getDate()}/${fullAmountDate.getFullYear()}`)}</span>
        </div>
        <ul>{journalList}</ul>
      </div>
    );
  };

  private loadJournals = async () => {
    // this.setState({ 'isLoading': true });
    const journalResponse = await API.get('FetchJournals', '', {});

    this.setState({ 'isLoading': false, 'data': JSON.parse(journalResponse.body) });
  };
}
