// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { Benefits } from '../benefit_types';

export class BenefitPanel extends Component<{}> {
  props: {
    benefitKey: string,
    userHasBenefit: boolean,
    status: string,
    tenant: number
  };

  constructor(props: any) {
    super(props);
    this.props = props;
  }

  render = () => {
    const benefit = Benefits[this.props.benefitKey];
    const icon = this.props.status !== 'inactive' ? benefit.activeIcon : benefit.inactiveIcon;
    return (
      <div className='col-sm-12 col-md-4 benefit-panel'>
        <a href={`#/benefits/${this.props.benefitKey}/tenant/${this.props.tenant}`}>
          <span>{benefit.title}</span>
          <img className='benefit-image' src={icon} alt={benefit.title}/>
          {/* <span>${this.props.status}</span> */}
        </a>
      </div>
    );
  };
}
