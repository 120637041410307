// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import '../assets/styles/configuration.scss';

export class Configuration extends Component<{}> {
  render() {
    return (<div>
      <div id={'configuration'}>
        <h1>Configuration</h1>
      </div>

        <div className="menu">
          <div className="menuitem">Employee Info</div>
          <div className="menuitem">Payroll</div>
          <div className="menuitem">Call Center</div>
          <div className="menuitem">Images</div>
          <div className="menuitem">Documents</div>
          <div className="menuitem">Benefits</div>
        </div>

      <div className="main">
        <h2>Employee Info</h2>
        <p>Start typing to search for an employee</p>
        <input type={'text'}/>
      </div>

      <div className="right">
        <h2>What?</h2>
        <p>Did you know that you can update key employee profile data here?</p>
        <h2>Where?</h2>
        <p>Data you enter in here will sync to both Rent Manager and Anequim databases.</p>
      </div>

    </div>);
  }
}
