// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';

export class ErrorModal extends Component<{}> {
  state: {
    modalShowing?: boolean;
  } = {};

  props: {
    errorMsg: string,
    errorBody: string,
    modalShowing: boolean,
  };

  // private modalShowing: boolean;

  constructor(props: any) {
    super(props);
    this.props = props;
    // this.modalShowing = this.props.modalShowing;
    this.state.modalShowing = this.props.modalShowing;
  }

  closeModal = () => {
    this.setState({ 'modalShowing': false });
    // this.forceUpdate();
  };

  render() {
    return (<Modal id="error-modal" show={this.state.modalShowing}
                                    onHide={this.closeModal}
                                   backdrop="static"
                                   keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>{this.props.errorMsg}</Modal.Title>
          </Modal.Header>
          <Modal.Body>  {this.props.errorBody}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>);
  }
}
