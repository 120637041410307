// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import * as _ from 'lodash';
import { API } from '@aws-amplify/api';
import { Button, Modal, Form } from 'react-bootstrap';
import { CenteredSpinner } from '../components/centered_spinner';
// import { PersonalContact } from '../components/personal_contact';
import { ContactCard } from '../components/ContactCard';

type ContactsState = {

}

export class Contacts extends Component<{}, ContactsState> {
  state: {
    contacts: Array<any>,
    activeContact: any,
    loaded: boolean,
    contactTypes: Array<any>,
    updateModalShowing: boolean,
    contactFormName: string,
    contactFormBirthDate: Date,
    contactFormContactTypeId: number,
    isModalInputValid: boolean,
    profileData?: any
  } = {
    'contacts': [],
    'activeContact': null,
    'loaded': false,
    'contactTypes': [],
    'updateModalShowing': false,
    'contactFormName': '',
    'contactFormBirthDate': new Date(),
    'contactFormContactTypeId': 0,
    'isModalInputValid': false
  };

  props: {
    user: any
  };

  private wrapper: React.RefObject<unknown>;

  constructor(props: any) {
    super(props);
    this.wrapper = React.createRef();
    this.props = props;
    this.addContact = this.addContact.bind(this);
  }

  async componentDidMount() {
    await this.loadContactTypes();
    await this.loadProfileData();
    await this.loadContacts();
  }

  async loadProfileData() {
    const loggedUserEmail = _.get(this.props.user, 'attributes.email');
    const eeData = await API.get('Employee', `Anequim/${loggedUserEmail}?db=MX`, {});
    this.setState({ 'profileData': eeData, 'loaded': true });
  }

  async loadContactTypes() {
    const eeData = await API.get('ContactTypes', 'Anequim/types/all?db=MX', {});
    this.setState({ 'contactTypes': eeData });
  }

  async loadContacts() {
    const { profileData } = this.state;
    const tenantId = _.get(profileData, 'tenantId', null);
    const contactsData = await API.post(
      'RmContactsByTnt',
      'Anequim/tenant?db=MX',
      {
        'body': {
          tenantId
        }
      }
    );
    this.setState({ 'contacts': contactsData });
  }

  /** Are we using this method? */
  removeContact(removeMe: any) {
    // eslint-disable-next-line eqeqeq
    const newContacts = this.state.contacts.slice(0).filter((c) => c.id != removeMe.id);
    this.setState({ 'contacts': newContacts });
  }

  /** Are we using this method? */
  addContact() {
    this.setState({
      'contacts': this.state.contacts.concat([{
        'id': `tmp${Math.random()}`
      }])
    });
  }

  showUpdateModalForm = (contact: any) => {
    if (!_.isNil(contact)) {
      let date: Date;
      let name: string;
      // eslint-disable-next-line max-len
      if (!_.isNil(contact.DateOfBirth) && !_.isNil(contact.LastName)) {
        // eslint-disable-next-line prefer-destructuring
        date = new Date(parseInt(contact.DateOfBirth));
        name = contact.LastName;
      } else {
        date = new Date();
        name = 'UNKNOWN';
      }

      this.setState({
        'updateModalShowing': true,
        'activeContact': contact,
        'contactFormName': name,
        'contactFormBirthDate': date,
        'contactFormContactTypeId': contact.ContactTypeID
      });
    } else {
      const currentDate = new Date();
      this.setState({
        'updateModalShowing': true,
        'activeContact': '',
        'contactFormName': '',
        'contactFormBirthDate': currentDate,
        'contactFormContactTypeId': 0
      });
    }
  };

  closeUpdateModalForm = () => {
    this.setState({ 'updateModalShowing': false });
  };

  // eslint-disable-next-line max-len
  async saveContact(contactId: Number, contactName: String, contactBirthDateObject: Date, contactTypeId: Number) {
    const { profileData } = this.state;
    const tenantId = _.get(profileData, 'tenantId', null);
    const contactBirthDate = contactBirthDateObject.toISOString().split('T')[0];

    if (contactId === -1) {
      await API.post(
        'RmContacts',
        'Anequim/save?db=MX',
        {
          'body': {
            contactName, contactBirthDate, contactTypeId, tenantId
          }
        }
      );
    } else {
      await API.post(
        'RmContacts',
        'Anequim/save?db=MX',
        {
          'body': {
            contactId, contactName, contactBirthDate, contactTypeId, tenantId
          }
        }
      );
    }
  }

  reload = () => window.location.reload();

  onSubmitUpdateModalForm = async () => {
    const {
      activeContact,
      contactFormName,
      contactFormBirthDate,
      contactFormContactTypeId
    } = this.state;
    const isNameValid = contactFormName.length > 0;
    const isBirthDateValid = contactFormBirthDate.toString().length > 0;
    const isContactTypeIdValid = contactFormContactTypeId > 0;

    // eslint-disable-next-line max-len
    // console.log(`${contactFormName}|${isNameValid} - ${contactFormBirthDate}|${isBirthDateValid} - ${contactFormContactTypeId}|${isContactTypeIdValid}`);
    const action = _.isNil(activeContact.ContactID) ? 'new' : 'update';

    if (isNameValid && isBirthDateValid && isContactTypeIdValid) {
      if (action === 'new') {
        // eslint-disable-next-line max-len
        await this.saveContact(-1, contactFormName, contactFormBirthDate, contactFormContactTypeId);
        await this.setState({
          'updateModalShowing': false
        });
        this.reload();
      }
      if (action === 'update') {
        // eslint-disable-next-line max-len
        await this.saveContact(activeContact.ContactID, contactFormName, contactFormBirthDate, contactFormContactTypeId);
        await this.setState({
          'updateModalShowing': false
        });
        this.reload();
      }
    } else {
      // eslint-disable-next-line no-alert
      alert('Algunos campos son inválidos');
    }
  };

  body = () => {
    const { loaded, profileData } = this.state;
    if (loaded) {
      if (profileData) {
        return this.contactList();
      }
      return (

          <h2>No se encontró información de perfil</h2>

      );
    }
    return (
        <CenteredSpinner />
    );
  };

  contactList = () => (
      <div>
        { this.state.contacts.map((contact) => (
          <ContactCard
              contact={contact}
              showModal={this.showUpdateModalForm}
              contactTypes={this.state.contactTypes}
              parent={this}
              key={this.state.contacts.indexOf(contact)} />
        ))
        }
        {/* <pre>{JSON.stringify(props.profile, null, 4)}</pre> */}
        <div className='row mt-3 mb-3'>
          <div className='col col-xs-12'>
            <button className='btn btn-primary' onClick={() => this.showUpdateModalForm(null)}>Agregar contacto</button>
          </div>
        </div>
      </div>
  );

  // eslint-disable-next-line consistent-return
  contactFormModal = () => {
    const {
      activeContact,
      contactTypes,
      contactFormName,
      contactFormBirthDate,
      contactFormContactTypeId,
      updateModalShowing
    } = this.state;
    if (!_.isNil(activeContact)) {
      return (
          <Modal
              size='lg'
              animation={false}
              className='contactModalForm'
              show={updateModalShowing}
              onHide={this.closeUpdateModalForm}
              centered>
            <Modal.Header closeButton>
              <Modal.Title>Contacto</Modal.Title>
            </Modal.Header>
            {/* eslint-disable-next-line max-len */}
            <Modal.Body>
              <Form.Group>
                <Form.Label>Nombre: </Form.Label>
                <Form.Control type="text"
                              onChange={ (e) => this.setState({ 'contactFormName': e.target.value }) }
                              value={contactFormName}
                              placeholder="Nombre"
                              maxLength={50}
                              required />
              </Form.Group>
              <Form.Group>
                <Form.Label>Fecha de nacimiento: </Form.Label>
                <Form.Control type="date"
                              onKeyDown={ (e: any) => e.preventDefault() }
                              onChange={ (e) => {
                                const timestamp = Date.parse(e.target.value);
                                const dateObject = new Date(timestamp);
                                this.setState({ 'contactFormBirthDate': dateObject });
                              } }
                              value={contactFormBirthDate.toISOString().split('T')[0]}
                              placeholder="Fecha de nacimiento"
                              maxLength={50}
                              required />
              </Form.Group>
              <Form.Group>
                <Form.Label>Tipo de contacto: </Form.Label>
                <Form.Control as="select"
                              required
                              type="select"
                              value={contactFormContactTypeId}
                              onChange={ (e) => this.setState({ 'contactFormContactTypeId': e.target.value }) }>
                  {/* eslint-disable-next-line max-len */}
                  {contactTypes.map((e) => <option key={e.ContactTypeID} value={e.ContactTypeID}>{e.Name}</option>)}
                </Form.Control>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.closeUpdateModalForm}>
                Cerrar
              </Button>
              <Button variant="primary" onClick={this.onSubmitUpdateModalForm}>
                Guardar
              </Button>
            </Modal.Footer>
          </Modal>
      );
    }
  };

  render() {
    return (
      <div className="row outer-row">
        <div className="col col-md-8 offset-md-2 col-xs-12">
          <h1>Mis Contactos</h1>
          {this.body()}
          {this.contactFormModal()}
        </div>
      </div>
    );
  }
}
