// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import * as _ from 'lodash';
import { Button, Form, Modal } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Environment } from '../environment';
import { INewServiceIssueDefinition } from './INewServiceIssueDefinition';
import '../assets/styles/service-issues.scss';
import { IEmployeeProfileModel } from './interfaces/IEmployeeProfileModel';
import { IDynamoAdvocateModel } from './interfaces/IDynamoAdvocateModel';

export class ServiceIssuesButtonSection extends Component<{}> {
  props: {
    children?: any,
    key: number,
    tileData: any,
    user: any,
    profileData: IEmployeeProfileModel
  };

  state: {
    title?: string;
    usagePercent?: number;
    menuItems?: Array<any>;
    modalShowing: boolean;
    message: string;
    category: string;
    clientList?: Array<any>;
    clientId: string;
    rmUserId: number;
    statusesList?: Array<any>;
    statusId: number;
    categoryId: number;
    propertyId: string;
    client?: string;
    feedbackModalShowing: boolean;
    createdServiceIssue?: any;
    advocatesData: Array<IDynamoAdvocateModel>;
    dataLoadingError: boolean;
  } = {
    'modalShowing': false,
    'message': '',
    'category': '',
    'clientId': '',
    'rmUserId': 0,
    'statusId': 0,
    'categoryId': 0,
    'propertyId': '',
    'feedbackModalShowing': false,
    'advocatesData': [],
    'dataLoadingError': false
  };

  constructor(props: any) {
    super(props);
    this.props = props;

    this.state.modalShowing = false;
    this.state.feedbackModalShowing = false;
    this.state.message = '';
    this.state.category = '';
  }

  async componentDidMount() {
    try {
      await Promise.all([await this.loadClients()]).then(() => {
        const tileTitle = _.get(this.props.tileData, 'name');
        const tileUsage = _.get(this.props.tileData, 'usage');
        const tileMenu = _.get(this.props.tileData, 'menuItems');
        this.setState({
          'title': tileTitle, 'usagePercent': tileUsage, 'menuItems': tileMenu, 'dataLoadingError': false
        });
      });
    } catch (e) {
      console.log(e);
      this.setState({ 'dataLoadingError': true });
    }
  }

  handleShow = () => {
    this.setState({ 'modalShowing': true });
    // this.forceUpdate();
  };

  handleClose = () => {
    this.setState({ 'modalShowing': false });
    this.setState({ 'message': '' });
    this.setState({ 'category': '' });
    // this.forceUpdate();
  };

  private handleFeedbackModalClose = () => {
    this.setState({ 'feedbackModalShowing': false });
  };

  validateForm() {
    const { message, category } = this.state;
    let isValidValue = true;

    if (message.length === 0 || message.length > 350) {
      isValidValue = false;
    }

    if (category.length === 0) {
      isValidValue = false;
    }

    return isValidValue;
  }

  loadClients = async () => {
    try {
      await fetch(`${Environment.get('SPRING_INTEGRATION_HUB_API_ROOT')}rm/clients/list`, {})
        .then((r) => r.json())
        // eslint-disable-next-line no-unused-vars
        .then((data) => {
          // getting the Anequim client UUID
          const anequimClient = _.find(data, { 'name': 'Anequim' });

          const anequimId = _.get(anequimClient, 'id');

          this.setState({ 'clientId': anequimId });
          this.loadUnassignedStatus(anequimId);
          this.loadUnassignedCategory(anequimId);
          this.loadRMUserId(anequimId);
        });
    } catch (e) {
      console.log(e);
      this.setState({ 'dataLoadingError': true });
    }
  };

  loadUnassignedStatus(clientId: string) {
    if (clientId !== null) {
      try {
        const data = [
          {
            'ServiceManagerStatusID': 1,
            'Name': '<Unassigned>',
            'Description': 'Status has not been assigned',
            'SortOrder': 1,
            'IsActive': true
          },
          {
            'ServiceManagerStatusID': 9,
            'Name': 'System Enhancement',
            'Description': '',
            'SortOrder': 9,
            'IsActive': true
          }
        ];
        const unassignedData = _.find(data, { 'Name': '<Unassigned>' });
        const unassignedID = _.get(unassignedData, 'ServiceManagerStatusID');
        this.setState({ 'statusId': unassignedID });
      } catch (e) {
        console.log(e);
      }
    }
  }

  loadUnassignedCategory(clientId: string) {
    if (clientId !== null) {
      try {
        const data = [
          {
            'ServiceManagerCategoryID': 1,
            'Name': '<Unassigned>',
            'Description': 'Category has not been assigned',
            'SortOrder': 1,
            'IsActive': true
          },
          {
            'ServiceManagerCategoryID': 9,
            'Name': 'System Enhancements',
            'Description': '',
            'SortOrder': 9,
            'IsActive': true
          },
          {
            'ServiceManagerCategoryID': 10,
            'Name': 'REVIEWS',
            'Description': 'Raises, violations, benefits',
            'SortOrder': 10,
            'IsActive': true
          },
          {
            'ServiceManagerCategoryID': 12,
            'Name': 'Admin',
            'Description': 'Payroll, Payables, Receivables',
            'SortOrder': 12,
            'IsActive': true
          },
          {
            'ServiceManagerCategoryID': 13,
            'Name': 'Quality',
            'Description': '',
            'SortOrder': 13,
            'IsActive': true
          }
        ];
        const unassignedData = _.find(data, { 'Name': '<Unassigned>' });
        const unassignedID = _.get(unassignedData, 'ServiceManagerCategoryID');
        this.setState({ 'categoryId': unassignedID });
      } catch (e) {
        console.log(e);
      }
    }
  }

  async loadRMUserId(clientId: string) {
    if (clientId !== null) {
      try {
        await fetch(`${Environment.get('SPRING_INTEGRATIONS_API_ROOT')}rm/advocates/all`, {})
          .then((response) => response.json())
          .then((data) => {
            this.setState({ 'advocatesData': data });
          });
        const data = [
          {
            'Name': '<Unassigned>',
            'UserID': 0,
            'Username': '',
            'Lastname': '<Unassigned>',
            'Firstname': '',
            'IsActive': false,
            'IsAdmin': false,
            'Email': ''
          }
        ];
        const rmUserId = _.get(data[0], 'UserID');
        this.setState({ 'rmUserId': rmUserId });
      } catch (e) {
        console.log(e);
        this.setState({ 'dataLoadingError': true });
      }
    }
  }

  createServiceIssue = async () => {
    // getting the 'unassigned' values
    const {
      statusId, rmUserId, categoryId, clientId, propertyId
    } = this.state;

    // getting due date, is always 'today' + 1 day
    const isoDate = new Date(`${new Date().toString().split('GMT')[0]} UTC`);
    isoDate.setDate(isoDate.getDate() + 1);
    const stringIsoDate = isoDate.toISOString().split('.')[0];

    const description = `Category: ${this.state.category}
    Message: ${this.state.message}`;

    const loggedUserEmail = _.get(this.props.user, 'attributes.email');

    const requestBody: INewServiceIssueDefinition = {
      'Title': 'Anequim support request',
      'Description': description,
      'StatusID': statusId,
      'AssignedToUserID': rmUserId,
      'CategoryID': categoryId,
      'DueDate': stringIsoDate,
      'LoggedUserEmail': loggedUserEmail,
      'PropertyId': Number(propertyId)
    };

    const requestOptions = {
      'method': 'PUT',
      'headers': { 'Content-Type': 'application/json' },
      'body': JSON.stringify(requestBody)
    };

    try {
      // Send the request to create a new service issue and link tenant and property
      await fetch(`${Environment.get('SPRING_INTEGRATION_HUB_API_ROOT')}rm/service-issues/issue-data/${encodeURI(clientId)}?db=MX`, requestOptions)
        .then((r) => r.json())
        .then((data) => {
          this.handleClose();
          this.setState({ 'feedbackModalShowing': true, 'createdServiceIssue': data });
        });
    } catch (e) {
      console.log(e);
    }
  };

  private loadEmployeeProfileData = async () => {
    try {
      const loggedUserEmail = _.get(this.props.user, 'attributes.email');
      const response = await fetch(`${Environment.get('INTERNAL_REST_SERVICE_API_ROOT')}anequim/employees/employee-profile/${loggedUserEmail}`);
      const data = await response.json();
      this.setState({
        'employeeProfile': data
      });
      return data;
    } catch (e) {
      console.log(e);
      this.setState({ 'error': true });
      return {} as IEmployeeProfileModel;
    }
  }

  private getAdvocateId() {
    const { advocatesData } = this.state;
    const { profileData } = this.props;
    const advocateData = _.find(
      advocatesData, { 'name': profileData.advocate }
    );
    return advocateData ? advocateData.rm_user_id : null;
  }

  // eslint-disable-next-line max-len
  private categoryHandler(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, selectCategories) {
    const catId = e.target.value;
    const cat = _.find(selectCategories, { 'id': catId });
    if (cat.propertyId === '59') {
      const advocateId = this.getAdvocateId();
      if (advocateId !== null) {
        this.setState({
          'category': cat.category,
          'propertyId': cat.propertyId,
          'rmUserId': advocateId
        });
      } else {
        this.setState({
          'category': cat.category,
          'propertyId': cat.propertyId
        });
      }
    } else {
      this.setState({
        'category': cat.category,
        'propertyId': cat.propertyId
      });
    }
  }

  handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    const isValid = this.validateForm();

    if (isValid) {
      await this.createServiceIssue();
    } else {
      // eslint-disable-next-line no-alert
      alert('Todos los campos son requeridos');
    }
  };

  render = () => {
    const selectCategories: Array<any> = [
      { 'id': '1', 'category': 'Holiday/Vacation Balance', 'propertyId': '56' },
      { 'id': '2', 'category': 'Beneficios', 'propertyId': '59' },
      { 'id': '3', 'category': 'Situaciones laborales o personales', 'propertyId': '59' },
      { 'id': '4', 'category': 'Quejas sobre carga laboral u otros proyectos asignados', 'propertyId': '59' },
      { 'id': '5', 'category': 'Incapacidades IMSS', 'propertyId': '56' },
      { 'id': '6', 'category': 'Discrepancias de nómina', 'propertyId': '56' },
      { 'id': '7', 'category': 'Actualización de cuentas bancarias', 'propertyId': '56' },
      { 'id': '8', 'category': 'Recibos de nomina no estan en portal', 'propertyId': '56' },
      { 'id': '9', 'category': 'Problemas con Hubstaff', 'propertyId': '51' },
      { 'id': '10', 'category': 'Solicitud de Constancias', 'propertyId': '51' },
      { 'id': '11', 'category': 'Cambio de informacion personal', 'propertyId': '51' },
      { 'id': '12', 'category': 'Preguntas sobre Webdox/contrato', 'propertyId': '51' },
      { 'id': '13', 'category': 'Problemas con el portal', 'propertyId': '51' }
    ];

    const { createdServiceIssue, dataLoadingError } = this.state;
    if (dataLoadingError) {
      return (
        <Modal animation={false} show={this.state.modalShowing} onHide={this.handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>Ha sucedido un error, por favor intenta de nuevo más tarde.</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
    return (
      <div>
        <Button variant="primary crear-ticket-button" onClick={this.handleShow}>
          Crear ticket
        </Button>

        {/* eslint-disable-next-line max-len */}
        <Modal animation={false} show={this.state.modalShowing} onHide={this.handleClose} centered>
          <Modal.Header closeButton>
            <Container className="d-flex flex-column">
              <Row className="justify-content-center soporte-title">
                <Col><Modal.Title>Soporte</Modal.Title></Col>
              </Row>
              <Row className="mt-auto">
                <Col>
                  <div>Por favor consulta nuestras{' '}<a href={'https://www.sweetprocess.com/kb/5z8Dfvm0aCvx/'}
                                                          target={'_blank'}>FAQs</a>{' '}para encontrar respuestas antes
                    de enviar un ticket.
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Categoría: </Form.Label>
              <Form.Control as="select" required onChange={
                (e) => {
                  this.categoryHandler(e, selectCategories);
                }
              }>
                <option>Seleccionar categoría</option>
                {/* eslint-disable-next-line max-len */}
                {selectCategories.map((value) => <option key={value.id} value={value.id}>{value.category}</option>)}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Mensaje: </Form.Label>
              <Form.Control type="text" as="textarea" rows={3}
                            onChange={(e) => this.setState({ 'message': e.target.value })}
                            value={this.state.message}
                            placeholder="Mensaje"
                            maxLength={350}
                            required/>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <Button variant="secondary" onClick={this.handleClose} style={{ 'marginRight': '10px' }}>
                Cerrar
              </Button>
              <Button variant="primary" onClick={this.handleSubmit}>
                Enviar
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal animation={false}
               show={this.state.feedbackModalShowing}
               onHide={this.handleFeedbackModalClose}
               backdrop="static"
               keyboard={false} centered>
          <Modal.Header closeButton>
            <Modal.Title>Soporte</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Tu solicitud de soporte fue creada
            correctamente {createdServiceIssue && `con el id de ticket: ${createdServiceIssue.ServiceManagerIssueID}, `}
            el equipo de HR se pondrá en contacto contigo
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleFeedbackModalClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };
}
