// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { Analytics } from '@aws-amplify/analytics';
import * as _ from 'lodash';
import { ITileDefinition } from './ITileDefinition';
import ArrowIcon from '../assets/images/landing-page/arrow.svg';

export class Tile extends Component<{}> {
  props: {
    children?: any,
    key: number,
    tileData: any
  };

  state: {
    title?: string,
    usagePercent?: number,
    menuItems?: Array<any>
  } = {};

  constructor(props: any) {
    super(props);
    this.props = props;
  }

  componentDidMount() {
    const tileTitle = _.get(this.props.tileData, 'name');
    const tileUsage = _.get(this.props.tileData, 'usage');
    const tileMenu = _.get(this.props.tileData, 'menuItems');
    this.setState({ 'title': tileTitle, 'usagePercent': tileUsage, 'menuItems': tileMenu });
  }

  handleTileClick = () => {
    Analytics.record({
      'name': 'tileClicked',
      'attributes': { 'tileTitle': this.props.tileData.title }
    })
      .then(() => {
        console.log('Event recorded successfully');
      })
      .catch((err) => {
        console.error('Error recording event:', err);
      });
  };

  render = () => {
    const tileDefinition: ITileDefinition = this.props.tileData;
    const icon = tileDefinition.activeIcon;
    return (
      <div className='home-tile' onClick={this.handleTileClick}>
        <a href={tileDefinition.destination} target={tileDefinition.target}
           rel={tileDefinition.rel}>
          <img className='tile-image' src={icon} alt={tileDefinition.title}/>
          <div className='tile-title'>{tileDefinition.title}</div>
          <div className='tile-description'>{tileDefinition.description}</div>
          <img className='arrow' src={ArrowIcon} alt={tileDefinition.title}/>
        </a>
      </div>
    );
  };
}
