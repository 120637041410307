// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import * as _ from 'lodash';
import TwitterIcon from '../assets/images/twitter.svg';
import InstagramIcon from '../assets/images/instagram.svg';

export class Footer extends Component<{}> {
  props: {
    user: any,
  };

  constructor(props: any) {
    super(props);
    this.props = props;
  }

  renderLegalText(userType: string, currentYear: number) {
    if (userType === 'Client' || userType === 'Supervisor' || userType === 'Decision maker') {
      return `${currentYear} Anequim℠. All rights reserved.`;
    }
    return `Copyright © ${currentYear} Anequim S.A. de C.V. Todos los derechos reservados.`;
  }

  renderSocialText(userType: string) {
    if (userType === 'Client' || userType === 'Supervisor' || userType === 'Decision maker') {
      return 'Connect with Anequim';
    }
    return 'Conecta con Anequim';
  }

  renderSocialLinks(userType: string) {
    if (userType === 'Client' || userType === 'Supervisor' || userType === 'Decision maker') {
      return (
        <>
          <a href="https://www.instagram.com/anequimus/" target="_blank" rel="noopener noreferrer"><img
            src={InstagramIcon} alt='Instagram'/></a>
          <a href="https://twitter.com/AnequimUS" target="_blank" rel="noopener noreferrer"><img
            src={TwitterIcon} alt='Twitter'/></a>
        </>
      );
    }
    return (
      <>
        <a href="https://www.instagram.com/anequimcareersmx/" target="_blank" rel="noopener noreferrer"><img
          src={InstagramIcon} alt='Instagram'/></a>
        <a href="https://twitter.com/AnequimMX" target="_blank" rel="noopener noreferrer"><img
          src={TwitterIcon} alt='Twitter'/></a>
      </>
    );
  }

  render() {
    const userType = _.get(this.props.user, 'attributes.custom:user_type');
    const currentYear = new Date().getFullYear();
    const legalText = this.renderLegalText(userType, currentYear);
    const socialText = this.renderSocialText(userType);
    const socialLinks = this.renderSocialLinks(userType);

    return (
      <div className="legal-banner">
        <div className="legal-banner-content">
          <div className="legal-banner-item-left">
            {legalText}
          </div>
          <div className="legal-banner-item-right">
            {socialText}
            <span className="social-icons">
              {socialLinks}
            </span>
          </div>
        </div>
      </div>
    );
  }
}
