// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import * as _ from 'lodash';
import { Card, Button } from 'react-bootstrap';
import { CenteredSpinner } from './centered_spinner';

export class ContactCard extends Component {
  props: {
        contact: any,
        contactTypes: any,
        parent: any,
        showModal: any
    };

  state: {
        contactType: any,
        loaded: boolean
    } = { 'contactType': null, 'loaded': false };

  constructor(props: any) {
    super(props);
    this.props = props;
  }

  componentDidMount() {
    this.loadContactType();
  }

  async loadContactType() {
    const { contact, contactTypes } = this.props;
    const contactType = this.findContactType(contactTypes, contact);
    await this.setState({ 'contactType': contactType, 'loaded': true });
  }

  findContactType(contactTypes: any, contactToFind: any) {
    let foundContactType: any = null;
    contactTypes.forEach(
      (element: any) => {
        if (element.ContactTypeID === contactToFind.ContactTypeID) {
          foundContactType = element;
        }
      }
    );
    return foundContactType;
  }

  closeModal = () => {
    this.setState({ 'modalShowing': false });
  };

  body = () => {
    const { contact } = this.props;
    const { contactType, loaded } = this.state;
    if (loaded && !_.isNil(contactType)) {
      let date = '';
      let name = '';
      let contactTypeName = '';
      // eslint-disable-next-line max-len
      if (!_.isNil(contact.DateOfBirth) && !_.isNil(contact.LastName) && !_.isNil(contactType.Name)) {
        // eslint-disable-next-line prefer-destructuring
        date = new Date(parseInt(contact.DateOfBirth)).toISOString().split('T')[0];
        name = contact.LastName;
        contactTypeName = contactType.Name;
      } else {
        date = 'UNKNOWN';
        name = 'UNKNOWN';
        contactTypeName = 'UNKNOWN';
      }
      return (
            <div className='mt-3'>
                <Card>
                    <Card.Body>
                        <p><strong>Nombre:</strong> {name}</p>
                        {/* eslint-disable-next-line max-len */}
                        <p><strong>Fecha de nacimiento:</strong> {date}</p>
                        <p><strong>Tipo de contacto:</strong> {contactTypeName} </p>
                        <Button variant="primary" onClick={ () => this.props.showModal(contact)}>Editar</Button>
                    </Card.Body>
                </Card>
            </div>
      );
    }

    return (
        <CenteredSpinner />
    );
  };

  render() {
    return (
            <div>
                {this.body()}
            </div>
    );
  }
}
