export class ZohoHelper {
   accessToken = '';

   public static loginToZoho = (username: string, password: string): Promise<Response> => fetch('https://amazingbusinessresults.com/Zoho_Extension/Test/anequim.php', {
     'method': 'POST',
     'body': JSON.stringify({
       'username': username,
       'password': password,
       'function_type': 'login'
     })
   });

   public static getAccessToken = (): string => {
     if (sessionStorage.getItem('zohoAccessToken') != null) {
       const accessTokenData = JSON.parse(sessionStorage.getItem('zohoAccessToken') ?? '');
       if (accessTokenData.expiry > (new Date())) {
         return accessTokenData.accessToken;
       }
       const formdata = new FormData();
       formdata.append('grant_type', 'authorization_code');
       formdata.append('client_id', '1000.VI9OVM27LJS88RYPQ83UCRLZ7TG6CJ');
       formdata.append('client_secret', '60848c5f18b03c4fe571ede9e232551b4fa9df72f5');
       formdata.append('redirect_uri', 'https://google.com');
       formdata.append('code', '1000.6cfdcf838b78b54a17305ba0c731f072.10f1f38938326c95c88ca26342c745ea');

       const requestOptions = {
         'method': 'POST',
         'body': formdata
       };

       fetch('https://accounts.zoho.com/oauth/v2/token', requestOptions)
         .then((response) => response.text())
         .then((result) => console.log(result))
         .catch((error) => console.error(error));
     }
     return '';
   }
}
