// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import '../assets/styles/upload-fragment.scss';
import * as _ from 'lodash';
import { Button, Form, Table } from 'react-bootstrap';
import { CenteredSpinner } from './centered_spinner';
import { IFileDetail } from './IFileDetail';
import { IFileDetailError } from './IFileDetailError';
import EmployeeTypeAhead from './EmployeeTypeAhead';
import TrashCanIcon from '../assets/images/trash-can-regular.svg';
import { Environment } from '../environment';
import { IEmployeeTypeAheadData } from './IEmployeeTypeAheadData';

interface UploadFragmentProps {
  'onSubmit': Function
}

class UploadFragment extends Component<UploadFragmentProps, any> {
  state: {
    isLoading: boolean;
    fileDetailsList: Array<IFileDetail>;
    organizationsData: Array<any>;
    vendorsData: Array<any>;
    fileList: Array<any>;
    employeeData: Array<any>;
    globalCheckboxValue: boolean;
    fileDetailsErrors: Array<IFileDetailError>;
    apiError: boolean;
    nominas: Array<any>;
    typeaheadEmployees: Array<IEmployeeTypeAheadData>;
    count: number;
  } = {
    'fileList': [],
    'fileDetailsList': [],
    'organizationsData': [],
    'vendorsData': [],
    'isLoading': false,
    'employeeData': [],
    'globalCheckboxValue': false,
    'fileDetailsErrors': [],
    'apiError': false,
    'nominas': [],
    'typeaheadEmployees': [],
    'count': 0
  };

  componentDidMount() {
    this.setState({ 'isLoading': true });

    Promise.all(
      [this.loadOrganizationsData(),
        this.loadEmployeesData(),
        this.loadVendorsData(),
        this.loadNominas(),
        this.loadAllEmployees()]
    ).then(() => {
      this.setState({ 'isLoading': false });
    });
  }

  render() {
    if (this.state.isLoading) {
      return (
        <CenteredSpinner/>
      );
    }

    if (this.state.apiError) {
      return (
        <div>
          There was an error loading some data, please try again later
        </div>
      );
    }

    return (<div>
        <div className="upload-content">
          <h3>Subir un archivo</h3>
          <div className="container">
            <input type="file" className="hidden"
                   multiple={false}
                   accept=".json,.csv,.txt,.text,application/json,text/csv,text/plain,.pdf,.zip,.png,.tif,.jpg,.docx,.doc,.xlsx,.xls"
                   onChange={(evt) => this.openFile(evt)}
                   title=" "/>
            <Button className="subir-button" type={'primary'} onClick={this.submit}>Subir</Button>
          </div>
          <div className={'col-lg-9 file-list'}>
            <h5>Archivos por subir</h5>
            <hr/>
            {!(_.size(this.state.fileList) > 0) ? null
              : <Table striped bordered hover size="sm">
                <thead>
                <tr>
                  <th className={'name-column'}>Nombre</th>
                  <th>Archivo de Nómina <input
                    type="checkbox"
                    checked={this.state.globalCheckboxValue}
                    onChange={() => this.globalPayrollCheckboxHandler()}
                  /></th>
                  <th className={'vendor-column'}>Vendor</th>
                  <th className={'nomina-column'}>Nómina</th>
                  <th className={'employee-column'}>Empleado</th>
                  <th className={'delete-column'}>Borrar</th>
                </tr>
                </thead>
                <tbody>
                {this.getFilenames()}
                </tbody>
              </Table>
            }
          </div>
        </div>
      </div>
    );
  }

  submit = (e: any) => {
    e.preventDefault();

    const fileDetailsErrors = this.validateFileDetails();
    if (!_.isEmpty(fileDetailsErrors)) {
      this.setState({ 'fileDetailsErrors': fileDetailsErrors });
    }
    if (!_.isNil(this.state.fileList) && _.isEmpty(fileDetailsErrors)) {
      this.setState({ 'isLoading': true });
      const p: Promise<any> = this.props.onSubmit(this.state.fileList, this.state.fileDetailsList);
      p.finally(() => {
        this.setState({ 'fileList': [], 'fileDetailsList': [] });
        this.setState({ 'isLoading': false });
      });
    }
  };

  validateFileDetails = (): Array<IFileDetailError> => {
    const { fileList } = this.state;
    const detailsErrors: Array<IFileDetailError> = [];
    _.forEach(fileList, (file) => {
      const fileName = _.get(file, 'name');
      const fileDetailErrors = this.getFileDetailsErrors(fileName);
      if (!_.isNil(fileDetailErrors)) {
        detailsErrors.push(fileDetailErrors);
      }
    });
    return detailsErrors;
  };

  getFileDetailsErrors = (filename: string): IFileDetailError | undefined => {
    const detail = this.getFileDetails(filename);
    const errors: IFileDetailError = {
      'fileName': '',
      'fieldName': ''
    };

    const isPayroll = _.get(detail, 'isPayroll');

    const vendor = _.get(detail, 'vendorId');
    if (isPayroll && _.isNil(vendor)) {
      errors.fieldName = 'vendorId';
      errors.fileName = filename;
      return errors;
    }

    const nomina = _.get(detail, 'nominaId');
    if (isPayroll && _.isNil(nomina)) {
      errors.fieldName = 'nominaId';
      errors.fileName = filename;
      return errors;
    }

    return undefined;
  };

  private openFile(evt: React.ChangeEvent<HTMLInputElement>) {
    const fileArg = evt.target.files;
    const fileObj = _.head(fileArg) || null;
    const reader = new FileReader();
    if (fileObj) {
      reader.readAsText(fileObj);
      const { fileList } = this.state;
      fileList.push(fileObj);
      console.log('Files: ', fileList);
      const detail = this.setDefaults(_.get(fileObj, 'name') || '');
      const fileDetails = this.state.fileDetailsList;
      if (detail) {
        this.setState({ 'fileDetailsList': fileDetails });
      }
    }
  }

  private setDefaults = (fileName: string): IFileDetail | undefined => {
    if (_.isNil(fileName)) {
      return undefined;
    }
    const newDetail: IFileDetail = {
      'fileName': fileName,
      'isPayroll': false,
      'organizationId': undefined,
      'vendorId': undefined,
      'employee': undefined
    };
    this.state.fileDetailsList.push(newDetail);
    return newDetail;
  };

  private handleSelect(event: any, fileName: string) {
    const selectedValue = event.target.value;
    if (_.isNil(selectedValue)) {
      return;
    }
    const {
      organizationsData,
      vendorsData,
      fileDetailsErrors
    } = this.state;
    const selectedOrg = _.find(organizationsData, { 'id': selectedValue });
    const fileDetails = this.getFileDetails(fileName);
    if (!fileDetails) {
      return;
    }
    if (!_.isNil(selectedOrg)) {
      const selectedVendor = _.find(vendorsData, { 'organizationId': selectedOrg.id });
      fileDetails.vendorId = _.get(selectedVendor, 'id');
      fileDetails.organizationId = _.get(selectedOrg, 'id');
    }
    const fileErrors = this.getFileDetailsErrorState(fileName);
    if (fileErrors) {
      // eslint-disable-next-line max-len
      const newDetailErrors = _.filter(fileDetailsErrors, (detail) => detail.fileName !== fileName);
      this.setState({ 'fileDetailsErrors': newDetailErrors });
      // this.forceUpdate();
      // return;
    }
  }

  private handleNominaSelect(event: any, filename: string) {
    const selectedValue = event.target.value;
    const fileDetails = this.getFileDetails(filename);
    if (fileDetails) {
      fileDetails.nominaId = selectedValue;
    }
    const fileErrors = this.getFileDetailsErrorState(filename);
    const { fileDetailsErrors } = this.state;
    if (fileErrors) {
      // eslint-disable-next-line max-len
      const newDetailErrors = _.filter(fileDetailsErrors, (detail) => detail.fileName !== filename);
      this.setState({ 'fileDetailsErrors': newDetailErrors });
      // this.forceUpdate();
      // return;
    }
  }

  private getFilenames = () => {
    const filenames: any[] = [];
    const { organizationsData, nominas, typeaheadEmployees } = this.state;
    const sortedNominas = _.sortBy(nominas, 'payPeriodNumber');
    const nominaOptions = _.map(sortedNominas, (nomina) => (
      <option key={_.get(nomina, 'id')} value={_.get(nomina, 'id')}>{nomina.description}</option>));
    const selectOptions = _.map(organizationsData, (org) => (
      <option key={_.get(org, 'id')} value={_.get(org, 'id')}>{_.get(org, 'name')}</option>));
    _.forEach(this.state.fileList, (file) => {
      const fileName: string = _.get(file, 'name');
      const rawCurrentFileDetails = this.getFileDetails(fileName);
      const isPayrollValue = _.get(rawCurrentFileDetails, 'isPayroll', false);
      const currentEmployee = _.get(rawCurrentFileDetails, 'employee');
      const selectedOption = currentEmployee ? [currentEmployee] : [];
      const fileErrors = this.getFileDetailsErrorState(fileName);
      const fieldError = _.get(fileErrors, 'fieldName');
      // @ts-ignore
      filenames.push(
        <tr key={fileName}>
          <td>{fileName}</td>
          <td><input type="checkbox" checked={isPayrollValue} onChange={() => this.setCheckboxValue(fileName)}/></td>
          <td>{
            isPayrollValue
            && <Form.Group controlId="validationCustom03">
              <Form.Control as="select"
                            onChange={(event) => this.handleSelect(event, fileName)}
                            isInvalid={!_.isNil(fileErrors) && fieldError === 'vendorId'}>
                <option>Elige vendor</option>
                {selectOptions}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Elige un vendor por favor
              </Form.Control.Feedback>
            </Form.Group>
          }</td>
          <td>{
            isPayrollValue
            && <Form.Group controlId="validationCustom04">
              <Form.Control as="select"
                            onChange={(event) => this.handleNominaSelect(event, fileName)}
                            isInvalid={!_.isNil(fileErrors) && fieldError === 'nominaId'}>
                <option>Elige nómina</option>
                {nominaOptions}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Elige una nómina por favor
              </Form.Control.Feedback>
            </Form.Group>
          }</td>
          <td>
            <EmployeeTypeAhead
              handler={this.typeaheadHandler}
              selectedOption={selectedOption}
              options={typeaheadEmployees}
              rowIdentifier={fileName}
              isDisabled={isPayrollValue}
            />
          </td>
          <td className='delete-icon-container'>
            <a href='#' onClick={(e) => this.deleteFileHandler(e, fileName)}>
              <img className='trash-can-icon' src={TrashCanIcon} alt={'Borrar'}/>
            </a>
          </td>
        </tr>
      );
    });
    return filenames;
  };

  private typeaheadHandler = (selectedOption: any, fileName: string) => {
    if (!_.isNil(fileName)) {
      const fileDetails = this.getFileDetails(fileName);
      if (fileDetails) {
        fileDetails.employee = selectedOption;
        this.updateFileDetail(fileDetails);
      }
    }
    // this.forceUpdate();
  };

  private setCheckboxValue(fileName: string) {
    const fileDetails = this.getFileDetails(fileName);
    const suggestedEmployee = this.getSuggestedEmployee(fileName);
    if (fileDetails) {
      fileDetails.isPayroll = !_.get(fileDetails, 'isPayroll');
      if (!_.isNil(suggestedEmployee)) {
        fileDetails.employee = suggestedEmployee;
      }
      this.updateFileDetail(fileDetails);
    }
    // this.forceUpdate();
  }

  private updateFileDetail(updatedFileDetail: IFileDetail) {
    const newDetails = _.filter(this.state.fileDetailsList,
      (detail: IFileDetail) => _.get(detail, 'fileName') !== _.get(updatedFileDetail, 'fileName'));
    newDetails.push(updatedFileDetail);
    this.setState({ 'fileDetailsList': newDetails });
  }

  private deleteFileHandler(event: any, fileName: String) {
    event.preventDefault();
    const { fileList } = this.state;
    const newFileList = [...fileList];
    _.remove(newFileList, { 'name': fileName });
    this.setState({ 'fileList': newFileList });
  }

  private getSuggestedEmployee(fileName: String) {
    const fileNumber = _.get(_.split(fileName.valueOf(), '_'), 4, undefined);
    const providedPayNumber = fileNumber
      ? _.get(_.split(fileNumber.valueOf(), '.'), 0, undefined)
      : undefined;
    if (providedPayNumber !== undefined) {
      const { employeeData } = this.state;
      return _.find(employeeData, (emp) => emp.paystubReference === providedPayNumber);
    }
    return undefined;
  }

  async loadOrganizationsData() {
    await fetch(`${Environment.get('INTERNAL_REST_SERVICE_API_ROOT')}anequim/organizations/all`, {})
      .then((response) => response.json())
      .then((data) => {
        this.setState({ 'organizationsData': data, 'apiError': false });
      }).catch(() => this.setState({ 'apiError': true }));
  }

  async loadVendorsData() {
    await fetch(`${Environment.get('INTERNAL_REST_SERVICE_API_ROOT')}anequim/vendors/all`, {})
      .then((response) => response.json())
      .then((response) => {
        this.setState({ 'vendorsData': response, 'apiError': false });
      }).catch(() => this.setState({ 'apiError': true }));
  }

  async loadEmployeesData() {
    await fetch(`${Environment.get('INTERNAL_REST_SERVICE_API_ROOT')}anequim/employees/payroll`, {})
      .then((response) => response.json())
      .then((data) => {
        const filteredResponse = _.filter(data, (emp) => !_.isNil(emp.name));
        this.setState({ 'employeeData': filteredResponse, 'apiError': false });
      }).catch(() => this.setState({ 'apiError': true }));
  }

  private globalPayrollCheckboxHandler() {
    // Get all document details
    const { fileList, globalCheckboxValue } = this.state;
    // update all file details related to the global isPayroll checkbox
    _.map(fileList, (file) => {
      const fileName = _.get(file, 'name');
      const fileDetails = this.getFileDetails(fileName);
      if (fileDetails) {
        fileDetails.isPayroll = !globalCheckboxValue;
        fileDetails.vendorId = undefined;
        fileDetails.organizationId = undefined;
      }
    });
    this.setState({
      'globalCheckboxValue': !globalCheckboxValue
    });
  }

  private getFileDetails = (fileName: string): IFileDetail | undefined => _.find(this.state.fileDetailsList, { 'fileName': fileName }) || this.setDefaults(fileName);

  private getFileDetailsErrorState = (fileName: string): IFileDetailError | undefined => _.find(this.state.fileDetailsErrors, { 'fileName': fileName });

  async loadNominas() {
    const today = new Date();
    const thisYear = today.getFullYear();
    // eslint-disable-next-line max-len
    await fetch(`${Environment.get('INTERNAL_REST_SERVICE_API_ROOT')}timesheetperiods/year/${thisYear}`, {})
      .then((response) => response.json())
      .then((data) => {
        this.setState({ 'nominas': data });
      });
  }

  private async loadAllEmployees() {
    await fetch(`${Environment.get('INTERNAL_REST_SERVICE_API_ROOT')}anequim/employees/`, {})
      .then((response) => response.json())
      .then((data) => {
        const filteredResponse = _.filter(data, (emp) => !_.isNil(_.get(emp, 'name')));
        this.setState({ 'typeaheadEmployees': filteredResponse });
      });
  }
}

export default UploadFragment;
