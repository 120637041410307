// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import '../assets/styles/check-detail.scss';

export class CheckDetail extends Component<{}> {
  props: {
    checkNum: number
  };

  constructor(props: any) {
    super(props);
    this.props = props;
  }

  render() {
    return (<div>
      {/* <EmployeeInfoGrid/> */}
      {/*  <PayDetailsGrid/> */}
      {/*  /!* <TaxAndDeductionGrid/> *!/ */}
      {/*  <TimeOffGrid/> */}
    </div>
    );
  }
}
