/* eslint-disable space-infix-ops */
// eslint-disable-next-line no-use-before-define,no-unused-vars
import React, { Component, useRef } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import * as _ from 'lodash';
import '../assets/styles/reporting.scss';
import '../assets/styles/datepicker.scss';
import { Button } from 'react-bootstrap';
import { CenteredSpinner } from '../components/centered_spinner';
import { Environment } from '../environment';
import { Utils } from '../utils/utils';

export class Reporting extends Component<{}> {
  state : {
    'paramsShowingEMPRPT': boolean;
    'paramsShowingCC': boolean;
    'paramsShowingBEN': boolean;
    'paramsShowingUNTJRN': boolean;
    'inputStart': string,
    'inputFinish': string,
    'inputRef1' : any,
    'inputRef2' : any,
    'showSpinner': boolean,
    'unitId': number,
    'unitIdField': Array<any>,
    loading: boolean;
  } = {
    'paramsShowingEMPRPT': false,
    'paramsShowingCC': false,
    'paramsShowingBEN': false,
    'paramsShowingUNTJRN': false,
    'inputStart': '01/01/2021',
    'inputFinish': '01/31/2021',
    'inputRef1': null,
    'inputRef2': null,
    'showSpinner': false,
    'unitId': 0,
    'unitIdField': [],
    'loading': true
  };

  componentDidMount() {
    Promise.all([this.loadUnitIdField()]).then(() => {
      this.setState({ 'loading': false });
    });
  }

  async loadUnitIdField() {
    await fetch(`${Environment.get('INTERNAL_REST_SERVICE_API_ROOT')}anequim/employees/`, {}).then((response) => {
      this.setState({ 'unitIdField': response });
    });
  }

  reportMenu = () => (
    <div className='headline-reports'>
      <div className='report'>
        <a className='report-link' onClick={(event) => { this.showRMCCParams(event); } } data-report="CC">RMCC Call Center Data</a>
        <div hidden={!this.state.paramsShowingCC}>
          {this.dateRangePicker('Start Date', 1)}
          {this.dateRangePicker('End Date', 2)}
          <Button variant="primary" onClick={() => { this.submitRmcc(); } }>Submit</Button>
        </div>
      </div>
      <div className='report'>
        <a className='report-link' onClick={(event) => { this.showBenefitSignUpParams(event); } } data-report="BEN">Benefits Sign-Up Report</a>
        <div hidden={!this.state.paramsShowingBEN}>
          {this.dateRangePicker('Start Date', 1)}
          {this.dateRangePicker('End Date', 2)}
          <Button variant="primary" onClick={() => { this.submitBenefitsSignUp(); } }>Submit</Button>
        </div>
        </div>
      <div className='report'>
        <a className='report-link' onClick={(event) => { this.showUnitJournalParams(event); } } data-report="UNTJRN">Journal Entries for Unit Report</a>
        <div hidden={!this.state.paramsShowingUNTJRN}>
          {this.unitIdField()}
          <Button variant="primary" onClick={() => { this.submitUnitJournals(); } }>Submit</Button>
        </div>
      </div>
      <div className='report'>
        <a className='report-link' onClick={(event) => { this.showEmployeeReportSubmitButton(event); } } data-report="EMPRPT">All Employees Report</a>
        <div hidden={!this.state.paramsShowingEMPRPT}>
          <Button variant="primary" onClick={() => { this.submitEmployeeReport(); } }>Submit</Button>
        </div>
      </div>
    </div>
  );

  submitRmcc = async () => {
    const startVal = (_.isNil(this.state.inputRef1)
      ? '01/01/2021' : this.state.inputRef1.current.children[0].value);
    const endVal = (_.isNil(this.state.inputRef2)
      ? '01/31/2021' : this.state.inputRef2.current.children[0].value);
    const startMillis = (new Date(startVal).getTime()) / 1000;
    const endMillis = (new Date(endVal).getTime()) / 1000;

    this.toggleSpinner();
    try {
      await fetch(`${Environment.get('SPRING_RMLS_API_ROOT')}reports/rmcc/startDate/${startMillis}/endDate/${endMillis}`, {})
      // eslint-disable-next-line no-unused-vars
        .then((data) => data.blob().then((blob) => Utils.download(blob, 'rmcc-data.csv')))
        .finally(() => {
          this.toggleSpinner();
        });
    } catch (e) {
      console.log(e);
    } finally {
      this.toggleSpinner();
    }
  };

  submitBenefitsSignUp = async () => {
    const startVal = (_.isNil(this.state.inputRef1)
      ? '01/01/2021' : this.state.inputRef1.current.children[0].value);
    const endVal = (_.isNil(this.state.inputRef2)
      ? '01/31/2021' : this.state.inputRef2.current.children[0].value);
    const startMillis = (new Date(startVal).getTime()) / 1000;
    const endMillis = (new Date(endVal).getTime()) / 1000;
    this.toggleSpinner();
    try {
      await fetch(`${Environment.get('SPRING_RMLS_API_ROOT')}reports/benefits-sign-up/startDate/${startMillis}/endDate/${endMillis}`, {})
        // eslint-disable-next-line no-unused-vars
        .then((data) => data.blob().then((blob) => Utils.download(blob, 'benefits-sign-up-data.csv')))
        .finally(() => {
          this.toggleSpinner();
        });
    } catch (e) {
      console.log(e);
    } finally {
      this.toggleSpinner();
    }
  };

  toggleSpinner() {
    this.setState({ 'showSpinner': !this.state.showSpinner });
  }

  unitIdField = () => {
    const ref3 = useRef<HTMLDivElement | null>(null);
    const label = 'Rent Manager Unit ID';
    const fieldData = this.state.unitIdField;
    const orderedFieldData = _.sortBy(fieldData, (o) => o.name);
    return (
      <div >
        <label>{label}</label>
        <div id={'unit-id'} ref={ref3} >
          <select value={this.state.unitId} onChange={this.onUnitInputChange} className="form-control col-4">
            {/* eslint-disable-next-line max-len */}
            {orderedFieldData.map((unit) => <option key={unit.id} value={unit.id}>{unit.name}</option>)}
          </select>
        </div>
      </div>);
  };

  dateRangePicker(label: string, num : number) {
    const initialDate = (num === 1 ? this.state.inputStart
      : this.state.inputFinish);
    const ref1 = useRef<HTMLDivElement | null>(null);
    const ref2 = useRef<HTMLDivElement | null>(null);

    const activeRef = num === 1 ? ref1 : ref2;
    return (
      <div >
        <label>{label}</label>
        <div id={`range-pick-${num}`} ref={activeRef} >
          <DateRangePicker onApply={() => this.setRef(activeRef, num)} initialSettings={{
            'singleDatePicker': true,
            'showDropdowns': true,
            'startDate': initialDate,
            'minYear': 2014
          }}
          >
            <input type="text" className="form-control col-4" />
          </DateRangePicker>
        </div>
      </div>);
  }

  showRMCCParams = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    this.setState({ 'paramsShowingCC': !this.state.paramsShowingCC });
  };

  showBenefitSignUpParams = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    this.setState({ 'paramsShowingBEN': !this.state.paramsShowingBEN });
  };

  closeModal = () => {
    this.setState({ 'modalShowing': false });
  };

  render() {
    if (this.state.showSpinner) {
      return (<CenteredSpinner/>);
    }

    return (<div id={'reporting'}>
      <h2>Reporting</h2>
      {/* eslint-disable-next-line no-undef */}
      <this.reportMenu/>
    </div>);
  }

  private setRef(activeRef: React.MutableRefObject<HTMLDivElement | null>, num: number) {
    if (num === 1) {
      this.setState({ 'inputRef1': activeRef });
    } else {
      this.setState({ 'inputRef2': activeRef });
    }
  }

  private showUnitJournalParams = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    this.setState({ 'paramsShowingUNTJRN': !this.state.paramsShowingUNTJRN });
  };

  private submitUnitJournals = async () => {
    this.toggleSpinner();
    try {
      await fetch(`${Environment.get('SPRING_RMLS_API_ROOT')}reports/unit-journals/unit/${this.state.unitId}`, {})
        // eslint-disable-next-line no-unused-vars
        .then((data) => data.blob().then((blob) => Utils.download(blob, `unitjournal-data-${this.state.unitId}.csv`)))
        .finally(() => {
          this.toggleSpinner();
        });
    } catch (e) {
      console.log(e);
    } finally {
      this.toggleSpinner();
    }
  };

  private onUnitInputChange = (event: any) => {
    event.preventDefault();
    this.setState({ 'unitId': event.target.value });
  };

  private showEmployeeReportSubmitButton = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    this.setState({ 'paramsShowingEMPRPT': !this.state.paramsShowingEMPRPT });
  };

  private submitEmployeeReport = async () => {
    this.toggleSpinner();
    try {
      await fetch(`${Environment.get('SPRING_RMLS_API_ROOT')}reports/employees/csv`, {})
        // eslint-disable-next-line no-unused-vars
        .then((data) => data.blob().then((blob) => Utils.download(blob, `employee-data-${this.state.unitId}.csv`)))
        .finally(() => {
          this.toggleSpinner();
        });
    } catch (e) {
      console.log(e);
    } finally {
      this.toggleSpinner();
    }
  };
}
