// eslint-disable-next-line no-use-before-define,no-unused-vars
import React, { Component } from 'react';
import '../assets/styles/my-payroll.scss';
import { Link } from 'react-router-dom';
import HamburgerMenu from '../assets/images/bars-solid.svg';
import '../assets/styles/sidebar.scss';

export class Sidebar extends Component<{}> {
  private menuItems: Array<any>;

  state = { 'showSidebarMenuItems': false };

  props: {
    menuItems: Array<any>;
  } = { 'menuItems': [] };

  constructor(propsA: any) {
    super(propsA);
    this.props = propsA;
    this.menuItems = propsA.menuItems;
  }

  render = () => (
      <div>
        <nav className={this.menuItems ? 'nav-menu active' : 'nav-menu'}>
          <ul className='nav-menu-items' onClick={this.toggleSidebar}>
            <li className='navbar-toggle'>
              <Link to='#' className='menu-bars'>
                <img className='' src={HamburgerMenu}/>
              </Link>
            </li>
            { this.getmenuItems() }
          </ul>
        </nav>
    </div>
  );

  private getmenuItems = () => {
    if (this.state.showSidebarMenuItems) {
      return (<div className={'item-list'}>
          {this.menuItems.map((item, index) => (
            <li key={index} className={item.cName}>
              <Link to={item.path}>
                {item.icon}
                <span>{item.title}</span>
              </Link>
            </li>
          ))}
        </div>
      );
    }
    return null;
  };

  private toggleSidebar = () => {
    this.setState({ 'showSidebarMenuItems': !this.state.showSidebarMenuItems });
  };
}
