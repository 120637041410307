/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';
import { Nav } from './components/nav';
import { BenefitsHome } from './pages/BenefitsHome';
import { Profile } from './pages/Profile';
import { Contacts } from './pages/Contacts';
import { Benefit } from './pages/Benefit';
import './assets/styles/authenticated.scss';
import { AwardCalculator } from './pages/AwardCalulator';
import { Admin } from './pages/Admin';
import { Landing } from './pages/Landing';
import { Collections } from './pages/Collections';
import { RmccHome } from './pages/RmccHome';
import { Configuration } from './pages/Configuration';
import { Payables } from './pages/Payables';
import { Reporting } from './pages/Reporting';
import { ServiceIssues } from './pages/ServiceIssues';
import { MyWorkday } from './pages/MyWorkday';
import { MyBalance } from './pages/MyBalance';
import { HrPage } from './pages/HrPage';
import { EmployeeProfile } from './pages/EmployeeProfile';
import { CheckDetail } from './pages/CheckDetail';
import { PayrollAdmin } from './pages/PayrollAdmin';
import { MyDocuments } from './pages/MyDocuments';
import { EmployeeReferences } from './pages/EmployeeReferences';
import { Changelog } from './pages/Changelog';
import { RemoteProfessionals } from './pages/RemoteProfessionals';
import { ClientServiceIssues } from './pages/ClientServiceIssues';

type AuthenticatedAppState = {
  echo: any;
}

export class AuthenticatedApp extends Component<{}, AuthenticatedAppState> {
  state: {
    user: any,
    echo: any,
    loading: boolean,
    color: string
  } = {
    'user': null, 'echo': null, 'loading': true, 'color': 'blue'
  };

  props: {
    user: any
  };

  constructor(props: any) {
    super(props);
    this.props = props;
  }

  get user(): any {
    return this.props.user;
  }

  render() {
    return (
      <div id='root-container' className='container-fluid authenticated-app'>
        <input type='hidden' value={this.user?.signInUserSession?.idToken?.jwtToken}></input>

        <HashRouter>
          <Nav user={this.user}></Nav>
          {
            <Switch>
              <Route path='/profile'> <Profile user={this.user}/> </Route>
              <Route path='/contacts'> <Contacts user={this.user}/> </Route>
              <Route path='/benefits/:benefitKey/tenant/:tenant'
                     render={(props: any) => <Benefit benefitKey={props.match.params.benefitKey}
                                                      userHasBenefit={!!props.match.params.userHasBenefit}
                                                      user={this.user} tenant={props.match.params.tenant}/>}> </Route>
              <Route path='/calculator'> <AwardCalculator/> </Route>
              <Route path='/admin' component={Admin}/>
              <Route path='/collections' component={Collections}/>
              <Route path='/rmcc' component={RmccHome}/>
              <Route path='/configuration' component={Configuration}/>
              <Route path='/payables' component={Payables}/>
              <Route path='/reporting' component={Reporting}/>
              <Route path='/hr-page'> <HrPage user={this.user}/> </Route>
              <Route path='/employee-refs'> <EmployeeReferences user={this.user}/> </Route>
              <Route path='/service-issues'> <ServiceIssues user={this.user}/> </Route>
              <Route path='/my-workday' component={MyWorkday}/>
              <Route path='/balance' component={MyBalance}/>
              <Route path='/my-documents'> <MyDocuments user={this.user}/> </Route>
              <Route path='/payroll-admin' component={PayrollAdmin}/>
              <Route path='/employee-profile-admin' component={EmployeeProfile}/>
              <Route path='/check/:checkNum'
                     render={(props: any) => <CheckDetail checkNum={props.match.params.checkNum}/>}/>
              <Route path='/benefits'> <BenefitsHome user={this.user}/> </Route>
              <Route path='/changelog' component={Changelog}/>
              <Route path='/remote-professionals'> <RemoteProfessionals user={this.user} /> </Route>
              <Route path='/client-services'> <ClientServiceIssues user={this.user}/> </Route>
              <Route path='/'> <Landing user={this.user}/> </Route>
            </Switch>
          }
        </HashRouter>
      </div>
    );
  }
}
