// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { API } from '@aws-amplify/api';
import { Button, Modal } from 'react-bootstrap';
import _ from 'lodash';
// Importing toastify module
import { toast } from 'react-toastify';
import { CenteredSpinner } from '../components/centered_spinner';
import { Benefits } from '../benefit_types';
import '../assets/styles/benefit.scss';
import { PdfViewer } from '../components/pdf/PdfViewer';
import { ErrorModal } from '../components/ErrorModal';
import AnequimBucksIconBlue from '../assets/images/benefits/a-rewards-blue.svg';

const BenefitKeyMap: Map<string, string> = new Map([
  ['life', 'L'],
  ['majorMedical', 'GMM'],
  ['minorMedical', 'GMN']
]);

export class Benefit extends Component<{}> {
  state: {
    benefitPrices?: any;
    balance?: number;
    email?: string;
    profileData?: any;
    costForEmployee?: number;
    modalShowing?: boolean;
    errorModalShowing: boolean;
    selectedTier?: string;
    rates?: Map<string, number>;
    isLoading?: boolean;
    userHasBenefit?: boolean;
    isProcessing?: boolean;
    benefitElections?: Array<any>
  } = { 'errorModalShowing': false };

  props: {
    benefitKey: string,
    userHasBenefit: boolean,
    tenant: number,
    user: any
  };

  private instanceCount: string;

  private errorMessage: string;

  private errorBody: string;

  constructor(props: any) {
    super(props);
    this.props = props;
    this.state.selectedTier = 'STD';
    this.state.isLoading = true;
    this.instanceCount = _.uniqueId('Benefit');
    this.errorBody = '';
    this.errorMessage = '';
    this.setState({ 'userHasBenefit': this.props.userHasBenefit });
  }

  componentDidMount() {
    // if (!this.state.userHasBenefit) {
    // double check the benefit election
    // this.isInBenefitElections(this.props.benefitKey);
    // }

    Promise.all([this.loadBalance(),
      this.loadProfileData(),
      this.loadBenefitPrices(),
      this.loadExchangeRates(),
      this.loadBenefitElections()
    ]).then(() => {
      this.setState({ 'isLoading': false });
    }).catch((reason: any) => {
      console.error(reason);
      this.setState({ 'isLoading': false });
    });
  }

  private isInBenefitElections(benefitName: string): boolean {
    const itm = _.find(this.state.benefitElections, { 'benefitOffering': _.capitalize(benefitName) });
    return (itm !== undefined);
  }

  loadBenefitElections = async () => {
    const elections = await API.get('BenefitElectionsModel', `?tenantId=${this.props.tenant}`, {});
    this.setState({ 'benefitElections': elections });
  };

  loadBalance = async () => {
    const loggedUserEmail = _.get(this.props.user, 'attributes.email');
    const eeData = await API.get('AwardsBalance', `Anequim/balance/${loggedUserEmail}?db=MX`, {});
    this.setState({ 'balance': eeData.balance, 'email': loggedUserEmail });
  };

  loadProfileData = async () => {
    const loggedUserEmail = _.get(this.props.user, 'attributes.email');
    this.state.profileData = await API.get('Employee', `Anequim/${loggedUserEmail}?db=MX`, {});
    // const age = this.getAge();
  };

  getAge = () => {
    const primCont = _.get(this.state.profileData, 'personalContacts')?.filter(
      (c: any) => c.IsPrimary && c.contactTypeID === 2
    );
    if (!primCont.length) {
      return 0;
    }

    const now = new Date();
    const currentDate = now.getDate();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();
    const ax = new Date(primCont[0].dateOfBirth);
    const bx = new Date(primCont[0].dateOfBirth);
    const cx = new Date(primCont[0].dateOfBirth);
    const birthDate = ((ax) === null
    || ax === undefined ? undefined : ax.getDate()) || currentDate;
    const birthMonth = ((bx) === null
    || bx === undefined ? undefined : bx.getMonth()) || currentMonth;
    const birthYear = ((cx) === null
    || cx === undefined ? undefined : cx.getFullYear()) || currentYear;
    let a = currentYear - birthYear;

    if (currentMonth <= birthMonth && currentDate < birthDate) {
      a--;
    }
    return a;
  };

  balance(): number | undefined {
    return this.state.balance;
  }

  purchaseButton = () => (
    <div className='text-center button-padding'>
      <Button className="anequim-button" onClick={this.showModal}
              disabled={this.state.userHasBenefit}>Adquirir</Button>
    </div>
  );

  updateTier = (i: any) => {
    this.setState({ 'selectedTier': i });
  };

  selectBenefitTier = () => (
    <div>
      <select className="browser-default custom-select" defaultValue={this.state.selectedTier}
              onChange={this.updateTier}>
        <option>Choose your option</option>
        <option value="STD">Standard</option>
        {/* <option value="GLD">Gold</option> */}
        {/* <option value="PLT">Platinum</option> */}
      </select>
    </div>
  );

  status = () => {
    const profile = _.get(this.state, 'profileData');
    return _.get(profile, `[${this.props.benefitKey} + 'Status']`);
  };

  showModal = () => {
    this.setState({ 'modalShowing': true });
  };

  closeModal = () => {
    this.setState({ 'modalShowing': false });
  };

  finalizeRewardClaim = () => {
    if (this.state.isProcessing) {
      return;
    }
    this.setState({ 'isProcessing': true });
    this.closeModal();
    this.setState({ 'isLoading': true });
    // Promise.all([
    this.recordBenefitElection().then(() => {
      this.createJournals().then(() => {
        // Redirect to home
        this.redirectToHome();
      }).catch((reason: any) => {
        this.errorMessage = 'An error occurred while saving Benefit Elections';
        this.errorBody = reason?.toString();
        this.setState({ 'errorModalShowing': true });
        console.error(reason);
        // this.forceUpdate();
      }).finally(() => {
        this.setState({ 'isProcessing': false });
        this.setState({ 'isLoading': false });
      });
    }).catch((reason: any) => {
      this.errorMessage = 'An error occurred while writing journals to Rent Manager';
      this.errorBody = reason?.toString();
      this.setState({ 'errorModalShowing': true });
      // this.forceUpdate();
      console.error(reason);
    }).finally(() => {
      this.setState({ 'isProcessing': false });
      this.setState({ 'isLoading': false });
    });
  };

  recordBenefitElection = () => {
    const benefitElection = {
      'benefitTier': this.state.selectedTier,
      'benefitOffering': BenefitKeyMap.get(this.props.benefitKey),
      'benefitStatus': 'Pending',
      'dateElected': new Date(),
      'employeeId': this.state.profileData.tenantId
    };

    const benArray = [benefitElection];
    return API.post('BenefitElections', '', { 'body': benArray });
  };

  createJournals = () => {
    const journalEntry = {
      'remoteProfessionalEmail': this.state.email,
      'benefitTier': 'STD',
      'transactionType': 'claimBenefit',
      'amount': this.getPrice(this.props.benefitKey),
      'rank': 'STD'
    };
    return API.post('CreateJournals', '', { 'body': journalEntry });
  };

  getStatusFieldName = () => {
    switch (this.props.benefitKey.toUpperCase()) {
      case 'LIFE':
        return 'Vida Status';
      case 'GMN':
        return 'GMN Status';
      case 'GMM':
        return 'GMM Status';
      default:
        return '';
    }
  };

  private redirectToHome() {
    this.setState({ 'userHasBenefit': true });
    toast('Has adquirido el beneficio. Alguien de recursos humanos se pondrá en contacto con usted para obtener más información.');
  }

  updateContactRecord() {
    const personalContact = this.getPersonalContact();
    const contactId = _.get(personalContact, 'contactId', null);
    const contact = {
      'contactId': contactId,
      'userDefinedValues': [{
        'Alias': `${this.props.benefitKey}Status`,
        'Value': 'Pending'
      }]
    };
    return API.post('Contact', '', { 'body': contact });
  }

  getPrice = (benefitKey: string): number => {
    if (benefitKey === 'majorMedical') {
      // Major Medical is more complicated. Go get 'em!
      return (this.getGMMPrice());
    }
    const price = _.filter(this.state.benefitPrices, (b) => {
      const benefitTier = _.get(b, 'benefitTierCode');
      return _.get(b, 'insuredTypeCode') === 'E'
        && benefitTier === this.state.selectedTier
        && _.get(b, 'benefitOfferingCode') === BenefitKeyMap.get(this.props.benefitKey);
    });
    return (Array.isArray(price) && price.length > 0 ? price[0].price * 26 : 0);
  };

  getPersonalContact() {
    // const contacts = _.get(this.state, 'profileData.personalContacts');
    // return _.find(contacts, { 'name': _.get(this.state.profileData, 'name') });
    // const primCont = _.get(this.state.profileData, 'personalContacts')?.filter(
    //   (c: any) => c.IsPrimary && c.contactTypeID === 2
    // );
    // return primCont;
    return _.get(this.state.profileData, 'personalContacts[0]');
  }

  parseBoolean(value: string): boolean {
    if (_.isNil(value)) {
      return false;
    }
    const val = value.toString().toLowerCase();
    if (val.length > 0) {
      switch (val[0]) {
        case '1':
        case 't':
          return true;
        default:
          break;
      }
    }
    return false;
  }

  getPricingContent = () => {
    const x = this?.state?.userHasBenefit
      ? this.parseBoolean(JSON.stringify(this?.state?.userHasBenefit)) : undefined;
    const price = this.getPrice(this.props.benefitKey);
    if (!x && price !== -1) {
      return (
        <div>
          <h2 className='text-center button-padding'>Costo: <img className='anequim-bucks-icon-costs'
            src={AnequimBucksIconBlue}
            alt={'Anequim Bucks'}/>{price.toFixed(2)}</h2>
          {(this.hidePurchaseButton() && !this.state.isLoading ? null
            : <div>{this.purchaseButton()}</div>
          )
          }
        </div>
      );
    }
    return null;
  };

  /* Major Medical prices are stored in USD, so no exchange rate is needed. */
  getGMMPrice = () => {
    const price = _.filter(this.state.benefitPrices, (b) => {
      const benefitTier = _.get(b, 'benefitTierCode');
      const personalContact = this.getPersonalContact();

      if (_.isNil(personalContact)) {
        return 0;
      }

      const gender = _.get(personalContact, 'gender');
      const age = _.get(personalContact, 'age');
      if (!age || !gender) {
        return 0;
      }
      // employees only for now
      return _.get(b, 'insuredTypeCode') === 'E' && age >= _.get(b, 'ageFloor')
        && age <= _.get(b, 'ageCeiling') && gender === _.get(b, 'genderCode')
        && benefitTier === this.state.selectedTier
        && _.get(b, 'benefitOfferingCode') === BenefitKeyMap.get(this.props.benefitKey);
    });

    const p: number = _.get(price, '[0].price') || 0;
    if (!p || p === 0) {
      return 0;
    }
    return (p * 26) || 0;
  };

  render = () => {
    if (this.state.isLoading) {
      return (
        <CenteredSpinner/>
      );
    }

    const benefit = Benefits[this.props.benefitKey];
    const icon = this.status() === 'active' ? benefit.activeIcon : benefit.inactiveIcon;

    return (
      <div className='col-xs-12'>
        <div className='col-md-8 col-xs-12 offset-md-2 benefit-page'>
          <img src={icon} alt={benefit.title} height='40px;' className='benefit-title'/>
          <h1 className='benefit-title'>{benefit.title}</h1>
          <div className='description'>
          </div>
          <PdfViewer pdf={`/documents/${this.props.benefitKey}.pdf`}></PdfViewer>
        </div>
        <div>{this.getPricingContent()}</div>
        <Modal id="confirm-modal" show={this.state.modalShowing}
               onHide={this.closeModal}
               backdrop="static"
               keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>Adquirir {benefit.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            ¿ Estas seguro(a) de querer adquirir el {benefit.title} por
            $ {this.getPrice(this.props.benefitKey).toFixed(2)} USD?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal}>
              No
            </Button>
            <Button variant="primary" onClick={this.finalizeRewardClaim}>Yes</Button>
          </Modal.Footer>
        </Modal>
        {!this.state.errorModalShowing ? null
          : <ErrorModal modalShowing={this.state.errorModalShowing}
                        errorMsg={this.errorMessage} errorBody={this.errorBody}/>
        }
      </div>
    );
  };

  private loadBenefitPrices = async () => {
    this.setState({ 'benefitPrices': await API.get('BenefitPrices', '', {}) });
  };

  private loadExchangeRates = async () => {
    const rateList = await API.get('ExchangeRates', '', {});

    this.setState({ 'rates': rateList });
  };

  private hidePurchaseButton = () => {
    const benefitPrice = this.getPrice(this.props.benefitKey);
    return (((_.isNil(this.state.balance) ? 0 : this.state.balance) - benefitPrice) < 0)
      || benefitPrice === 0 || this.isInBenefitElections(this.props.benefitKey);
  };
}
