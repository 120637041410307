// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { ISwimLaneDefinition } from '../components/ISwimLaneDefinition';
// import { BaseWorkflowDashboard } from './BaseWorkflowDashboard';
import { ICardDefinition } from '../components/ICardDefinition';

const dummySwimLanes = [{
  'header': 'New',
  'width': '20%',
  'swimlaneId': 1
},
{
  'header': 'Awaiting Review',
  'width': '20%',
  'swimlaneId': 2
},
{
  'header': 'Approved',
  'width': '20%',
  'swimlaneId': 3
},
{
  'header': 'Paid',
  'width': '20%',
  'swimlaneId': 4
}];

const dummyCards = [{
  'title': 'Airfare to Phoenix',
  'text': 'Tenant says main toilet will not stop running',
  'priorityId': 2,
  'dueDate': new Date('2021-03-05'),
  'key': 1,
  'swimLaneId': 1,
  'id': '1'
},
{
  'title': 'Hotel for conference',
  'text': '20%',
  'priorityId': 1,
  'dueDate': new Date('2021-03-03'),
  'key': 2,
  'swimLaneId': 2,
  'id': '2'
},
{
  'title': 'Business lunch',
  'text': '20%',
  'priorityId': 3,
  'dueDate': new Date('2021-03-05'),
  'key': 3,
  'swimLaneId': 3,
  'id': '3'
},
{
  'title': 'Business lunch',
  'text': '20%',
  'priorityId': 2,
  'dueDate': new Date('2021-02-27'),
  'key': 4,
  'swimLaneId': 4,
  'id': '4'
},
{
  'title': 'Business lunch',
  'text': '20%',
  'priorityId': 3,
  'dueDate': new Date('2021-03-05'),
  'key': 5,
  'swimLaneId': 5,
  'id': '5'
}, {
  'title': 'Meals for conference',
  'text': 'Tenant says main toilet will not stop running',
  'priorityId': 2,
  'dueDate': new Date('2021-03-04'),
  'key': 6,
  'swimLaneId': 1,
  'id': '6'
},
{
  'title': 'Dry Cleaning',
  'text': '20%',
  'priorityId': 3,
  'dueDate': new Date('2021-03-15'),
  'key': 7,
  'swimLaneId': 1,
  'id': '7'
},
{
  'title': 'Business lunch',
  'text': '20%',
  'priorityId': 3,
  'dueDate': new Date('2021-03-16'),
  'key': 8,
  'swimLaneId': 1,
  'id': '8'
},
{
  'title': 'Dry Cleaning',
  'text': '20%',
  'priorityId': 3,
  'dueDate': new Date('2021-03-07'),
  'key': 9,
  'swimLaneId': 4,
  'id': '9'
}];

export class Payables extends Component<{}> {
  state: {
        swimlanes: Array<ISwimLaneDefinition>,
        dashboardId: number,
        activeIcon: string,
        cards: Array<ICardDefinition>
    } = {
      'swimlanes': dummySwimLanes,
      'cards': dummyCards,
      'dashboardId': 1,
      'activeIcon': '../../assets/images/landing-page/exclamation.svg'
    };

  // eslint-disable-next-line class-methods-use-this
  render() {
    return (<div className="dashboard">
                <h2>Accounts Payable</h2>
                {/* <BaseWorkflowDashboard dashboardId={this.state.dashboardId} */}
                {/*                       swimLanes={this.state.swimlanes} */}
                {/*                       cardCollection={this.state.cards} */}
                {/*                       activeIcon={this.state.activeIcon}/> */}
            </div>
    );
  }
}
