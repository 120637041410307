// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import { Button } from 'react-bootstrap';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export class PdfViewer extends Component {
  state = {
    'numPages': 1,
    'pageNum': 1,
    'prevButtonDisabled': true,
    'nextButtonDisabled': true,
    'showDefaultContent': false,
    'documentLoaded': true,
    'hideButtonBar': true
  };

  constructor(public props: any) {
    super(props);
  }

  onDocumentLoadSuccess = (pdf: any) => {
    const pgs = pdf.numPages;
    if (pgs !== 1) {
      this.setState({ 'prevButtonDisabled': (this.state.pageNum === 1) });
      this.setState({ 'nextButtonDisabled': false });
      this.setState({ 'hideButtonBar': false });
    }

    this.setState({ 'numPages': pgs });

    this.setState({ 'documentLoaded': true });
  };

  onDocumentLoadError = () => {
    this.setState({ 'prevButtonDisabled': true });
    this.setState({ 'nextButtonDisabled': true });
    this.setState({ 'showDefaultContent': true });
    this.setState({ 'documentLoaded': false });
    this.setState({ 'numPages': 0 });

    this.checkPagingButtons();
  };

  changePage = (offset: number) => {
    this.setState({ 'pageNum': this.state.pageNum + offset });

    this.checkPagingButtons();
  };

  checkPagingButtons = () => {
    if (this.state.numPages === 1 || this.state.numPages === 0) {
      this.setState({ 'hideButtonBar': true });
      return;
    }

    this.setState({ 'prevButtonDisabled': this.state.pageNum - 1 === 1 });

    this.setState({ 'nextButtonDisabled': this.state.pageNum + 1 === this.state.numPages });
  };

  previousPage = () => {
    // if(this.state.pageNum - 1 >= 1) {
    this.changePage(-1);
    //  }
    // else {
    //     //we are on the first page, disable the arrow
    //     this.setState({prevButtonDisabled: true});
    // }
  };

  nextPage = () => {
    // if(this.state.pageNum + 1 <= this.state.numPages) {
    this.changePage(1);
    // }
    // else {
    //     //we are on the last page, disable the arrow
    //     this.setState({nextButtonDisabled: true});
    // }
  };

  render = () => (
    <div>
      <div className="row justify-center pdf-max-height">
        {this.state.documentLoaded
          && <Document file={this.props.pdf} onLoadSuccess={this.onDocumentLoadSuccess}
                       onLoadError={this.onDocumentLoadError}>
                <Page pageNumber={this.state.pageNum}/></Document>
        }
        {!this.state.documentLoaded
          && <h3>Coming Soon...</h3>
        }
      </div>
      {!this.state.hideButtonBar
        && <div className="row justify-center paging-div">
              <Button className="anequim-pager" onClick={this.previousPage} disabled={this.state.prevButtonDisabled}>
                  <img src='../assets/images/angle-left.svg' className="30px"/>
              </Button>
              <Button className="anequim-pager" onClick={this.nextPage} disabled={this.state.nextButtonDisabled}>
                  <img src='../assets/images/angle-left.svg' className="30px"/>
              </Button>
          </div>
      }
    </div>
  );
}
