// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import '../assets/styles/admin.scss';

export class Admin extends Component<{}> {
  render() {
    return (<div className="admin">
        <form className="form-signin" method="post" action="/login"
              data-bitwarden-watching="1">
          <div className="login-header">Please sign in</div>
          <p>
            <label htmlFor="username" className="sr-only">Username</label>
            <input type="text" id="username" name="username" className="form-control"
                   placeholder="Username" />
          </p>
          <p>
            <label htmlFor="password" className="sr-only">Password</label>
            <input type="password" id="password" name="password" className="form-control"
                   placeholder="Password" />
          </p>
          <input name="_csrf" type="hidden" value="64392fad-4630-4f4f-a3fe-c78976dd7ef4"/>
            <button className="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
        </form>
    </div>);
  }
}
