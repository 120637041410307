// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { API } from '@aws-amplify/api';
import _ from 'lodash';
import { CenteredSpinner } from '../components/centered_spinner';
import { BenefitPanel } from '../components/benefit_panel';
import '../assets/styles/home.scss';
import AnequimBucksIconBlue from '../assets/images/benefits/a-rewards-blue.svg';

export class BenefitsHome extends Component<{}> {
  state: {
    isLoadingBalance?: boolean;
    isLoadingProfile?: boolean;
    balance?: number;
    benefitPrices?: Array<any>;
    benefitElections?: Array<any>;
    selectedTier?: string;
    profileData?: any;
    modalShowing?: boolean;
    loading: boolean;
    isBalanceError?: boolean;
  } = { 'loading': true };

  props: { user: any; } = { 'user': null };

  hasThermometer: boolean = false;

  constructor(props: any) {
    super(props);
    this.props = props;
  }

  componentDidMount() {
    this.setState({ 'isLoadingBalance': true });
    this.setState({ 'isLoadingProfile': true });
    this.setState({ 'selectedTier': 'STD' });

    Promise.all([this.loadBalance(),
      this.loadProfileData(),
      this.loadBenefitPrices()]).then(() => {
      this.setState({ 'loading': false });
    });
  }

  async loadBalance() {
    const loggedUserEmail = _.get(this.props.user, 'attributes.email');
    try {
      await API.get('AwardsBalance', `Anequim/balance/${loggedUserEmail}?db=MX`, {}).then((response) => {
        this.setState({ 'balance': response, 'isBalanceError': false });
      }).finally(() => {
        this.setState({ 'isLoadingBalance': false });
      });
    } catch (error) {
      this.setState({ 'isLoadingBalance': false, 'isBalanceError': true, 'balance': 0 });
    }
  }

  async loadProfileData() {
    const loggedUserEmail = _.get(this.props.user, 'attributes.email');
    await API.get('Employee', `Anequim/${loggedUserEmail}?db=MX`, {}).then((response) => {
      this.setState({ 'profileData': response });

      this.loadBenefitElections();
    })
      .finally(() => {
        this.setState({ 'isLoadingProfile': false });
      });
  }

  async loadBenefitPrices() {
    const prices = await API.get('BenefitPrices', '', {});
    this.setState({ 'benefitPrices': prices });
  }

  async loadBenefitElections() {
    const elections = await API.get('BenefitElectionsModel', `?tenantId=${_.get(this, 'state.profileData.tenantId')}`, {});
    this.setState({ 'benefitElections': elections });
  }

  balance(): number {
    return this.state.balance || 0;
  }

  balanceDisplay = () => {
    // this.thermometer();

    if (!this.state.isLoadingBalance) {
      return this.formattedBalance();
    }

    return (<CenteredSpinner/>);
  };

  formattedBalance = () => {
    const { isBalanceError } = this.state;
    if (isBalanceError) {
      return (
        <div className='headline-balance'>
          <h3>Hola {_.split(this.props.user.attributes?.name, ' ', 1)}!</h3>
          {/* eslint-disable-next-line max-len */}
          <div>Hubo un error al cargar tu información, pero no te preocupes, tus beneficios están a salvo. Por favor
            contacta a tu advocate para recibir más información.
          </div>
        </div>
      );
    }
    const bal: number = (this.balance() ? this.balance()!.toFixed(2) : 0) as number;
    const cashOutVal = (bal ? (bal * 0.8).toFixed(2) : 0);
    return (
      <div className='headline-balance'>
        <h3>Hola {_.split(this.props.user.attributes?.name, ' ', 1)}!</h3>
        <div>Tienes acumulados:</div>
        <h2><img className='anequim-bucks-icon' src={AnequimBucksIconBlue} alt={'Anequim Bucks'}/> {bal} </h2>
        <div>Valor en efectivo:</div>
        <h2>$ {cashOutVal} USD</h2>
        <a id="calc-link" href="#/balance">¿Cómo obtuve este saldo? </a>
      </div>
    );
  };

  showModal = () => {
    this.setState({ 'modalShowing': true });
  };

  closeModal = () => {
    this.setState({ 'modalShowing': false });
  };

  benefitsDisplay = () => {
    if (!this.state.isLoadingProfile) {
      if (this.state.profileData) {
        return this.benefitsGrid();
      }
      return (

        <h2>No se han encontrado datos que concuerden</h2>

      );
    }

    return (
      <CenteredSpinner/>
    );
  };

  benefitsGrid = () => {
    const tnt = _.get(this, 'state.profileData.tenantId') ?? 0;
    return (
      <div className='row'>
        <BenefitPanel benefitKey='life' userHasBenefit={this.isInBenefitElections('Life')}
                      status={this.state.profileData.lifeStatus} tenant={tnt}/>
        <BenefitPanel benefitKey='minorMedical'
                      userHasBenefit={this.isInBenefitElections('Minor Medical')}
                      status={this.state.profileData.minorMedicalStatus} tenant={tnt}/>
        <BenefitPanel benefitKey='majorMedical'
                      userHasBenefit={this.isInBenefitElections('Major Medical')}
                      status={this.state.profileData.majorMedicalStatus} tenant={tnt}/>
      </div>
    );
  };

  render() {
    return (
      <div className='col-xs-12 page'>
        <div className='col-md-8 col-xs-12 offset-md-2 text-center' id={'top-section'}>
          {/* eslint-disable-next-line no-undef */}
          <this.balanceDisplay/>
        </div>
        {/* eslint-disable-next-line no-undef */}
        {/* <h2>Thermometer</h2> */}
        {/* <div id="thermo"></div> */}
        <div className='col-xs-12 text-center' id={'bottom-section'}>
          <div className={'benefits-display'}>
            <h3>Beneficios A-Rewards</h3>
            {/* eslint-disable-next-line no-undef */}
            <this.benefitsDisplay/>
          </div>
        </div>
      </div>
    );
  }

  private isInBenefitElections(benefitName: string): boolean {
    const itm = _.find(this.state.benefitElections, { 'benefitOffering': benefitName });
    return (itm !== undefined);
  }
}
