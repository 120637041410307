// eslint-disable-next-line no-use-before-define
import React from 'react';
import '../assets/styles/spinner.scss';

export function Spinner() {
  return (
    <svg className='spinner' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='50' cy='50' r='45'/>
    </svg>
  );
}
