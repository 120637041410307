// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import * as _ from 'lodash';
import { IClientProfileModel } from './interfaces/IClientProfileModel';
import { Environment } from '../environment';

interface IState {
  employeeProfile: IClientProfileModel;
  error: boolean;
  isLoadingDocuments?: boolean;
}

interface IProps {
  user: any;
}

export class ClientProfile extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      'employeeProfile': {} as IClientProfileModel,
      'error': false,
      'isLoadingDocuments': true
    };
  }

  private loadClientProfileData = async () => {
    try {
      const userName = _.get(this.props.user, 'username');
      const userAccountId = _.get(this.props.user.attributes, 'custom:ext_ref_id');

      const payload = {
        'name': userName,
        'usAccountId': userAccountId
      };
      const response = await fetch(`${Environment.get('INTERNAL_REST_SERVICE_API_ROOT')}anequim/clients/by-reference`, {
        'method': 'POST',
        'headers': {
          'Content-Type': 'application/json'
        },
        'body': JSON.stringify(payload)
      });

      const data = await response.json();
      this.setState({
        'employeeProfile': {
          ...data,
          'companyName': data.name
        }
      });
      return data;
    } catch (e) {
      console.log(e);
      this.setState({ 'error': true });
      return {} as IClientProfileModel;
    }
  }

  async componentDidMount() {
    await Promise.all([
      await this.loadClientProfileData()
    ]).then(() => {
      this.setState({
        'error': false,
        'isLoadingDocuments': false
      });
    }).catch((e) => {
      console.log(e);
      this.setState({
        'error': true,
        'isLoadingDocuments': false
      });
    });
  }

  render() {
    const {
      employeeProfile, error, isLoadingDocuments
    } = this.state;

    const userName = _.get(this.props.user, 'username');
    const userEmail = _.get(this.props.user.attributes, 'email');
    const userType = _.get(this.props.user, 'attributes.custom:user_type');

    if (!isLoadingDocuments) {
      if (error) {
        return (
          <div>An error occurred. Please try again later.</div>
        );
      }
      return (
        <div className="landing-body">
        <div className='profile-content'>
          <h3 className="landing-header">{employeeProfile.name}</h3><br/>
          <div><span className='profile-h'>User Name: </span>{userName}</div><br/>
          <div><span className='profile-h'>Primary contact info: </span><a
            href={`mailto:${employeeProfile.email}`}>{userEmail}</a></div><br/>
          <div><span className='profile-h'>User type: </span>{userType}</div><br/>
        </div>
        </div>
      );
    }
    return (<div/>);
  }
}
