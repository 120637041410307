import DocumentsIcon from './assets/images/landing-page/documentos.svg';
import ServicesIcon from './assets/images/landing-page/centro-de-atencion.svg';
import HandbookIcon from './assets/images/landing-page/manual.svg';
import FaqsIcon from './assets/images/landing-page/faqs.svg';
import CouponsIcon from './assets/images/landing-page/beneficios-anequim.svg';
import RemotesIcon from './assets/images/landing-page/remote-professionals.svg';
import CalendarIcon from './assets/images/landing-page/calendar.svg';
import { ITileDefinition } from './components/ITileDefinition';

export const LandingPageTiles: { [key: string]: ITileDefinition } = {
  'payroll-admin': {
    'title': 'Documentos',
    'activeIcon': DocumentsIcon,
    'destination': '/#/my-documents',
    'key': 1,
    'permissions': ['Remote Professional'],
    'description': 'Consulta tus recibos de nómina.'
  },
  'issues': {
    'title': 'Centro de atención',
    'activeIcon': ServicesIcon,
    'destination': '/#/service-issues',
    'key': 2,
    'permissions': ['Remote Professional'],
    'description': '¿Necesitas ayuda o tienes preguntas? ¡Envíanos un ticket!'
  },
  'handbook': {
    'title': 'Manual del empleado',
    'activeIcon': HandbookIcon,
    'destination': 'https://docs.google.com/document/d/1BHDk95olR090spQmUVw0qtW9t41_mqqCDhZtP4-7YjU/',
    'target': '_blank',
    'rel': 'noreferrer noopener',
    'key': 3,
    'permissions': ['Remote Professional'],
    'description': 'Infórmate sobre nuestra empresa, políticas y prácticas laborales.'
  },
  'faq': {
    'title': 'FAQs',
    'activeIcon': FaqsIcon,
    'destination': 'https://www.sweetprocess.com/kb/5z8Dfvm0aCvx/',
    'target': '_blank',
    'rel': 'noreferrer noopener',
    'key': 4,
    'permissions': ['Remote Professional'],
    'description': 'Obtén respuestas a preguntas frecuentes sobre Anequim.'
  },
  'coupons': {
    'title': 'Beneficios Anequim',
    'activeIcon': CouponsIcon,
    'destination': 'https://drive.google.com/file/d/1CcwVwx9Hj8hrhTFr7Pu97dBQPxqDmaWH/view?usp=sharing',
    'target': '_blank',
    'rel': 'noreferrer noopener',
    'key': 5,
    'permissions': ['Remote Professional'],
    'description': 'Ahorra con descuentos exclusivos en diversos productos y servicios.'
  },
  'calendar': {
    'title': 'Calendario',
    'activeIcon': CalendarIcon,
    'destination': 'https://calendar.google.com/calendar/embed?src=c_c4fe04a9821dfa9c1f04aba662ab7b08e29928f39d5fa973ebd8eaeb00e780f2%40group.calendar.google.com',
    'target': '_blank',
    'rel': 'noreferrer noopener',
    'key': 5,
    'permissions': ['Remote Professional'],
    'description': 'Eventos y fechas de tu nómina, organizados y al alcance.'
  },
  'remote-professionals': {
    'title': 'Remote Professionals',
    'activeIcon': RemotesIcon,
    'destination': '/#/remote-professionals',
    'key': 50,
    'permissions': ['Client'],
    'description': 'View and manage your remote professionals\' information.'
  },
  'client-services': {
    'title': 'Client Services Center',
    'activeIcon': ServicesIcon,
    'destination': '/#/client-services',
    'key': 51,
    'permissions': ['Client'],
    'description': 'Submit tickets for assistance with any inquiries.'
  }
};

/* Old tiles */
// import Hubstaff from './assets/images/landing-page/hubstaff.svg';
// import Headphones from './assets/images/landing-page/headphones.svg';
// import Reporting from './assets/images/landing-page/reporting.svg';
// import HumanResourcesIcon from './assets/images/landing-page/human-resources.svg';
// 'workday': {
//   'title': 'Workday',
//   'activeIcon': List,
//   'destination': '/#my-workday',
//   'target': '',
//   'rel': '',
//   'name': '',
//   'key': 1
// },
// 'rmcc': {
//   'title': 'RMCC',
//   'activeIcon': Headphones,
//   'destination': '/#/rmcc',
//   'target': '',
//   'rel': '',
//   'name': '',
//   'key': 3,
//   'permissions': ['RMCC']
// },
// 'hubstaff': {
//   'title': 'Hubstaff',
//   'activeIcon': Hubstaff,
//   'destination': 'https://app.hubstaff.com',
//   'target': '_blank',
//   'rel': 'noreferrer noopener',
//   'name': '',
//   'key': 4,
//   'permissions': ['Remote Professional']
// },
// 'configure': {
//   'title': 'Configure',
//   'activeIcon': Tools,
//   'destination': '/#configuration',
//   'target': '',
//   'rel': '',
//   'name': '',
//   'key': 5,
//     'permissions': ['RMCC']
// },
// 'payables': {
//   'title': 'Payables',
//   'activeIcon': Invoice,
//   'destination': '/#payables',
//   'target': '',
//   'rel': '',
//   'name': '',
//   'key': 6,
//     'permissions': ['RMCC']
// },
// 'collections': {
//   'title': 'Collections',
//   'activeIcon': Collections,
//   'destination': '/#collections',
//   'target': '',
//   'rel': '',
//   'name': '',
//   'key': 7,
//     'permissions': ['RMCC']
// },
// 'administration': {
//   'title': 'Administrator',
//   'activeIcon': Admin,
//   'destination': '/#admin',
//   'target': '',
//   'rel': '',
//   'name': '',
//   'key': 8,
//     'permissions': ['RMCC']
// },
// 'reporting': {
//   'title': 'Reportes',
//   'activeIcon': Reporting,
//   'destination': '/#/reporting',
//   'target': '',
//   'rel': '',
//   'name': '',
//   'key': 9,
//   'permissions': ['Reporting']
// },
// 'benefits': {
//   'title': 'Beneficios',
//   'activeIcon': BenefitsIcon,
//   'destination': '/#/benefits',
//   'target': '',
//   'rel': '',
//   'name': '',
//   'key': 10,
//   'permissions': ['Remote Professional']
// },
// 'human-resources': {
//   'title': 'Recursos Humanos',
//   'activeIcon': HumanResourcesIcon,
//   'destination': '/#/hr-page',
//   'target': '',
//   'rel': '',
//   'name': '',
//   'key': 10,
//   'permissions': ['Reporting']
// },
// 'my-payroll': {
//   'title': 'Mis pagos',
//   'activeIcon': MoneyBag,
//   'destination': '/#/my-payroll',
//   'target': '',
//   'rel': '',
//   'name': '',
//   'key': 11,
//   'permissions': ['Remote Professional']
// }
