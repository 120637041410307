// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import Auth from '@aws-amplify/auth';
import HorizontalLogo from '../assets/images/horizontal-logo.svg';
import LogoutIcon from '../assets/images/logout.svg';
import '../assets/styles/nav.scss';

export class Nav extends Component {
  constructor(public props: any) {
    super(props);
  }

  render = () => (
    <div>
      <nav className='navbar navbar-expand-lg navbar-light'>
        <div className="navbar-content">
          <a className='navbar-brand' href='/'>
            <img src={HorizontalLogo} alt='Anequim' height='57px;'/>
          </a>
          <button className='btn signout-btn' onClick={() => { Auth.signOut(); sessionStorage.removeItem('user'); }}>Log out <img src={LogoutIcon}
                                                                                          alt='Logout'/>
          </button>
        </div>
      </nav>
      <div className="navbar-padding">
      </div>
    </div>
  );
}
