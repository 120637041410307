// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import * as _ from 'lodash';
import { IDynamoServiceIssueModel } from './interfaces/IDynamoServiceIssueModel';

export class ServiceIssueCard extends Component {
  props: {
    serviceIssueData: IDynamoServiceIssueModel
  };

  constructor(props: any) {
    super(props);
    this.props = props;
  }

  render() {
    const { serviceIssueData } = this.props;
    const siID = _.get(serviceIssueData, 'serviceManagerIssueID');
    const siCreateDate = new Date(_.get(serviceIssueData, 'createDate'));
    const siIsClosed = _.get(serviceIssueData, 'isClosed');
    // eslint-disable-next-line max-len
    const { siCategory, siDescription } = this.parseServiceIssueDescription(_.get(serviceIssueData, 'description'));
    // @ts-ignore
    return (
      <Card className="text-left service-issue-card">
        <Card.Body>
          {siID !== null && <Card.Title className="issue-card-title">ID de ticket: {siID}</Card.Title>}
          <Card.Subtitle className="mb-2 text-muted">Creación: {siCreateDate.toLocaleDateString()}</Card.Subtitle>
          <Card.Text>
            {siCategory !== null && <><b>Categoría: </b>{siCategory}<br/></>}
            {siDescription !== null && <><b>Mensaje: </b>{siDescription}<br/></>}
            {siIsClosed !== null && <><b>Estado: </b>{ siIsClosed === 1 ? 'Cerrado' : 'En progreso' }<br/></>}
          </Card.Text>
        </Card.Body>
      </Card>
    );
  }

  private parseServiceIssueDescription(description: string) {
    const regex = /(Category: )(.*)(\n *)(Message: )(.*)(\n *)(logged_user_email: )(.*)/;
    const matches = description.match(regex);
    if (matches == null) {
      return { 'siCategory': null, 'siDescription': description };
    }
    const cat = matches[2];
    const message = matches[5];
    return { 'siCategory': cat, 'siDescription': message };
  }
}
