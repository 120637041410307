/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IEmployeeProfileModel } from '../../components/interfaces/IEmployeeProfileModel';
import { Environment } from '../../environment';

const initialState = {
  'data': {} as IEmployeeProfileModel,
  'status': 'idle',
  'error': null
};

// this thunk function will handle data loading with an external API
export const fetchData = createAsyncThunk(
  'employeeProfile/fetchData', async (userEmail: string) => {
    try {
      const response = await fetch(`${Environment.get('INTERNAL_REST_SERVICE_API_ROOT')}anequim/employees/employee-profile/${userEmail}`);
      return await response.json();
    } catch (e) {
      console.log('retry');
      try {
        const response = await fetch(`${Environment.get('INTERNAL_REST_SERVICE_API_ROOT')}anequim/employees/employee-profile/${userEmail}`);
        return await response.json();
      } catch (e2) {
        console.log(e2);
        return {} as IEmployeeProfileModel;
      }
    }
  }
);

export const employeeProfileSlice = createSlice({
  'name': 'employeeProfile',
  'initialState': initialState,
  'reducers': {},
  // extra reducers handle changes on state when running the actions created by createAsyncThunk()
  extraReducers(builder) {
    builder
      // eslint-disable-next-line no-unused-vars
      .addCase(fetchData.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.status = 'failed';
        // @ts-ignore
        state.error = action.error.message;
      });
  }
});

export const selectAllData = (state: any) => state.reducers.employeeProfile.data;
export const selectProfileDataStatus = (state: any) => state.reducers.employeeProfile.status;
export const selectProfileDataError = (state: any) => state.reducers.employeeProfile.error;

export default employeeProfileSlice.reducer;
