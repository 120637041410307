// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { API } from '@aws-amplify/api';
import * as _ from 'lodash';
import { CenteredSpinner } from '../components/centered_spinner';

type ProfileState = {}

const profileFields = [
  { 'key': 'name', 'label': 'nombre', 'type': 'string' },
  { 'key': 'employeeId', 'label': 'No. de empleado', 'type': 'string' },
  // {key: 'employer', label: 'empresa', type: 'string'},

  { 'key': 'ssn', 'label': 'Social Security #', 'type': 'string' },
  { 'key': 'clabe', 'label': 'CLABE', 'type': 'string' },
  // {key: 'startDate', label: 'fecha de inicio', type: 'date'},
  { 'key': 'phone', 'label': 'telefono', 'type': 'string' },
  { 'key': 'primaryAddress.street', 'label': 'dirección', 'type': 'string' },
  { 'key': 'primaryAddress.city', 'label': 'ciudad', 'type': 'string' },
  { 'key': 'primaryAddress.state', 'label': 'estado', 'type': 'string' },
  { 'key': 'primaryAddress.postalCode', 'label': 'código postal', 'type': 'string' },

  { 'key': 'CURP', 'label': 'CURP', 'type': 'string' },
  { 'key': 'RFC', 'label': 'RFC', 'type': 'string' }
];

const editableFields = [
  'primaryAddress.street',
  'primaryAddress.city',
  'primaryAddress.state',
  'primaryAddress.postalCode',
  'clabe',
  'phone',
  'ssn'
];

// function get(path: string, object: any) {
//   return path.split('.').reduce((p, c) => (p && p[c]) || null, object);
// }

export class Profile extends Component<{}, ProfileState> {
  state: { profileData: any, profileUpdated?: any} = { 'profileData': null };

  props: {
        user: any
    };

  saveMessage: string = '';

  constructor(props: any) {
    super(props);
    this.props = props;
    this.profileInformation = this.profileInformation.bind(this);
    this.body = this.body.bind(this);
  }

  componentDidMount() {
    this.loadProfileData();
  }

  async loadProfileData() {
    const loggedUserEmail = _.get(this.props.user, 'attributes.email');
    const eeData = await API.get('Employee', `Anequim/${loggedUserEmail}?db=MX`, {});
    this.setState({ 'profileData': eeData });
  }

  body = (props: { profile?: any }) => {
    if (props.profile) {
      return this.profileInformation(props);
    }
    return (
                <CenteredSpinner/>
    );
  };

  profileInformation = (props: { profile?: any }) => {
    const fieldsHtml = profileFields.map((f) => this.profileField(f, props.profile));

    return (
            <div>
                <form >
                    {fieldsHtml}
                </form>
            </div>
    );
  };

  profileField(props: any, profileData: any) {
    let val = _.get(props.key, profileData);
    if (props.type === 'date' && val && val !== '') {
      val = new Date(val);
      val = `${(val.getMonth() + 1)}/${val.getDate()}/${val.getFullYear()}`;
    }

    const disabled = _.indexOf(editableFields, props.key) < 0;
    return (
            <div key={props.key} className="form-group">
                <label htmlFor={props.key}>{props.label}</label>
                <input type="text" disabled={disabled} className="form-control" id={props.key} name={props.key} value={val} onChange={this.updateField}/>
            </div>
    );
  }

  render() {
    return (
            <div className="row outer-row">
                <div className="col col-md-8 offset-md-2 col-xs-12">
                    <h1>Mi Perfil</h1>
                    {/* eslint-disable-next-line no-undef */}
                    <this.body profile={this.state.profileData}/>
                    <div className="col col-xs-4 text-left">
                        <button className='btn btn-primary' type="submit" onClick={this.updateProfile}>Guardar</button>
                        &nbsp;
                        {this.saveMessage}
                    </div>
                </div>
            </div>
    );
  }

  private getPersonalContact() {
    const contacts = _.get(this.state, 'profileData.personalContacts');
    return _.filter(contacts, (c) => _.get(c, 'name') === this.state.profileData.name)[0];
  }

  private updateProfile = async (e: any) => {
    e.preventDefault();

    const addr1 = _.get(this.state, 'profileData.primaryAddress.street');
    const city = _.get(this.state, 'profileData.primaryAddress.city');
    const st = _.get(this.state, 'profileData.primaryAddress.state');
    const postalCode = _.get(this.state, 'profileData.primaryAddress.postalCode');
    const tid = _.get(this.state, 'profileData.tenantId');
    const ssn = _.get(this.state, 'profileData.ssn');
    const phone = _.get(this.state, 'profileData.phone');
    const bankCLABE = _.get(this.state, 'profileData.clabe');
    const profileUpdateBody = {
      'tenant': {
        'tenantId': tid
      },
      'address': {
        'AddressTypeID': 1,
        'Address': `${addr1} ${city} ${st} ${postalCode}`,
        'Street': addr1,
        'City': city,
        'State': st,
        'PostalCode': postalCode,
        'IsPrimary': true,
        'ParentID': tid
      },
      'contact': {
        'contactId': this.getPersonalContact()?.contactId,
        'PhoneNumbers': {
          'PhoneNumberTypeID': 2, // Work email for Remote Professionals
          'PhoneNumber': phone,
          'IsPrimary': true
        }
      },
      'tenantUDFs': [{
        'name': 'bankCLABE',
        'value': bankCLABE
      }],
      'contactUDFs': [{
        'name': 'SSN',
        'value': ssn
      }]
    };
    this.saveMessage = 'Guardado...';
    this.state.profileUpdated = await API.post('UpdateProfile', '', { 'body': profileUpdateBody });
    this.saveMessage = 'Guardaste';
    setTimeout(() => {
      this.saveMessage = '';
    }, 1500);
  };

  private updateField = (e:any) => {
    const newProfileData = _.cloneDeep(this.state.profileData);
    _.set(newProfileData, `${e.target.name}`, e.target.value);
    this.setState({ 'profileData': newProfileData });
  };
}
