// import { combineReducers, createStore } from '@reduxjs/toolkit';
// import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import counterReducer from './features/counter/counterSlice';
import userReducer from './features/hubstaff/userReducer';
import employeesReducer from './features/employees/employeesReducer';
import employeeProfileReducer from './features/employeeProfile/employeeProfileSlice';

/**
 * There are several ways of creating a redux store, this is a kind of mix
 * between the old and modern approaches.
 *
 * In the old days we would have to create our reducers as functions and define
 * our actions and action creators 'by hand', now we can use 'createSlice'
 * to do that (see the counterReducer).
 *
 * Anyway, in the past we would end with a lot of reducers (one for every piece
 * of state we need), createStore only accepts one reducer parameter, so we had
 * to combine them with combineeReducers(), that is what we are doing here,
 * alternatively we can use toolkit's method configureStore() as the more modern
 * way of handling several reducers when creating a redux store.
 * */
const reducers = combineReducers({
  'counter': counterReducer,
  'hubUser': userReducer,
  'employees': employeesReducer,
  'employeeProfile': employeeProfileReducer
  // 'documentDetails': documentsReducer
});

/**
 * Then we can create the store, notice we can add middleware (we can see them as
 * a kind of 'plugins for redux' that extend functionality), here we are adding
 * redux dev tools to the store too. configureStore() from redux-toolkit replaces
 * this method in the modern approach.
 * */
// const store = createStore(
//   reducers,
//   composeWithDevToolsDevelopmentOnly() // <- This line has errors
// );
const store = configureStore({
  'reducer': { reducers },
  'devTools': true
});

/**
 * Now, this part applies to both ways old and new of using redux.
 *
 * We have to know the types of the state and the action dispatchers
 * of our store.
 *
 * RootState holds the type of the concatenated reducers of the store
 * */
export type RootState = ReturnType<typeof reducers>;

/** The Redux store has a method called dispatch. The only way to
 * update the state is to call store.dispatch() and pass in an action object.
 * */
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>();

/**
 * Lastly we export the store so react components can use it, we'll also need
 * the state and dispatch types
 * */
export default store;
