// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import '../assets/styles/my-payroll.scss';
import { API } from '@aws-amplify/api';
import * as _ from 'lodash';
import { Utils } from '../utils/utils';

export class EmployeeProfile extends Component<{}> {
  state = {
    'employee': null
  };

  props = {
    'user': null
  };

  constructor(props:any) {
    super(props);
    this.props.user = props.user;
  }

  async componentDidMount() {
    await this.loadEmployeeProfile();
  }

  render = () => {
    const time = (_.get(this.state.employee, 'startDate') || 0);
    let hdString = '';
    if (time !== 0) {
      const hireDate = new Date(time);
      hireDate.setHours(hireDate.getHours() + 6);
      hdString = hireDate.toLocaleDateString();
    }

    const birthTime = (_.get(this.state.employee, 'dateOfBirth') || 0);
    let bdString = '';
    if (birthTime !== 0) {
      const birthDate = new Date(birthTime);
      birthDate.setHours(birthDate.getHours() + 6);
      bdString = birthDate.toLocaleDateString();
    }
    // eslint-disable-next-line implicit-arrow-linebreak
    return (<div>
      <div className="sidebar col-md-2">
        {/* <div><Sidebar menuItems={this.getMenuItems()}/></div> */}
      </div>
      <div id={'sidebar'}>
        <div className={'profile-wrapper col-md-10'}>
          <div className="profile-content">
            <div className={'profile-header-content'}>
              <h1>
                {Utils.checkStringField(_.get(this.state.employee, 'name'))}
              </h1>
              <div className="col-md-6">
                <div className={'field'}>
                  <label htmlFor={'hiredate'}>Fecha de contratación:</label>
                  <span id={'hiredate'}>{Utils.checkStringField(hdString)}</span>
                </div>
                <div className={'field'}>
                  <label htmlFor={'email'}>Email:</label>
                  <span id={'email'}>{Utils.checkStringField(_.get(this.state.employee, 'email'))}</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className={'field'}>
                  <label htmlFor={'phone'}>Teléfono:</label>
                  <span id={'phone'}>{Utils.checkStringField(_.get(this.state.employee, 'phone'))}</span>
                </div>
                <div className={'field'}>
                  <label htmlFor={'birthDate'}>Fecha de nacimiento:</label>
                  <span id={'birthDate'}>{Utils.checkStringField(bdString)}</span>
                </div>
              </div>
            </div>
            <div className={'profile-detail-list'}>

            </div>
          </div>
        </div>
      </div>
    </div>);
  };

  private loadEmployeeProfile = async () => {
    const loggedUserEmail = _.get(this.props.user, 'attributes.email');
    const eeData = await API.get('Employee', `Anequim/${loggedUserEmail}?db=MX`, {});
    this.setState({ 'employee': eeData });
  };

  private referenceRows = () => {
    let key = 0;
    const rows: Array<any> = [];
    _.each(_.get(this.state.employee, 'employeeExternalSystemReferences'), (c: any) => {
      key++;
      // this column should come from another place or maybe all the columns/rows?
      const date = new Date(_.get(c, 'createDate'));
      const dispDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;

      rows.push(
        <tr key={key}>
          <td>
            {dispDate}
          </td>
          <td>
            {_.get(c, 'nomina')}
          </td>
        </tr>
      );
      // ADDING "SHADOW" ROW AS PLACEHOLDER FOR SECOND PAY STUB
      const emptyRowStyles = { 'color': 'red' };
      rows.push(
        <tr key={key}>
          <td>
            {dispDate}
          </td>
          <td>
            {_.get(c, 'nomina')}
          </td>
          <td>
            <div id={_.get(c, 'incentivosPayStubId')} style={emptyRowStyles}>Estamos trabajando para poner a tu disposicion tus recibos de incentivos en este espacio. Mientras tanto, puedes solicitar una copia por la liga al chat abajo [¿Necesitas ayuda?] y con gusto to lo enviaremos</div>
          </td>
        </tr>
      );
    });
    return rows;
  };
}
