/* eslint-disable max-len */
// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import { useTable, useSortBy } from 'react-table';
import '../assets/styles/remoteprofessionals.scss';
import * as _ from 'lodash';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { Environment } from '../environment';

interface Employee {
  name: string;
  status: string;
  position: string;
  payrollHours: string;
  ptoTaken: string;
}

interface TableProps {
  tableData: Employee[];
  user: any
  setterData: Function
  setterLoading: Function
}
const RemoteProfessionalsTable: React.FC<TableProps> = ({
  user, setterData, setterLoading, tableData
}) => {
  const columns = React.useMemo(
    () => [
      {
        'Header': 'Name',
        'accessor': 'name'
      },
      {
        'Header': 'Status',
        'accessor': 'status'
      },
      {
        'Header': 'Position',
        'accessor': 'position'
      },
      {
        'Header': 'Payroll Hours',
        'accessor': 'payrollHours'
      },
      {
        'Header': 'PTO Taken',
        'accessor': 'ptoTaken'
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, 'data': tableData }, useSortBy);

  const currDate:Date = new Date();
  const fetchDatabyDate = async (start: string, end: string) => {
    try {
      const startDate = new Date(start);
      const endDate = new Date(end);
      const startDateStr = `${startDate.getDate().toString().padStart(2, '0')}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getFullYear()}`;
      const endDateStr = `${endDate.getDate().toString().padStart(2, '0')}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate.getFullYear()}`;
      const userName = _.get(user, 'username');
      const userAccountId = _.get(user.attributes, 'custom:ext_ref_id');
      const response = await fetch(`${Environment.get('ABR_INTERMEDITARY_SERVER')}`, {
        'method': 'POST',
        'headers': {
          'Content-Type': 'application/json'
        },
        'body': JSON.stringify({
          'function_type': 'get_people_timesheet',
          'name': userName,
          'usAccountId': userAccountId,
          'start_date': startDateStr,
          'end_date': endDateStr,
          'db': 'mx'
        })
      });

      const result = await response.json();

      setterData(result.data);
      setterLoading(false);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  return (
    <div className='row'>
      <div className="col-12" style={{ 'height': '80px' }}>
        <DateRangePicker onCallback={(start, end) => fetchDatabyDate(start, end)} initialSettings={{
          'endDate': currDate,
          'startDate': (currDate).setDate(currDate.getDate() - 7)
        }}
        >
                <input type="text" className="form-control col-4" style={{ 'margin': 'auto' }} />
        </DateRangePicker>
      </div>
      <div className='col-12'>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                  <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <span>
                  <span>
                  {column.render('Header')} {column.isSorted && (column.isSortedDesc ? ' ▼' : ' ▲')}
                </span>
                </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      </div>
    </div>
  );
};

type Props = {
  user: any
}

export const RemoteProfessionals: React.FC<Props> = ({ user }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const setterData = (val:any) => { setData(val); };
  const setterLoading = (val:any) => { setLoading(val); };
  const fetchData = async () => {
    try {
      const userName = _.get(user, 'username');
      const userAccountId = _.get(user.attributes, 'custom:ext_ref_id');
      const response = await fetch(`${Environment.get('ABR_INTERMEDITARY_SERVER')}`, {
        'method': 'POST',
        'headers': {
          'Content-Type': 'application/json'
        },
        'body': JSON.stringify({
          'function_type': 'get_people_timesheet',
          'name': userName,
          'usAccountId': userAccountId,
          'start_date': null,
          'end_date': null,
          'db': 'mx'
        })
      });

      const result = await response.json();
      setData(result.data);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  let content;

  if (loading) {
    content = (<div>Loading...</div>);
  } else {
    content = (<RemoteProfessionalsTable user={user} setterData={setterData} setterLoading={setterLoading} tableData={data}/>);
  }

  return (content);
};
