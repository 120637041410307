/**
 * Pulls REACT_APP_ enviornment variables based on a key.
 */
export class Environment {
  public static get(key: string) {
    return process.env[`REACT_APP_${this.stage()}_${key}`];
  }

  public static stage(): string {
    return process.env.REACT_APP_STAGE?.toUpperCase() || 'DEV';
  }
}
