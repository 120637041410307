import { createSlice } from '@reduxjs/toolkit';

export const counterSlice = createSlice({
  'name': 'counter',
  'initialState': {
    'value': 0
  },
  'reducers': {
    'increment': (state) => {
      // Redux Toolkit allows us to write "mutating" logic in counter. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      // eslint-disable-next-line no-param-reassign
      state.value += 1;
    },
    'decrement': (state) => {
      // eslint-disable-next-line no-param-reassign
      state.value -= 1;
    },
    'incrementByAmount': (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.value += action.payload;
    }
  }
});

export const { increment, decrement, incrementByAmount } = counterSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const incrementAsync = (amount: any) => (dispatch: any) => {
  setTimeout(() => {
    dispatch(incrementByAmount(amount));
  }, 1000);
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectCount = (state: any) => state.counter.value;

export default counterSlice.reducer;
