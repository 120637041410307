// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { CenteredSpinner } from '../components/centered_spinner';
import '../assets/styles/service-issues.scss';
import { ServiceIssueCard } from '../components/ServiceIssueCard';
import { IDynamoServiceIssueModel } from '../components/interfaces/IDynamoServiceIssueModel';
import { Environment } from '../environment';
import { ServiceIssuesButtonSection } from '../components/ServiceIssuesButtonSection';
import {
  selectAllData
} from '../features/employeeProfile/employeeProfileSlice';

type Props = {
  user: any
}

export const ServiceIssues: React.FC<Props> = ({ user }) => {
  const [serviceIssues, setServiceIssues] = useState([] as IDynamoServiceIssueModel[]);
  const [error, setError] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  // const [showServiceIssueDetail, setShowServiceIssueDetail] = useState(false);
  // const [client, setClient] = useState('');
  // const [clientList, setClientList] = useState([] as any[]);
  // const [vendorList, setVendorList] = useState([] as any[]);
  // const [statusList, setStatusList] = useState([] as any[]);
  // const [categoryList, setCategoryList] = useState([] as any[]);
  // const [priorityList, setPriorityList] = useState([] as any[]);
  // const [clientStatusMappings, setClientStatusMappings] = useState([] as any[]);
  // const [defaultStatuses, setDefaultStatuses] = useState([] as any[]);

  // profile data selectors
  const profileData = useSelector(selectAllData);

  const loadServiceIssues = async () => {
    try {
      const loggedUserEmail = _.get(user, 'attributes.email');
      await fetch(`${Environment.get('SPRING_INTEGRATION_HUB_API_ROOT')}rm/service-issues/client/ae801db9-bcc0-4cb1-a704-e00eb6d65dee/service-issues/rp`, {
        'method': 'POST',
        'headers': {
          'Content-Type': 'application/json'
        },
        'body': JSON.stringify({ 'email': loggedUserEmail })
      })
        .then((r) => r.json())
        .then((data) => {
          setServiceIssues(data);
        });
    } catch (e) {
      console.log(e);
      setError(true);
    }
  };

  useEffect(() => {
    setShowSpinner(true);
    loadServiceIssues();
    setShowSpinner(false);
  }, []);

  let content;

  if (showSpinner) {
    content = (<CenteredSpinner/>);
  } else if (error) {
    content = (
      <div>
        <Container>
          {/* eslint-disable-next-line max-len */}
          <h5>Ha ocurrido un error, intenta recargar la página, si el error persiste pónte en contacto con tu
            advocate</h5>
        </Container>
      </div>
    );
  } else {
    // eslint-disable-next-line max-len,array-callback-return
    const issueList = serviceIssues.map((data, index) => <ServiceIssueCard serviceIssueData={data} key={index}/>);
    content = (
      <div>
        <Container fluid id="service-issues-wrapper">
          <Row className="justify-content-center">
            <Col sm={4} className="text-center">
              <div className="help-center-container">
                <div className="comment-button-label">
                  <h3 className="si-header">Centro de atención</h3>
                  <p className="si-body">¿Necesitas ayuda o tienes preguntas?<br/>¡Envíanos un ticket!</p>
                </div>
                <div id="si-comment-button-container">
                  {/* eslint-disable-next-line max-len */}
                  <ServiceIssuesButtonSection key={1} tileData={null} user={user} profileData={profileData}/>
                </div>
              </div>
            </Col>
            <Col sm={8} className="vertical-line">
              <div className="help-center-container">
                <h3 className="si-header">Historial de tickets</h3>
                <div id="issue-list-container">
                  {issueList}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  return (content);
};
