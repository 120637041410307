// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import * as _ from 'lodash';
import '../assets/styles/file-permissions-modal.scss';
import InputGroup from 'react-bootstrap/InputGroup';
import { IDocumentAccessModelRow } from './IDocumentAccessModelRow';

interface FilePermissionsModalProps {
    modalShowing: boolean,
    file: IDocumentAccessModelRow,
    onHideHandler: any,
    disableRecordsHandler: any,
    documentAccessRows: Array<IDocumentAccessModelRow>,
    modalError: boolean,
    checkboxHandler: any
}

export class FilePermissionsModal extends Component<FilePermissionsModalProps, any> {
  render() {
    return (
            <div>
                {this.permissionsModal()}
            </div>
    );
  }

  permissionsModal = () => {
    const { file, modalError } = this.props;
    return (
          <Modal
              size='lg'
              animation={false}
              className='file-permissions-modal-form'
              show={this.props.modalShowing}
              onHide={this.props.onHideHandler}
              centered>
              <Modal.Header closeButton>
                  <Modal.Title>Permisos - {_.get(file, 'documentName')}</Modal.Title>
              </Modal.Header>
              {/* eslint-disable-next-line max-len */}
              <Modal.Body>
                  { !modalError
                    ? <div className="document-permissions-container">
                      <table className="document-permissions-table table table-striped">
                          <thead className="document-permissions-thead">
                          <tr>
                              <th scope="col">
                                  <span>Empleado</span>
                              </th>
                              <th scope="col">
                                  <span>Tipo de permiso</span>
                              </th>
                              <th scope="col">
                                  <span>Fecha de creación</span>
                              </th>
                              <th scope="col">
                                  <span>Desactivar</span>
                              </th>
                          </tr>
                          </thead>
                          <tbody>
                          {this.permissionsRows()}
                          </tbody>
                      </table>
                  </div> : <div>Hubo un error cargando los detalles</div> }
              </Modal.Body>
              <Modal.Footer>
                  <Button variant="secondary" onClick={this.props.onHideHandler}>
                      Cancelar
                  </Button>
                  <Button variant="primary" onClick={this.props.disableRecordsHandler}>
                      Borrar seleccionados
                  </Button>
              </Modal.Footer>
          </Modal>
    );
  };

  private permissionsRows = () => {
    const { documentAccessRows } = this.props;
    let key = 0;
    const rows: Array<any> = [];
    _.each(documentAccessRows, (r: IDocumentAccessModelRow) => {
      const date = new Date(_.get(r, 'createDate'));
      key++;
      rows.push(
          <tr key={key} className={ r.isActive ? 'active-row' : 'inactive-row' }>
              <td>
                  {r.employeeName}
              </td>
              <td>
                  {r.documentRoleText}
              </td>
              <td>
                  {`${date.getDay()}/${date.getMonth()}/${date.getFullYear()}`}
              </td>
              <td>
                  <InputGroup className='check-box-container is-active-check-box'>
                      <InputGroup.Checkbox
                          className='mt-0'
                          checked={!r.isActive}
                          onChange={() => this.props.checkboxHandler(r)}
                      />
                  </InputGroup>
              </td>
          </tr>
      );
    });
    return rows;
  };
}
