/* eslint-disable no-restricted-globals */
// eslint-disable-next-line no-use-before-define
import React, { useState } from 'react';
import { API } from '@aws-amplify/api';
import DatePicker from 'react-datepicker';
import { Spinner } from '../components/spinner';

type status = 'complete'|'loading';

function RenderResult(props: {result: string, loadStatus: status}) {
  if (props.loadStatus === 'loading') {
    return (<Spinner/>);
  }
  if (props.result === '') {
    return (<div></div>);
  }
  return (
      <pre>
      {props.result}
    </pre>
  );
}

export function AwardCalculator() {
  const [loadingStatus, updateStatus] = useState<status>('complete');
  const [testEmail, updateEmail] = useState('');
  const [asOfDate, updateDate] = useState<Date|undefined>(undefined);
  const [result, updateResult] = useState('');

  const submitCalculuation = async () => {
    updateStatus('loading');
    const calcResult = await API.get('CalculateAward', '', {
      'queryStringParameters': {
        'email': testEmail,
        'asOfDate': asOfDate?.getTime()
      }
    });
    updateResult(JSON.stringify(calcResult, null, 4));
    updateStatus('complete');
  };

  return (
    <div className='col-xs-12'>
      <div className='col-md-8 col-xs-12 offset-md-2'>
        <h1>Test Award Calculator</h1>
        <div className="form-group">
          <label htmlFor='testEmail'>Email</label>
          <input type="text" className="form-control" id='testEmail' onChange={(event: any) => { updateEmail(event.target.value); }}/>
        </div>
        <div className="form-group">
          <label htmlFor='asOfDate'>As of Date</label>
          <DatePicker id='asOfDate' selected={asOfDate} className="form-control" onChange={(date: any) => { updateDate(date); }}/>
        </div>
        <div className="row">
          <div className="col col-xs-4 text-left">
            <button className='btn btn-primary' onClick={submitCalculuation}>Calculate</button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className='col-md-8 col-xs-12 offset-md-2'>
          Results:<br />
          <RenderResult result={result} loadStatus={loadingStatus} />
        </div>
      </div>
    </div>
  );
}
