// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import * as _ from 'lodash';
import { EmployeeReferencesForm } from '../components/EmployeeReferencesForm';

export class EmployeeReferences extends Component<{}> {
  props: {
    user: any
  } = {
    'user': null
  };

  private administrators = ['sberkebile@anequim.net', 'aquintero@anequim.net',
    'robertoramirez@anequim.net', 'vmelgar@anequim.net', 'thernandez@anequim.net', 'jaspen@anequim.net',
    'asaucedo@anequim.net', 'preynoso@anequim.net', 'jcuvelier@anequim.net'];

  render() {
    const isUserAdmin = this.getIsUserAdmin();
    if (isUserAdmin) {
      return (
        <div className={'employee-references-content'}>
          <div>
            <h1>Agregar referencias</h1>
          </div>
          <div className={'references-form-container'}>
            <EmployeeReferencesForm/>
          </div>
        </div>
      );
    }
    return (<div>Usuario no autorizado</div>);
  }

  private getIsUserAdmin = () => (_.includes(
    this.administrators,
    _.get(this.props.user, 'attributes.email')
  ));
}
