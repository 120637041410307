// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import * as _ from 'lodash';
import { LandingPageTiles } from '../landingPageTiles';
import '../assets/styles/landing.scss';
import { Tile } from '../components/Tile';
import { Boletin } from '../components/Boletin';
import { EmployeeProfile } from '../components/EmployeeProfile';
import { ClientProfile } from '../components/ClientProfile';
// import { CalendarTile } from '../components/CalendarTile';

const tiles = _.map(LandingPageTiles);

const reportingUsers = ['robertoramirez@anequim.net', 'vmelgar@anequim.net', 'jdelgado@anequim.net', 'rferrer@anequim.net', 'luistorresmorett@gmail.com', 'jaspen@anequim.net', 'sberkebile@anequim.net', 'icardenas@anequim.net', 'aquintero@anequim.net', 'arlette_saucedo@hotmail.com'];
const serviceIssuesUsers = ['jaspen@anequim.net', 'sberkebile@anequim.net', 'jdelgado@anequim.net', 'amorales@wistargroup.com', 'aquintero@anequim.net', 'arlette_saucedo@hotmail.com'];
const administrators = ['sberkebile@anequim.net', 'aquintero@anequim.net'];

export class Landing extends Component<{}> {
  state: {
    isWideMenu?: boolean;
    key?: string;
    data?: any;
    tileCatalog?: Array<any>;
    authToken?: string;
    loading: boolean;
    permissions?: Array<string>;
    emulatedUserRef?: any;
  } = { 'loading': true, 'tileCatalog': [] };

  props: { user: any } = { 'user': null };

  constructor(props: any) {
    super(props);
    this.props = props;
  }

  componentDidMount() {
    this.getFilteredTiles();
  }

  captureEmulatedUser = (event: any) => {
    event.preventDefault();
    console.log(`emulated user: ${event.target.value}`);
  };

  getFilteredTiles = () => {
    // eslint-disable-next-line max-len
    //  TODO: make a web call to get tiles the user has permission to const filteredTiles = await this.getFilteredTiles();
    const username = _.get(this.props.user, 'attributes.email');
    const userType = _.get(this.props.user, 'attributes.custom:user_type');

    let perms: Array<string> = [];

    if (userType === 'Client' || userType === 'Supervisor' || userType === 'Decision maker') {
      perms = ['Client'];
    } else if (_.includes(reportingUsers, username)) {
      perms = ['Reporting', 'Remote Professional'];
    } else {
      perms = ['Remote Professional'];
    }

    // FOR TEST PURPOSES ONLY
    // if (username === 'jcuvelier@anequim.net') {
    //   perms = ['Client'];
    // }

    if (_.includes(serviceIssuesUsers, username)) {
      perms.push('ServiceIssues');
    }

    if (_.includes(administrators, username)) {
      perms.push('Administrator');
    }

    const t: Array<any> = [];
    _.each(tiles, (i) => {
      if (_.intersection(i.permissions, perms).length > 0) {
        t.push(i);
      }
    });
    this.setState({ 'permissions': perms });
    this.setState({ 'tileCatalog': t });
  };

  renderTiles(start, end) {
    const slicedTiles = this.state.tileCatalog?.slice(start, end);
    return slicedTiles?.map((tile, index) => {
      const key = start + index; // Create a unique key
      return <Tile key={key} tileData={tile}>{tile.title}</Tile>;
    });
  }

  render() {
    const userType = _.get(this.props.user, 'attributes.custom:user_type');
    let isAdmin;
    if (_.includes(this.state.permissions, 'Administrator')) {
      isAdmin = true;
    }
    if (this.state.tileCatalog) {
      return (
        <div className="anequim-container">
          <div className="grid-container">
            <div className="profile-container">
              {userType === 'Client' || userType === 'Supervisor' || userType === 'Decision maker'
                ? <ClientProfile user={this.props.user}/>
                : <EmployeeProfile user={this.props.user}/>}
            </div>
            <div className="boletin-container">
              <Boletin user={this.props.user}/>
            </div>
            <div className="tile first-tile">
              {this.renderTiles(0, 1)}
            </div>
            <div className="tile second-tile">
              {this.renderTiles(1, 2)}
            </div>
            <div className="tile extra-tile">
              {this.renderTiles(2, 49)}
            </div>
            <div className="tile first-tile">
              {this.renderTiles(49, 50)}
            </div>
            <div className="tile second-tile">
              {this.renderTiles(50, 51)}
            </div>
            <div className="tile extra-tile">
              {this.renderTiles(51, 100)}
            </div>
            {/* eslint-disable-next-line max-len */}
            {/* {!_.includes(this.state.permissions, 'Client') && <div className="tile calendar-tile"><CalendarTile/></div>} */}
            <div className="landing-body">{(isAdmin ? <div>
              <span>Who do you want to emulate?</span>
              <input id='emulated-user' type={'text'} onChange={this.captureEmulatedUser}/>
            </div> : null)}</div>
          </div>
        </div>
      );
    }
    return (<h2>There are no options for this user.</h2>);
  }
}
