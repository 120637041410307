// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { API } from '@aws-amplify/api';
import * as _ from 'lodash';
import UploadFragment from '../components/UploadFragment';
import { Utils } from '../utils/utils';
import '../assets/styles/upload-fragment.scss';

export class PayrollAdmin extends Component<{}> {
  render = () => (
    <div className="container">
      <Link to="/my-documents" className="back-button">
        Regresar
      </Link>
      <UploadFragment onSubmit={this.submit}/>
    </div>);

  // eslint-disable-next-line no-unused-vars
  submit = (fileList: Array<File>, fileDetailList: any) => {
    const formData = new FormData();
    _.each(fileList, (f) => {
      formData.append('file', f);
    });
    // formData.append('file', new Blob(fileList));
    formData.append('fileData', JSON.stringify(fileDetailList));

    this.setState({ 'isLoading': true });
    const ck = Utils.readCookie();
    return API.post('Upload', '', {
      'headers': {
        'Content-Type': 'multipart/form-data; boundary=-----Web1387te7',
        'Authorization': ck
      },
      'body': formData
    })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          'fileList': [],
          'isLoading': false
        });
      });
  };
}
