import LifeActive from './assets/images/benefits/life-active.svg';
import LifeInactive from './assets/images/benefits/life-inactive.svg';
import MinorMedActive from './assets/images/benefits/minor-medical-active.svg';
import MinorMedInactive from './assets/images/benefits/minor-medical-inactive.svg';
import MajorMedActive from './assets/images/benefits/major-medical-active.svg';
import MajorMedInactive from './assets/images/benefits/major-medical-inactive.svg';

type BenefitMetadata = {
  title: string;
  activeIcon: string;
  inactiveIcon: string;
}

export const Benefits: {[key: string]: BenefitMetadata} = {
  'life': {
    'title': 'Seguro de Vida',
    'activeIcon': LifeActive,
    'inactiveIcon': LifeInactive
  },

  'minorMedical': {
    'title': 'Seguro de Gastos Médicos Menores',
    'activeIcon': MinorMedActive,
    'inactiveIcon': MinorMedInactive
  },

  'majorMedical': {
    'title': 'Seguro de Gastos Médicos Mayores',
    'activeIcon': MajorMedActive,
    'inactiveIcon': MajorMedInactive
  }
};
