// eslint-disable-next-line no-use-before-define
import React, { Component, ChangeEvent } from 'react';
import '../../assets/styles/my-documents.scss';
import FilterIcon from '../../assets/images/filter-circle-xmark.svg';

type Props = {
  // eslint-disable-next-line no-unused-vars
  onFilter: (filterText: string) => void;
};

type State = {
  filterText: string;
};

export class DocumentsFilter extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      'filterText': ''
    };
  }

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ 'filterText': value }, () => {
      this.props.onFilter(value);
    });
  };

  handleClearClick = () => {
    this.setState({ 'filterText': '' });
    this.props.onFilter('');
  };

  render() {
    return (
      <div className="documents-filter">
        <div className="input-group">
          <input
            type="text"
            className="form-control filter-input"
            placeholder="Buscar"
            value={this.state.filterText}
            onChange={this.handleChange}
          />
          <button
            className="btn filter-reset"
            type="button"
            onClick={this.handleClearClick}
          >
            <img src={FilterIcon} alt='Clear' width='12' height='12'/>
          </button>
        </div>
      </div>
    );
  }
}
