// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import { marked } from 'marked';
import * as _ from 'lodash';
import { Environment } from '../environment';

interface IHistoryNote {
  HistoryCategoryID?: string;
  HistoryDate: number;
  HistoryID: string;
  IsLocked?: boolean;
  IsPinned?: boolean;
  IsShowInPOComments?: boolean;
  Note: string;
  FileID?: string;
}

interface Props {
  user: any;
}

export class Boletin extends Component<Props, any> {
  state: {
    isLoading: boolean;
    boletinData: Array<any>;
    apiError: boolean;
  } = {
    'isLoading': false,
    'boletinData': [],
    'apiError': false
  };

  async loadBoletinData() {
    await fetch(`${Environment.get('SPRING_INTEGRATIONS_API_ROOT')}rm/history-notes/boletin`, {})
      .then((response) => response.json())
      .then((data) => {
        this.setState({ 'boletinData': data, 'apiError': false });
      }).catch(() => this.setState({ 'apiError': true }));
  }

  componentDidMount() {
    this.setState({ 'isLoading': true });

    Promise.all(
      [this.loadBoletinData()]
    ).then(() => {
      this.setState({ 'isLoading': false });
    });
  }

  render() {
    const { isLoading, boletinData, apiError } = this.state;
    const userType = _.get(this.props.user, 'attributes.custom:user_type');
    const historyCategoryId = (userType === 'Client' || userType === 'Supervisor' || userType === 'Decision maker') ? '35' : '32';
    const headerText = userType === 'Client' || userType === 'Supervisor' || userType === 'Decision maker' ? 'Bulletin board' : 'Boletín';
    const spanishError = 'Ha ocurrido un error, por favor intenta de nuevo más tarde.';
    const englishError = 'An error occurred. Please try again later.';
    const errorText = userType === 'Client' ? englishError : spanishError;

    if (isLoading) {
      return <div className="boletin"><h3 className="header">{headerText}</h3></div>;
    }

    if (apiError) {
      return <div className="boletin"><h3 className="header">{headerText}</h3>
        <div className="boletin-notes">{errorText}</div>
      </div>;
    }

    const filteredData = boletinData.filter((historyNote: IHistoryNote) => (
      historyNote.HistoryCategoryID === historyCategoryId));

    const sortedData = filteredData.sort(
      (a: IHistoryNote, b: IHistoryNote) => b.HistoryDate - a.HistoryDate
    );

    const renderer = new marked.Renderer();
    renderer.link = (href, title, text) => `<a target="_blank" rel="noopener noreferrer" href="${href}" title="${title}">${text}</a>`;

    marked.setOptions({
      renderer,
      'breaks': true,
      'mangle': false,
      'headerIds': false
    });

    return (
      <div className="boletin">
        <h3 className="header">{headerText}</h3>
        <div className="boletin-notes scrollable-content">
          {sortedData.map((historyNote: IHistoryNote) => {
            const date = new Date(historyNote.HistoryDate);
            const formattedDate = date.toLocaleDateString('es-MX');
            const markedNote = marked(historyNote.Note);
            return (
              <div key={historyNote.HistoryID}>
                <div className="note-date"><small>{formattedDate}</small></div>
                <div dangerouslySetInnerHTML={{ '__html': markedNote }}/>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
