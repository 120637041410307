// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import '../assets/styles/hr-page.scss';
import * as _ from 'lodash';
import { Sidebar } from '../components/Sidebar';

export class HrPage extends Component<{}> {
    props: {
        user: any
    } = {
      'user': null
    };

    private administrators = ['sberkebile@anequim.net', 'aquintero@anequim.net',
      'robertoramirez@anequim.net', 'vmelgar@anequim.net', 'thernandez@anequim.net', 'jaspen@anequim.net',
      'asaucedo@anequim.net', 'preynoso@anequim.net', 'jcuvelier@anequim.net'];

    private basicMenuItems: Array<any> = [];

    private hrMenuItems: Array<any> = [
      { 'title': 'Referencias de Empleado', 'path': '/employee-refs' }
    ];

    render() {
      const isUserAdmin = this.getIsUserAdmin();
      if (isUserAdmin) {
        return (
          <div>
            <div className="sidebar col-md-2">
              <div><Sidebar menuItems={this.getMenuItems()}/></div>
            </div>
            <div id={'sidebar'}>
              <div className={'payroll-wrapper col-md-10'}>
                <div>
                  <h1>Recursos Humanos</h1>
                </div>
              </div>
            </div>
          </div>
        );
      }
      return (<div>Usuario no autorizado</div>);
    }

    private getMenuItems = () => (_.includes(
      this.administrators,
      _.get(this.props.user, 'attributes.email')
    ) ? this.hrMenuItems : this.basicMenuItems);

    private getIsUserAdmin = () => (_.includes(
      this.administrators,
      _.get(this.props.user, 'attributes.email')
    ));
}
