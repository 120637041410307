// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import * as _ from 'lodash';
import { ISwimLaneDefinition } from '../components/ISwimLaneDefinition';
// import { BaseWorkflowDashboard } from './BaseWorkflowDashboard';

const dummySwimLanes = [{
  'header': 'High Priority',
  'width': '20%',
  'swimlaneId': 1
},
{
  'header': 'Medium Priority',
  'width': '20%',
  'swimlaneId': 2
},
{
  'header': 'Low Priority',
  'width': '20%',
  'swimlaneId': 3
}];

const dummyCards = [{
  'title': 'Toilet keeps running',
  'text': 'Tenant says main toilet will not stop running',
  'priority': 2,
  'dueDate': new Date('2021-03-05'),
  'key': 36,
  'swimLaneId': 2,
  'id': '1'
},
{
  'title': 'No Heat',
  'text': '20%',
  'priority': 1,
  'dueDate': new Date('2021-03-03'),
  'key': 35,
  'swimLaneId': 1,
  'id': '2'
},
{
  'title': 'Dripping faucet',
  'text': '20%',
  'priority': 3,
  'dueDate': new Date('2021-03-05'),
  'key': 34,
  'swimLaneId': 3,
  'id': '3'
},
{
  'title': 'Investigate Unauthorized Pet',
  'text': '20%',
  'priority': 2,
  'dueDate': new Date('2021-02-27'),
  'key': 33,
  'swimLaneId': 2,
  'id': '4'
},
{
  'title': 'Possible mold in bathroom',
  'text': '20%',
  'priority': 3,
  'dueDate': new Date('2021-03-05'),
  'key': 32,
  'swimLaneId': 3,
  'id': '5'
}, {
  'title': 'Fence Repair',
  'text': 'Tenant says main toilet will not stop running',
  'priority': 2,
  'dueDate': new Date('2021-03-04'),
  'key': 31,
  'swimLaneId': 2,
  'id': '6'
},
{
  'title': 'Carpet Cleaning',
  'text': '20%',
  'priority': 3,
  'dueDate': new Date('2021-03-15'),
  'key': 30,
  'swimLaneId': 3,
  'id': '7'
},
{
  'title': 'Move Out Inspection',
  'text': '20%',
  'priority': 3,
  'dueDate': new Date('2021-03-16'),
  'key': 29,
  'swimLaneId': 3,
  'id': '7'
},
{
  'title': 'Carpet Cleaning',
  'text': '20%',
  'priority': 3,
  'dueDate': new Date('2021-03-07'),
  'key': 28,
  'swimLaneId': 3,
  'id': '9'
},
{
  'title': 'Move Out Inspection',
  'text': '20%',
  'priority': 3,
  'dueDate': new Date('2021-03-25'),
  'key': 27,
  'swimLaneId': 3,
  'id': '10'
},
{
  'title': 'Repair tub caulking',
  'text': '20%',
  'priority': 2,
  'dueDate': new Date('2021-03-07'),
  'key': 26,
  'swimLaneId': 2,
  'id': '11'
},
{
  'title': 'Broken faucet handle',
  'text': '20%',
  'priority': 3,
  'dueDate': new Date('2021-03-05'),
  'key': 25,
  'swimLaneId': 3,
  'id': '12'
},
{
  'title': 'Broken faucet handle',
  'text': '20%',
  'priority': 1,
  'dueDate': new Date('2021-03-10'),
  'key': 24,
  'swimLaneId': 1,
  'id': '13'
},
{
  'title': 'Maria Bonita',
  'text': 'Tenant says main toilet will not stop running',
  'priority': 3,
  'dueDate': new Date('2021-03-05'),
  'key': 1,
  'swimLaneId': 3,
  'id': '14'
},
{
  'title': 'H. Delbrook',
  'text': '20%',
  'priority': 2,
  'dueDate': new Date('2021-03-03'),
  'key': 2,
  'swimLaneId': 2,
  'id': '15'
},
{
  'title': 'Abby Normal',
  'text': '20%',
  'priority': 1,
  'dueDate': new Date('2021-03-05'),
  'key': 3,
  'swimLaneId': 1,
  'id': '16'
},
{
  'title': 'Girard Grebe',
  'text': '20%',
  'priority': 1,
  'dueDate': new Date('2021-02-27'),
  'key': 4,
  'swimLaneId': 1,
  'id': '17'
},
{
  'title': 'Hannah Montana',
  'text': '20%',
  'priority': 3,
  'dueDate': new Date('2021-03-05'),
  'key': 5,
  'swimLaneId': 3,
  'id': '18'
}, {
  'title': 'Fred Flintstone',
  'text': 'Tenant says main toilet will not stop running',
  'priority': 2,
  'dueDate': new Date('2021-03-04'),
  'key': 6,
  'swimLaneId': 2,
  'id': '19'
},
{
  'title': 'George Jetson',
  'text': '20%',
  'priority': 3,
  'dueDate': new Date('2021-03-15'),
  'key': 7,
  'swimLaneId': 3,
  'id': '20'
},
{
  'title': 'Bilbo Baggins',
  'text': '20%',
  'priority': 3,
  'dueDate': new Date('2021-03-16'),
  'key': 8,
  'swimLaneId': 3,
  'id': '21'
},
{
  'title': 'Jeff Conaway',
  'text': '20%',
  'priority': 1,
  'dueDate': new Date('2021-03-07'),
  'key': 9,
  'swimLaneId': 1,
  'id': '22'
},
{
  'title': 'John Belushi',
  'text': '20%',
  'priority': 3,
  'dueDate': new Date('2021-03-25'),
  'key': 10,
  'swimLaneId': 3,
  'id': '23'
},
{
  'title': 'Angelina Jolie',
  'text': '20%',
  'priority': 1,
  'dueDate': new Date('2021-03-07'),
  'key': 11,
  'swimLaneId': 1,
  'id': '24'
},
{
  'title': 'Goldie Hawn',
  'text': '20%',
  'priority': 3,
  'dueDate': new Date('2021-03-05'),
  'key': 12,
  'swimLaneId': 3,
  'id': '25'
},
{
  'title': 'Theo Lesieg',
  'text': '20%',
  'priority': 3,
  'dueDate': new Date('2021-03-10'),
  'key': 13,
  'swimLaneId': 3,
  'id': '26'
},
{
  'title': 'Airfare to Phoenix',
  'text': 'Tenant says main toilet will not stop running',
  'priority': 2,
  'dueDate': new Date('2021-03-05'),
  'key': 14,
  'swimLaneId': 2,
  'id': '27'
},
{
  'title': 'Hotel for conference',
  'text': '20%',
  'priority': 1,
  'dueDate': new Date('2021-03-03'),
  'key': 15,
  'swimLaneId': 1,
  'id': '28'
},
{
  'title': 'Business lunch',
  'text': '20%',
  'priority': 3,
  'dueDate': new Date('2021-03-05'),
  'key': 16,
  'swimLaneId': 3,
  'id': '29'
},
{
  'title': 'Business lunch',
  'text': '20%',
  'priority': 2,
  'dueDate': new Date('2021-02-27'),
  'key': 17,
  'swimLaneId': 2,
  'id': '30'
},
{
  'title': 'Business lunch',
  'text': '20%',
  'priority': 3,
  'dueDate': new Date('2021-03-05'),
  'key': 18,
  'swimLaneId': 3,
  'id': '31'
}, {
  'title': 'Meals for conference',
  'text': 'Tenant says main toilet will not stop running',
  'priority': 2,
  'dueDate': new Date('2021-03-04'),
  'key': 19,
  'swimLaneId': 2,
  'id': '32'
},
{
  'title': 'Dry Cleaning',
  'text': '20%',
  'priority': 3,
  'dueDate': new Date('2021-03-15'),
  'key': 20,
  'swimLaneId': 3,
  'id': '33'
},
{
  'title': 'Business lunch',
  'text': '20%',
  'priority': 3,
  'dueDate': new Date('2021-03-16'),
  'key': 21,
  'swimLaneId': 3,
  'id': '34'
},
{
  'title': 'Dry Cleaning',
  'text': '20%',
  'priority': 3,
  'dueDate': new Date('2021-03-07'),
  'key': 22,
  'swimLaneId': 3,
  'id': '35'
}];
export class MyWorkday extends Component<{}> {
  state: {
        swimlanes: Array<ISwimLaneDefinition>,
        dashboardId: number,
        activeIcon: string,
        cards: Array<any>
    } = {
      'swimlanes': dummySwimLanes,
      'cards': dummyCards,
      'dashboardId': 1,
      'activeIcon': '../../assets/images/landing-page/exclamation.svg'
    };

  // eslint-disable-next-line class-methods-use-this
  render() {
    return (<div className="dashboard">
                <h2>My Workday</h2>
                {/* <BaseWorkflowDashboard dashboardId={this.state.dashboardId} */}
                {/*                       swimLanes={this.state.swimlanes} */}
                {/*                       cardCollection={this.state.cards} */}
                {/*                       activeIcon={this.state.activeIcon} */}
                {/*                      onDropped={this.onDropped}/> */}
            </div>
    );
  }

  private onDropped = (cardId: string, swimlaneId: string, targetSwimlaneId: number) => {
    const cards = _.cloneDeep(this.state.cards);
    const draggedCardIdx = cards.findIndex(
      ({ id, swimLaneId }) => id === cardId && swimLaneId === +swimlaneId
    );
    cards[draggedCardIdx] = {
      ...cards[draggedCardIdx],
      'swimLaneId': targetSwimlaneId
    };
    this.setState({
      cards
    });
  };
}
