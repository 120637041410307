// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import '../assets/styles/my-documents.scss';
import * as _ from 'lodash';
import { CenteredSpinner } from '../components/centered_spinner';
import { Utils } from '../utils/utils';
import { Environment } from '../environment';
import PencilIcon from '../assets/images/pencil-light.svg';
import { FilePermissionsModal } from '../components/FilePermissionsModal';
import { IDocumentAccessModelRow } from '../components/IDocumentAccessModelRow';
import { DocumentsFilter } from '../components/documents/DocumentsFilter';
import { Information } from '../components/documents/Information';

export class MyDocuments extends Component<{}> {
  state: {
    employee: any;
    documents: Array<IDocumentAccessModelRow>;
    parsedDocuments: Array<Array<IDocumentAccessModelRow>>;
    totalPages: number;
    currentPage: number;
    pageSize: number;
    isLoadingDocuments?: boolean;
    error: boolean;
    permissionsModalShowing: boolean;
    currentFile: any;
    isModalLoading: boolean;
    documentAccessRecords: Array<IDocumentAccessModelRow>;
    modalError: boolean;
  } = {
    'employee': null,
    'documents': [],
    'parsedDocuments': [],
    'totalPages': 1,
    'currentPage': 1,
    'pageSize': 5,
    'isLoadingDocuments': true,
    'error': false,
    'permissionsModalShowing': false,
    'currentFile': null,
    'isModalLoading': false,
    'documentAccessRecords': [],
    'modalError': false
  };

  props: {
    user: any
  } = {
    'user': null
  };

  constructor(props: any) {
    super(props);
    this.props = props;
  }

  private administrators = ['sberkebile@anequim.net', 'robertoramirez@anequim.net', 'jcuvelier@anequim.net',
    'aangeles@anequim.net', 'thernandez@anequim.net', 'jaspen@anequim.net', 'asaucedo@anequim.net',
    'preynoso@anequim.net'];

  componentDidMount() {
    Promise.all([this.loadEmployeeDocuments()]).then(async () => {
      await this.chunkDocuments();
    });
  }

  render() {
    const {
      isLoadingDocuments,
      permissionsModalShowing,
      currentFile,
      documentAccessRecords,
      modalError
    } = this.state;

    if (!isLoadingDocuments) {
      return (
        <div className="container">
          <div className={'payroll-wrapper'}>
            <div className="payroll-content">
              <div className={'employee-documents-wrapper'}>
                {this.documentsTable()}
              </div>
              <div>
                <FilePermissionsModal
                  modalShowing={permissionsModalShowing}
                  file={currentFile}
                  onHideHandler={this.hidePermissionsModalHandler}
                  disableRecordsHandler={this.disableRecordsHandler}
                  documentAccessRows={documentAccessRecords}
                  modalError={modalError}
                  checkboxHandler={this.isActiveCheckboxHandler}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (<CenteredSpinner/>);
  }

  private isActiveCheckboxHandler = (r: IDocumentAccessModelRow) => {
    const updatedRecord = this.getDacRecord(_.get(r, 'id'));
    if (!_.isNil(updatedRecord)) {
      updatedRecord.isActive = !_.get(updatedRecord, 'isActive');
    }
    this.forceUpdate();
  };

  private getDacRecord = (recordId: string): IDocumentAccessModelRow | undefined => _.find(
    this.state.documentAccessRecords, { 'id': recordId }
  );

  private loadEmployeeDocuments = async () => {
    const loggedUserEmail = _.get(this.props.user, 'attributes.email');
    const response = await fetch(`${Environment.get('SPRING_INTEGRATIONS_API_ROOT')}rm/employees/Anequim/${loggedUserEmail}?db=MX`);
    const employeeData = await response.json();

    this.setState({
      'employee': employeeData
    });

    const docResponse = await this.getDocuments();

    this.setState({
      'documents': _.flatten(docResponse)
    });
    return docResponse;
  };

  getDocuments = async () => {
    try {
      const ck = Utils.readCookie();
      let docsResponse: Array<IDocumentAccessModelRow> = [];
      await fetch(`${Environment.get('INTERNAL_REST_SERVICE_API_ROOT')}documents`, {
        'method': 'GET',
        'headers': {
          'Content-Type': 'multipart/form-data; boundary=-----Web1387te7',
          'Authorization': ck
        }
      }).then((response) => response.json())
        .then((data) => {
          docsResponse = [...data];
        })
        .finally(() => {
          this.setState({ 'error': false });
        });
      return docsResponse;
    } catch (e) {
      console.log(e);
      this.setState({ 'error': true });
      return [];
    }
  };

  private chunkDocuments = (docs?: Array<IDocumentAccessModelRow>) => {
    const { documents, pageSize } = this.state;
    const documentsToChunk = docs || documents;
    const sortedDocs = documentsToChunk.sort(
      (docA, docB) => _.get(docB, 'createDate') - _.get(docA, 'createDate')
    );
    const chunkedDocuments = _.chunk(sortedDocs, pageSize);
    // debugger;
    this.setState({
      'parsedDocuments': chunkedDocuments,
      'currentPage': 1,
      'totalPages': chunkedDocuments.length,
      'isLoadingDocuments': false
    });
  };

  openDocument = async (e: any) => {
    e.preventDefault();
    e.persist();
    const doc = _.find(_.flatten(_.get(this.state, 'documents')), { 'id': e.target.id });
    const ck = Utils.readCookie();
    await fetch(`${Environment.get('PAYROLL_API_ROOT')}documents/${_.get(doc, 'id')}`, {
      'headers': {
        'Authorization': ck
      }
    })
      .then((data) => data.blob().then((blob) => Utils.download(blob, `${_.get(doc, 'documentName')}`)))
      .catch((err) => {
        console.log(err);
      });
  };

  private showDocumentSharing = (e: any, document: IDocumentAccessModelRow) => {
    e.preventDefault();
    if (!_.isNil(document)) {
      this.setState({ 'isModalLoading': true });
      Promise.all(
        [this.loadDocumentAccessRecords(document)]
      ).then(() => {
        this.setState({
          'currentFile': document,
          'permissionsModalShowing': true,
          'isModalLoading': false
        });
      });
    }
  };

  private handleFilter = (filterText) => {
    const filteredDocuments = this.state.documents.filter(
      (document) => {
        const nameMatch = document.documentName.toLowerCase().includes(filterText.toLowerCase());
        const dateMatch = Utils.getDisplayDate(document.createDate).includes(filterText);
        // eslint-disable-next-line max-len
        const roleMatch = document.documentRoleText.toLowerCase().includes(filterText.toLowerCase());
        // eslint-disable-next-line max-len
        const descriptionMatch = this.getDescription(document.documentName, document.nominaNumber).toLowerCase().includes(filterText.toLowerCase());
        return nameMatch || dateMatch || roleMatch || descriptionMatch;
      }
    );
    this.chunkDocuments(filteredDocuments);
  };

  private async loadDocumentAccessRecords(file: IDocumentAccessModelRow) {
    try {
      const ck = Utils.readCookie();
      await fetch(`${Environment.get('INTERNAL_REST_SERVICE_API_ROOT')}documents/access`, {
        'method': 'POST',
        'headers': {
          'Content-Type': 'application/json',
          'Authorization': ck
        },
        'body': JSON.stringify(file)
      }).then((response) => response.json())
        .then((data) => {
          this.setState({ 'documentAccessRecords': data });
        })
        .finally(() => {
          this.setState({ 'modalError': false });
        });
    } catch (e) {
      console.log(e);
      this.setState({ 'modalError': true });
    }
  }

  private getDescription = (documentName: string, nominaNumber: string) => {
    const bonoRegex = /^\d+_\d+_\d+_\d+\.pdf$/;
    const nominaRegex = /^RE_\d+_Catorcenal_\d+_\d+_\d+_[A-Z0-9]+\.pdf$/;
    const nomina = nominaNumber === undefined ? '' : nominaNumber;

    if (nominaNumber === 'Aguinaldo') {
      return 'Aguinaldo';
    } if (bonoRegex.test(documentName)) {
      return `Bono ${nomina}`;
    } if (nominaRegex.test(documentName)) {
      return `Nomina ${nomina}`;
    }
    return '';
  };

  private documentsTable = () => {
    const { error } = this.state;

    if (error) {
      return (
        <div>
          Ha sucedido un error, por favor intenta de nuevo más tarde.
        </div>
      );
    }

    const columns: TableColumn<IDocumentAccessModelRow>[] = [
      {
        'name': 'Permisos',
        'cell': (row: any) => (
          <a href="#" onClick={(e) => this.showDocumentSharing(e, row)}>
            <img className="pencil-icon" src={PencilIcon} alt="Lapiz"/></a>),
        'button': true
      },
      {
        'name': 'Nombre',
        'selector': (row: IDocumentAccessModelRow) => row.documentName,
        'cell': (row: any) => (<a id={row.id} href="#" onClick={(e) => this.openDocument(e)}>
          {row.documentName}</a>),
        'sortable': true,
        'grow': 2
      },
      {
        'name': <div>Tipo de archivo</div>,
        'selector': (row: IDocumentAccessModelRow) => this.getDescription(row.documentName, row.nominaNumber),
        'sortable': true
      },
      {
        'name': 'Rol',
        'selector': (row: IDocumentAccessModelRow) => row.documentRoleText,
        'sortable': true
      },
      {
        'name': <div>Fecha de creación</div>,
        'selector': (row: IDocumentAccessModelRow) => row.createDate,
        'cell': (row: any) => Utils.getDisplayDate(row.createDate),
        'sortable': true
      }
    ];

    const isAdmin = _.includes(this.administrators, _.get(this.props.user, 'attributes.email'));

    return (
      <div className={'employee-documents-content'}>
        <h1>Mis archivos</h1>
        <div className={'documents-list'}>
          <div className="links">
            <span className="administration-link">
              {isAdmin ? <a href="/#/payroll-admin">Administración</a>
                : <span className="disabled-link">Administración</span>}
            </span>
            <Information/>
          </div>
          <DocumentsFilter onFilter={this.handleFilter}/>
          <DataTable
            className="documents-table"
            columns={columns}
            data={this.state.parsedDocuments[this.state.currentPage - 1] || []}
            noDataComponent="No se encontraron documentos"
            pagination
            paginationServer
            paginationPerPage={this.state.pageSize}
            paginationComponentOptions={{
              'rowsPerPageText': 'Documentos por página',
              'rangeSeparatorText': 'de'
            }}
            paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
            paginationTotalRows={this.state.documents.length}
            onChangePage={(page) => this.setState({ 'currentPage': page })}
            onChangeRowsPerPage={(currentRowsPerPage) => this.setState({ 'pageSize': currentRowsPerPage }, () => this.chunkDocuments())}
            paginationDefaultPage={this.state.currentPage}
            striped
          />
        </div>
      </div>
    );
  };

  private hidePermissionsModalHandler = () => {
    this.setState({
      'currentFile': null,
      'permissionsModalShowing': false
    });
  };

  private disableRecordsHandler = async () => {
    const { documentAccessRecords } = this.state;
    const recordsToUpdate = [...documentAccessRecords];
    _.remove(recordsToUpdate, { 'isActive': true });
    await this.disableDocumentAccessRecordsModalHandler(recordsToUpdate);
    this.forceUpdate();
  };

  private async disableDocumentAccessRecordsModalHandler(r: Array<IDocumentAccessModelRow>) {
    try {
      const ck = Utils.readCookie();
      const reqBody = JSON.stringify(r);
      await fetch(`${Environment.get('INTERNAL_REST_SERVICE_API_ROOT')}documents/access-disable`, {
        'method': 'POST',
        'headers': {
          'Content-Type': 'application/json',
          'Authorization': ck
        },
        'body': reqBody
      }).then((response) => {
        response.json();
        this.setState({
          'isLoadingDocuments': true
        });
      })
        .then((data) => {
          Promise.all([this.loadEmployeeDocuments()]).then(() => this.chunkDocuments()).then(() => {
            console.log(data);
            this.setState({
              'permissionsModalShowing': false,
              'isModalLoading': false,
              'modalError': false,
              'isLoadingDocuments': false
            });
          });
        });
    } catch (ex) {
      console.log(ex);
      this.setState({ 'modalError': true });
    }
  }
}
