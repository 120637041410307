// eslint-disable-next-line no-use-before-define
import React from 'react';

export class Information extends React.Component {
  render() {
    return (
      <span className="info-link">
        <span>Información</span>
        <div className="info-tooltip">
          <p>
            En este módulo encontrarás tus recibos de nómina y otros documentos<br/>
            que hayan sido compartidos contigo. Por lo general, tus recibos de<br/>
            nómina estarán disponibles 72 horas después del día de pago.
          </p>
        </div>
      </span>
    );
  }
}
