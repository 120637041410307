import * as _ from 'lodash';

interface LodashMixins extends _.LoDashStatic {
  // eslint-disable-next-line no-unused-vars
    roundAndFix (value: any, precision: number): string | undefined;
}

const roundAndFix = (value: any, precision: number): string | undefined => {
  if (value != null) {
    const v = +value;
    if (v != null) {
      return v.toFixed(precision);
    }
  }

  return undefined;
};

_.mixin({ 'roundAndFix': roundAndFix });

export default LodashMixins;
