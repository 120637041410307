// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import '../assets/styles/upload-fragment.scss';
import { Typeahead } from 'react-bootstrap-typeahead';
import * as _ from 'lodash';
import { IEmployeeTypeAheadData } from './IEmployeeTypeAheadData';

interface EmployeeTypeAheadProps {
  handler : Function,
  selectedOption: Array<any>,
  options: Array<IEmployeeTypeAheadData>,
  rowIdentifier?: string,
  isDisabled : boolean
}

class EmployeeTypeAhead extends Component<EmployeeTypeAheadProps> {
  // state: {
  //   isLoading: boolean;
  // } = {
  //   'isLoading': false
  // };

  render() {
    // if (this.state.isLoading) {
    //   return (
    //       <CenteredSpinner/>
    //   );
    // }

    return (<div>
          {this.getTypeAheadField()}
        </div>
    );
  }

  getTypeAheadField = () => {
    const { selectedOption, options, isDisabled } = this.props;

    // debugger;
    return (
        <Typeahead
            id="employee-typeahead"
            onChange={(selected) => this.localHandler(selected)}
            labelKey="name"
            selected={ selectedOption }
            options={ options }
            disabled={ isDisabled }
        />
    );
  };

  private localHandler(selected) {
    const { rowIdentifier } = this.props;
    const handler = this.props.handler || _.noop;
    if (!_.isNil(rowIdentifier)) {
      handler(_.head(selected), rowIdentifier);
    } else {
      handler(selected);
    }
  }
}

export default EmployeeTypeAhead;
