// eslint-disable-next-line no-use-before-define
import React, { Component } from 'react';
import * as _ from 'lodash';
import { ISwimLaneDefinition } from '../components/ISwimLaneDefinition';
// import { BaseWorkflowDashboard } from './BaseWorkflowDashboard';
import { ICardDefinition } from '../components/ICardDefinition';

const dummySwimLanes = [{
  'header': '30 Days',
  'width': '20%',
  'swimlaneId': 1
},
{
  'header': '60 Days',
  'width': '20%',
  'swimlaneId': 2
},
{
  'header': '90 Days',
  'width': '20%',
  'swimlaneId': 3
},
{
  'header': '14 day Notice',
  'width': '20%',
  'swimlaneId': 4
},
{
  'header': 'Paid/Payment Plan',
  'width': '20%',
  'swimlaneId': 5
}];

const dummyCards = [{
  'title': 'Maria Bonita',
  'text': 'Tenant says main toilet will not stop running',
  'priorityId': 3,
  'dueDate': new Date('2021-03-05'),
  'key': 1,
  'swimLaneId': 1,
  'id': '1',
  'rejectReason': '',
  'completionNote': ''
},
{
  'title': 'H. Delbrook',
  'text': '20%',
  'priorityId': 2,
  'dueDate': new Date('2021-03-03'),
  'key': 2,
  'swimLaneId': 2,
  'id': '2',
  'rejectReason': '',
  'completionNote': ''
},
{
  'title': 'Abby Normal',
  'text': '20%',
  'priorityId': 1,
  'dueDate': new Date('2021-03-05'),
  'key': 3,
  'swimLaneId': 3,
  'id': '3',
  'rejectReason': '',
  'completionNote': ''
},
{
  'title': 'Girard Grebe',
  'text': '20%',
  'priorityId': 1,
  'dueDate': new Date('2021-02-27'),
  'key': 4,
  'swimLaneId': 4,
  'id': '4',
  'rejectReason': '',
  'completionNote': ''
},
{
  'title': 'Hannah Montana',
  'text': '20%',
  'priorityId': 3,
  'dueDate': new Date('2021-03-05'),
  'key': 5,
  'swimLaneId': 5,
  'id': '5',
  'rejectReason': '',
  'completionNote': ''
}, {
  'title': 'Fred Flintstone',
  'text': 'Tenant says main toilet will not stop running',
  'priorityId': 2,
  'dueDate': new Date('2021-03-04'),
  'key': 6,
  'swimLaneId': 2,
  'id': '6',
  'rejectReason': '',
  'completionNote': ''
},
{
  'title': 'George Jetson',
  'text': '20%',
  'priorityId': 3,
  'dueDate': new Date('2021-03-15'),
  'key': 7,
  'swimLaneId': 1,
  'id': '7',
  'rejectReason': '',
  'completionNote': ''
},
{
  'title': 'Bilbo Baggins',
  'text': '20%',
  'priorityId': 3,
  'dueDate': new Date('2021-03-16'),
  'key': 8,
  'swimLaneId': 1,
  'id': '8',
  'rejectReason': '',
  'completionNote': ''
},
{
  'title': 'Jeff Conaway',
  'text': '20%',
  'priorityId': 1,
  'dueDate': new Date('2021-03-07'),
  'key': 9,
  'swimLaneId': 4,
  'id': '9',
  'rejectReason': '',
  'completionNote': ''
},
{
  'title': 'John Belushi',
  'text': '20%',
  'priorityId': 3,
  'dueDate': new Date('2021-03-25'),
  'key': 10,
  'swimLaneId': 1,
  'id': '10',
  'rejectReason': '',
  'completionNote': ''
},
{
  'title': 'Angelina Jolie',
  'text': '20%',
  'priorityId': 1,
  'dueDate': new Date('2021-03-07'),
  'key': 11,
  'swimLaneId': 4,
  'id': '11',
  'rejectReason': '',
  'completionNote': ''
},
{
  'title': 'Goldie Hawn',
  'text': '20%',
  'priorityId': 3,
  'dueDate': new Date('2021-03-05'),
  'key': 12,
  'swimLaneId': 5,
  'id': '12',
  'rejectReason': '',
  'completionNote': ''
},
{
  'title': 'Theo Lesieg',
  'text': '20%',
  'priorityId': 3,
  'dueDate': new Date('2021-03-10'),
  'key': 13,
  'swimLaneId': 1,
  'id': '13',
  'rejectReason': '',
  'completionNote': ''
}];

export class Collections extends Component<{}> {
  state: {
        swimlanes: Array<ISwimLaneDefinition>,
        dashboardId: number,
        activeIcon: string,
        cards: Array<ICardDefinition>
    } = {
      'swimlanes': dummySwimLanes,
      'cards': dummyCards,
      'dashboardId': 1,
      'activeIcon': '../../assets/images/landing-page/exclamation.svg'
    };

  private onDropped = (cardId: string, swimlaneId: string, targetSwimlaneId: number) => {
    const cards = _.cloneDeep(this.state.cards);
    const draggedCardIdx = cards.findIndex(
      ({ id, swimLaneId }) => id === cardId && swimLaneId === +swimlaneId
    );
    cards[draggedCardIdx] = {
      ...cards[draggedCardIdx],
      'swimLaneId': targetSwimlaneId
    };
    this.setState({
      cards
    });
  };

  // eslint-disable-next-line class-methods-use-this
  render() {
    return (<div className="dashboard">
                <h2>Collections</h2>
                {/* <BaseWorkflowDashboard dashboardId={this.state.dashboardId} */}
                {/*                       swimLanes={this.state.swimlanes} */}
                {/*                       cardCollection={this.state.cards} */}
                {/*                       activeIcon={this.state.activeIcon} */}
                {/*                       onDropped={this.onDropped} */}
                {/*                        onRejectSave={} */}
                {/*                        onCompleteSave={}/> */}
            </div>
    );
  }
}
