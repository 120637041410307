import Auth from '@aws-amplify/auth';
import { Environment } from './environment';

const customHeader = async () => {
  const token = (await Auth.currentAuthenticatedUser()).signInUserSession?.idToken?.jwtToken;
  return {
    'Authorization': token
  };
};

const AmplifyConfig = {
  'Auth': {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    // identityPoolId: Environment.get('REACT_APP_IDENTITY_POOL_ID'),

    // REQUIRED - Amazon Cognito Region
    'region': Environment.get('COGNITO_REGION'),

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    // identityPoolRegion: 'XX-XXXX-X',

    // OPTIONAL - Amazon Cognito User Pool ID
    'userPoolId': Environment.get('USER_POOL_ID'),

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    'userPoolWebClientId': Environment.get('WEB_CLIENT_ID'),

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    'mandatorySignIn': false,

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie
    // transmission requires a secure protocol
    'cookieStorage': {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      'domain': Environment.get('COOKIE_DOMAIN'),
      // OPTIONAL - Cookie path
      'path': '/',
      // OPTIONAL - Cookie expiration in days
      'expires': 365,
      // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      'sameSite': 'strict',
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission
      // requires a secure protocol (https).
      // Make this depend on environment.
      'secure': !!Environment.get('COOKIE_SECURE')
    },

    // OPTIONAL - customized storage object
    // storage: MyStorage,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: 'USER_PASSWORD_AUTH',

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    // clientMetadata: { myCustomKey: 'myCustomValue' },

    // OPTIONAL - Hosted UI configuration
    'oauth': {
      'domain': Environment.get('OAUTH_DOMAIN'),
      'scope': ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      'redirectSignIn': Environment.get('OAUTH_URL'),
      'redirectSignOut': Environment.get('OAUTH_URL'),
      'responseType': 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    },

    // AWS Pinpoint
    'identityPoolId': Environment.get('COGNITO_IDENTITY_POOL_ID')
  },
  'API': {
    'endpoints': [
      {
        'name': 'RmContactsByTnt',
        'endpoint': `${Environment.get('SPRING_INTEGRATIONS_API_ROOT')}rm/contacts/`,
        'custom_header': customHeader
      },
      {
        'name': 'RmContacts',
        'endpoint': `${Environment.get('SPRING_INTEGRATIONS_API_ROOT')}rm/contacts/`,
        'custom_header': customHeader
      },
      {
        'name': 'ContactTypes',
        'endpoint': `${Environment.get('SPRING_INTEGRATIONS_API_ROOT')}rm/contacts/`,
        'custom_header': customHeader
      },
      {
        'name': 'EmployeeListSlim',
        'endpoint': `${Environment.get('INTERNAL_REST_SERVICE_API_ROOT')}anequim/employees/`,
        'custom_header': customHeader
      },
      {
        'name': 'EmployeeListPayroll',
        'endpoint': `${Environment.get('INTERNAL_REST_SERVICE_API_ROOT')}anequim/employees/payroll`,
        'custom_header': customHeader
      },
      {
        'name': 'AnequimOrganizations',
        'endpoint': `${Environment.get('INTERNAL_REST_SERVICE_API_ROOT')}anequim/organizations/all`,
        'custom_header': customHeader
      },
      {
        'name': 'AnequimVendors',
        'endpoint': `${Environment.get('INTERNAL_REST_SERVICE_API_ROOT')}anequim/vendors/all`,
        'custom_header': customHeader
      },
      {
        'name': 'Employee',
        'endpoint': `${Environment.get('SPRING_INTEGRATIONS_API_ROOT')}rm/employees/`,
        'custom_header': customHeader
      },
      {
        'name': 'DisableDocumentAccessRecords',
        'endpoint': `${Environment.get('INTERNAL_REST_SERVICE_API_ROOT')}documents/access-disable`,
        'custom_header': customHeader
      },
      {
        'name': 'Contact',
        'endpoint': `${Environment.get('API_ROOT')}contacts/`,
        'custom_header': customHeader
      },
      {
        'name': 'AwardsBalance',
        'endpoint': `${Environment.get('SPRING_INTEGRATIONS_API_ROOT')}rm/awards/`,
        'custom_header': customHeader
      },
      {
        'name': 'CalculateAward',
        'endpoint': `${Environment.get('API_ROOT')}award_calc_test/`,
        'custom_header': customHeader
      },
      {
        'name': 'UpdateProfile',
        'endpoint': `${Environment.get('API_ROOT')}update_profile/`,
        'custom_header': customHeader
      },
      {
        'name': 'CreateJournals',
        'endpoint': `${Environment.get('JOURNALS_API_ROOT')}journals/`,
        'custom_header': customHeader
      },
      {
        'name': 'FetchJournals',
        'endpoint': `${Environment.get('JOURNALS_API_ROOT')}journals/`,
        'custom_header': customHeader
      },
      {
        'name': 'BenefitPrices',
        'endpoint': `${Environment.get('SPRING_BENEFITS_API_ROOT')}benefitTierPrices/`,
        'custom_header': customHeader
      },
      {
        'name': 'HistoryNote',
        'endpoint': `${Environment.get('API_ROOT')}notes/`,
        'custom_header': customHeader
      },
      {
        'name': 'BenefitElections',
        'endpoint': `${Environment.get('SPRING_BENEFITS_API_ROOT')}benefitElections`,
        'custom_header': customHeader
      },
      {
        'name': 'BenefitElectionsModel',
        'endpoint': `${Environment.get('SPRING_BENEFITS_API_ROOT')}benefitElectionsModel/`,
        'custom_header': customHeader
      },
      {
        'name': 'ExchangeRates',
        'endpoint': `${Environment.get('SPRING_INTEGRATIONS_API_ROOT')}exchangerates/`,
        'custom_header': customHeader
      },
      {
        'name': 'UserPermissions',
        'endpoint': `${Environment.get('SPRING_AUTH_API_ROOT')}userroles/`,
        'custom_header': customHeader
      },
      {
        'name': 'RMCCReport',
        'endpoint': `${Environment.get('SPRING_RMLS_API_ROOT')}reports/rmcc/`,
        'custom_header': customHeader
      },
      {
        'name': 'BenefitsSignUp',
        'endpoint': `${Environment.get('SPRING_RMLS_API_ROOT')}reports/benefits-sign-up/`,
        'custom_header': customHeader
      },
      {
        'name': 'UpdateServiceIssue',
        'endpoint': `${Environment.get('SPRING_RMLS_API_ROOT')}rm/service-issues/`,
        'custom_header': customHeader
      },
      {
        'name': 'RejectServiceIssue',
        'endpoint': `${Environment.get('SPRING_RMLS_API_ROOT')}rm/service-issues/`,
        'custom_header': customHeader
      },
      {
        'name': 'Upload',
        'endpoint': `${Environment.get('PAYROLL_API_ROOT')}documents/admin/upload/`,
        'custom_header': customHeader
      },
      {
        'name': 'Download',
        'endpoint': `${Environment.get('PAYROLL_API_ROOT')}payroll/download/`,
        'custom_header': customHeader
      },
      // {
      //   'name': 'ListPayStubs',
      //   'endpoint': `${Environment.get('PAYROLL_API_ROOT')}payroll/list-bucket-files/`,
      //   'custom_header': customHeader
      // },
      {
        'name': 'EmployeeProfile',
        'endpoint': `${Environment.get('SPRING_RMLS_API_ROOT')}employees/profile/`,
        'custom_header': customHeader
      },
      {
        'name': 'EmployeePayStubs',
        'endpoint': `${Environment.get('PAYROLL_API_ROOT')}stubs/list`,
        'custom_header': customHeader
      },
      {
        'name': 'TimesheetPeriods',
        'endpoint': `${Environment.get('INTERNAL_REST_SERVICE_API_ROOT')}timesheetperiods`,
        'custom_header': customHeader
      }
    ]
  },
  'Analytics': {
    'autoSessionRecord': true,
    'AWSPinpoint': {
      'appId': Environment.get('PINPOINT_APP_ID'),
      'region': Environment.get('PINPOINT_REGION'),
      'mandatorySignIn': false
    }
  }
};

export default AmplifyConfig;

// You can get the current config object
// const currentConfig = Auth.configure();
