import * as _ from 'lodash';
import { Environment } from '../environment';

export class Utils {
  public static readCookie = () :string => {
    const name = `CognitoIdentityServiceProvider.${Environment.get('WEB_CLIENT_ID')}.LastAuthUser=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const carr = decodedCookie.split(';') || [];
    for (let i = 0; i < carr.length; i++) {
      const c = (carr[i] || '').trim();
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  };

  public static download = (blob: any, filename: string) : any => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    // the filename you want
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  public static checkStringField = (info: any) => (_.isNil(info) || _.isEmpty(info) ? '' : info);

  public static getDisplayDate = (millis: number) => {
    let timeStr = '';
    if (millis !== 0) {
      const dt = new Date(millis);
      dt.setHours(dt.getHours() + 6);
      timeStr = dt.toLocaleDateString();
    }
    return timeStr;
  };
}
